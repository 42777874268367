import { ComponentChildren, h } from "preact";
import * as styles from "./Hint.module.scss";

type Props = {
    /** Dictates the style of the alert box. */
    type?: "warning";
    /** The content to be rendered within the box. */
    children?: ComponentChildren;
};

/**
 * Renders a colored box that can be used to grab user's attention.
 */
export function Hint({ type, children }: Props) {
    let classes = styles.hint;
    if (type) classes += " " + styles[type];

    return (
        <div className={classes}>
            {children}
        </div>
    );
}