import { h, Fragment } from "preact";
import { Link } from "react-router-dom";
import { Card } from "../../components/CardList";
import { DeleteEntityButton } from "../../components/DeleteEntityButton";
import { Drawer } from "../../components/Drawer";
import { EmailLink } from "../../components/EmailLink";
import { GoogleMapLink } from "../../components/GoogleMapLink";
import { Comments, Edit, Elevator } from "../../components/Icons";
import { PhoneLink } from "../../components/PhoneLink";
import { TicketCardList } from "../../components/TicketCardList";
import { createOverviewPage } from "../../factories/createOverviewPage";
import { GetBuildingQuery, useDeleteBuildingMutation, useGetBuildingQuery, UserRole } from "../../graphql/generated";
// import * as styles from "./BuildingView.module.scss";

/**
 * Renders a single building and displays the building's information and relationships.
 */
export const BuildingView = createOverviewPage<{ id: string }, GetBuildingQuery>({
    useQuery: ({ id }) => useGetBuildingQuery({ variables: { id } }),
    showNotFound: data => !data || !data.building,
    title: ({ building }) => building!.name,
    subTitle: () => "Building",
    info({ data }) {
        const { address, technician, ...building } = data.building!;

        return (
            <>
                <h3>Name</h3>
                <p>{building.name}</p>

                {building.email && (
                    <>
                        <h3>Email</h3>
                        <p><EmailLink email={building.email} /></p>
                    </>
                )}

                {building.phoneNumber && (
                    <>
                        <h3>Phone Number</h3>
                        <p><PhoneLink number={building.phoneNumber} /></p>
                    </>
                )}

                <h3>Address</h3>
                <p>
                    {address.street}<br />
                    {address.city}, {address.state} {address.countryCode} {address.zipcode}<br />
                    <GoogleMapLink address={address} />
                </p>

                <h3>Service Zone</h3>
                <Link to={`/serviceZones/${building.serviceZone.id}`}>
                    {building.serviceZone.name}
                </Link>

                <h3>Assigned Technician</h3>
                <p>
                    {technician ? (
                        <Link to={`/users/${technician.id}`}>
                            <strong>{technician.name}</strong><br />
                            <span>{technician.email}</span>
                        </Link>
                    ) : "None"}
                </p>

                <h3>Customer</h3>
                <Link to={`/customers/${building.customer.id}`}>
                    {building.customer.name}
                </Link>
            </>
        );
    },
    actions({ data, route, auth }) {
        if (auth.role !== UserRole.Dispatch) {
            return null;
        }

        const building = data.building!;

        return (
            <>
                <Drawer.Button
                    icon={<Comments />}
                    label="View Comments"
                    href={`/buildings/${building.id}/comments`}
                />
                <Drawer.Button
                    icon={<Elevator />}
                    label="Add Service Location"
                    href={`/buildings/${building.id}/serviceLocations/create`}
                />
                <Drawer.Button
                    icon={<Edit />}
                    label="Edit Building"
                    href={`/buildings/${building.id}/edit`}
                />
                <Drawer.Button
                    icon={<Edit />}
                    label="Edit Subscribers"
                    href={`/buildings/${building.id}/edit/subscribers`}
                />
                <DeleteEntityButton
                    id={building.id}
                    type="Building"
                    useMutation={useDeleteBuildingMutation}
                    onSuccess={() => route.history.push("/buildings")}
                />
            </>
        );
    },
    contents({ data, route }) {
        const history = route.history;
        const { id, serviceLocations, tickets } = data.building!;

        return (
            <>
                <h3>Active Service Requests</h3>
                <TicketCardList
                    serviceRequest
                    tickets={tickets}
                    history={history}
                />

                <h3>Service Locations</h3>
                <Card.List
                    items={serviceLocations}
                    onClick={sl => history.push(`/buildings/${id}/serviceLocations/${sl.id}`)}
                >
                    {({ name }) => (
                        <h3>{name}</h3>
                    )}
                </Card.List>

            </>
        );
    },
});