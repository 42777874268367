import { h } from "preact";
import { memo } from "preact/compat";
import { Phone } from "./Icons";
// import * as styles from "./PhoneLink.module.scss";

type Props = {
    /** The phone number formatted as +1########## */
    number: string;
};

/**
 * Simple component for rendering a phone link with an icon.
 */
export const PhoneLink = memo(({ number }: Props) => {
    const formatted = formatNumber(number);

    return (
        <a href={"tel:" + number} title={formatted}>
            <Phone color="currentColor" /> {formatted}
        </a>
    );
});

function formatNumber(num: string) {
    if (num.startsWith("+1") && num.length === 12) {
        return num.slice(0, 2) + " (" + num.slice(2, 5) + ") " + num.slice(5, 8) + "-" + num.slice(8);
    }

    return num;
}