@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    position: relative;
    color: inherit;
    pointer-events: none;
    font-size: 1rem;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        animation: loading 500ms infinite linear;
        background: transparent;
        border: 0.25rem solid currentColor;
        border-radius: 50%;
        border-right-color: transparent;
        border-top-color: transparent;
        content: "";
        display: block;
        width: 2em;
        height: 2em;
        margin-left: -1em;
        margin-top: -1em;
        padding: 0;
    }
}
