import { ComponentChildren, h } from "preact";
import * as styles from "./ErrorView.module.scss";

type Props = {
    /** Message contents to display for the error. */
    message: string | ComponentChildren;
    /** When present, renders a "retry" button. */
    onRetry?(): void;
};

/**
 * Renders an error page.
 */
export function ErrorView({ message, onRetry }: Props) {
    return (
        <div className={styles.root}>
            <p>{message}</p>
            {onRetry && (
                <a
                    className={styles.onRetry}
                    onClick={onRetry}
                >
                    Try Again
                </a>
            )}
        </div>
    );
}