import { h, Fragment } from "preact";
import { memo } from "preact/compat";
import { ParsedQs } from "qs";
import * as styles from "./Pagination.module.scss";

export type Props = {
    /** Total number of customer accounts found for the originating query. */
    totalCount: number;
    /** The number of results returned for this page. */
    pageCount: number;
    /** When paginating forwards, are there more items? */
    hasNextPage: boolean;
    /** When paginating backwards, are there more items? */
    hasPreviousPage: boolean;
    /** The number of results that were requested per page. */
    perPageCount: number;
} & (
        | {
            /** The current page that we're on. */
            page: number;
            /** Event that is called when the page info changes. */
            onPageChange(page: number): void;
            /** Event that is called when the desired number of results per page has changed. */
            onPerPageChange?(perPage: number): void;
            /** Search parameters potentially containing the current page number. */
            search?: never;
            /** Sets the search parameters when the page number changes. */
            onSearch?: never;
        }
        | {
            /** The current page that we're on. */
            page?: never;
            /** Event that is called when the page info changes. */
            onPageChange?: never;
            /** Event that is called when the desired number of results per page has changed. */
            onPerPageChange?: never;
            /** Search parameters potentially containing the current page number. */
            search: ParsedQs;
            /** Sets the search parameters when the page number changes. */
            onSearch(params: ParsedQs): void;
        }
    );

/**
 * Renders the pagination controls that appear at the bottom of a page.
 */
export const Pagination = memo(({
    totalCount,
    page,
    pageCount,
    perPageCount,
    hasPreviousPage,
    hasNextPage,
    onPageChange,
    onPerPageChange,
    search,
    onSearch,
}: Props) => {
    if (!page) {
        page = parseInt(search && typeof search.page === "string" ? search.page : "1");
    }

    if (!onPageChange && onSearch) {
        onPageChange = (page: number) => onSearch({ page: page.toString() });
    }

    if (!onPerPageChange && onSearch) {
        onPerPageChange = (perPage: number) => onSearch({ page: "1", perPage: perPage.toString() });
    }

    const pageStart = (page - 1) * perPageCount;
    const pageEnd = pageStart + pageCount;

    const totalPages = Math.ceil(totalCount / perPageCount);
    const tooManyPages = totalPages >= 9;
    let pageNumbers: JSX.Element[] = [];

    if (tooManyPages) {

        let nums = [];

        if (page <= 3) {
            nums = [1, 2, 3, 4, 5, 6, 0, totalPages - 2, totalPages - 1, totalPages];
        } else if (page >= totalPages - 3) {
            nums = [1, 2, 3, 0, totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        } else {
            nums = [1, 2, 3, 0, page - 1, page, page + 1, 0, totalPages - 2, totalPages - 1, totalPages];
        }


        for (const i of nums) {
            pageNumbers.push(i > 0
                ? <Page page={i} key={i} isCurrent={i === page} onClick={onPageChange!} />
                : <div>...</div>);
        }
    } else {
        for (let i = 0; i < totalPages; i++) {
            pageNumbers.push(<Page
                page={i + 1}
                isCurrent={(i + 1) === page}
                key={i}
                onClick={onPageChange!}
            />);
        }
    }

    return (
        <div>
            <div className={styles.pages}>
                <button
                    className={styles.prevPageButton}
                    disabled={!hasPreviousPage}
                    onClick={ev => onPageChange!(page! - 1)}
                />

                <div className={styles.pageNumbers}>
                    {pageNumbers}
                </div>

                <button
                    className={styles.nextPageButton}
                    disabled={!hasNextPage}
                    onClick={ev => onPageChange!(page! + 1)}
                />
            </div>
            <div className={styles.controls}>
                <div className={styles.perPageSelect}>
                    Rows per page
                    <div className={styles.perPageControl}>
                        <div className={styles.selectArrow} />
                        <select
                            value={"" + perPageCount}
                            onChange={ev => onPerPageChange && onPerPageChange(parseInt((ev.target as HTMLSelectElement).value))}
                        >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>
                <div>
                    Results {pageStart + 1} - {pageEnd} of {totalCount}
                </div>
            </div>
        </div>
    );
});


function Page({ page, isCurrent, onClick }: {
    page: number;
    isCurrent?: boolean;
    onClick(page: number): void;
}) {
    const classes = styles.pageNumber + (isCurrent ? " " + styles.isCurrent : "");

    return (
        <div className={classes} onClick={ev => onClick!(page)}>
            {page}
        </div>
    );
}
