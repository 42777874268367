import { h, Fragment } from "preact";
import { PhoneNumberField } from "../../components/controls/PhoneNumberField";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import {
    InviteCustomerUserInput,
    InviteCustomerUserMutation,
    InviteCustomerUserMutationVariables,
    NotificationPreference,
    useInviteCustomerUserMutation
} from "../../graphql/generated";
import {SelectField} from "../../components/controls/SelectField";
import {NOTIFY_OPTIONS} from "./UpdateBuildingSubscribersView";

type Fields = InviteCustomerUserInput;
type RouteParams = { id: string };
type MutRes = InviteCustomerUserMutation;
type MutVar = InviteCustomerUserMutationVariables;

/**
 * Renders the form view for inviting a new user that will instantly be attached to a
 * customer account.
 */
export const InviteCustomerUserFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, null>({
    title: () => "Invite Customer User",
    useDefaultValues: ({ match }) => ({
        name: "",
        email: "",
        phoneNumber: "",
        customerId: match.params.id,
    }),
    useMutation: useInviteCustomerUserMutation,
    onSuccess: (res, { history, match }) => history.replace(`/customers/${match.params.id}`),
    onSuccessMessage: res => `Successfully invited new user "${res.user.name}".`,
    render({ register, auth }) {
        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Name"
                    required
                />
                <TextField
                    register={register}
                    name="email"
                    placeholder="name@example.com"
                    label="Email Address"
                    type="email"
                    required
                />
                <PhoneNumberField
                    register={register}
                    name="phoneNumber"
                    label="Phone Number"
                />
                <SelectField
                    register={register}
                    name="defaultNotificationPreference"
                    label="Add this user to all new building notifications?"
                    options={NOTIFY_OPTIONS}
                    value={NotificationPreference.EmailOnly}
                    required
                />
                <hr/>
                <p>
                    An email will be sent to the invited user informing them of their password (which is automatically created by the system).
                </p>
            </>
        );
    },
});