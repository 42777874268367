import { h } from "preact";
import { GetCurrentUserQuery, useGetCurrentUserQuery } from "../graphql/generated";
import { ComponentChildren, createContext } from "preact";
import { useContext, useEffect } from "preact/hooks";
import { LoaderView } from "../views/Loader";
import { TOKEN_KEY } from "../constants";
import { useHistory } from "react-router-dom";

export type AuthUser = NonNullable<GetCurrentUserQuery["me"]>;

export type AuthContext = {
    /** The current user data, if any. */
    user: null | AuthUser;
    /** Forces the auth layer to fetch user data. */
    refresh(): void;
    /** Logs the user out and clears the token from localStorage. */
    logout(): void;
};

const Context = createContext<AuthContext>({
    user: null,
    refresh() { },
    logout() { },
});

type Props = {
    /** The children that will be nested under this component. */
    children: ComponentChildren;
};

function updateIntercomUser(user: null | GetCurrentUserQuery["me"]) {
    if (!user) {
        window.Intercom("shutdown");
        window.Intercom("boot", {});
        return;
    }

    window.Intercom("update", {
        user_id: user.id,
        name: user.name,
        email: user.email,
        phone: user.phoneNumber || undefined,
        "User Type": user.role,
        avatar: user.avatarUrl ? {
            image_url: user.avatarUrl,
            type: "avatar",
        } : undefined,
    });
}

/**
 * Fetches user data (when applicable).
 */
export function AuthProvider({ children }: Props) {
    const history = useHistory();
    const [result, refetch] = useGetCurrentUserQuery();
    const { data, fetching } = result;
    const user = data?.me ?? null;

    useEffect(() => {
        if (result.fetching) return;

        if (!result.data?.me) {
            localStorage.removeItem(TOKEN_KEY);
            updateIntercomUser(null);
            return;
        }

        updateIntercomUser(user);
    }, [result])

    const logout = () => {
        localStorage.removeItem(TOKEN_KEY);
        refetch();
        history.push("/");
    };

    if (fetching) {
        return (
            <LoaderView />
        );
    }

    return (
        <Context.Provider value={{ user, logout, refresh: refetch }}>
            {children}
        </Context.Provider>
    );
}

/**
 * Provides access to the auth context.
 */
export const useAuth = () => useContext(Context);