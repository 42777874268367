import { h, Fragment } from "preact";
import { Card } from "../../components/CardList";
import { DateTime } from "../../components/DateTime";
import { Entrapment, ServiceRequests, SortBy } from "../../components/Icons";
import { StatusTag } from "../../components/indicators/StatusTag";
import { formatTicketDate } from "../../components/TicketCardList";
import { createListPage, createOptionsFromEnum } from "../../factories/createListPage";
import { GetTicketsQuery, TicketSortBy, SortOrder, useGetTicketsQuery, TicketStatus, TicketPriority } from "../../graphql/generated";

type Ticket = GetTicketsQuery["tickets"]["nodes"][0];


/**
 * Renders the full list of past service request tickets.
 */
export const CustomerTicketsListView = createListPage<Ticket, { customerId: string }>({
    title: "Past Service Requests",
    icon: <ServiceRequests />,
    filterOptions: {
        priority: {
            label: "Priority",
            multiselect: true,
            options: createOptionsFromEnum(TicketPriority),
        },
        sortBy: {
            label: "Sort By",
            icon: <SortBy />,
            default: TicketSortBy.Priority,
            options: createOptionsFromEnum(TicketSortBy).filter(t => t.value !== TicketSortBy.Status),
        },
    },
    getUrl: ({ id }, { match }) => `/acc/${match.params.customerId}/tickets/${id}`,
    useQuery: (query, { match }) => {
        const [{ fetching, error, data }] = useGetTicketsQuery({
            variables: {
                query: {
                    status: [TicketStatus.Closed],
                    customerIds: [match.params.customerId],
                    priority: (query.priority as TicketPriority[]) || null,
                    search: (query.search as string) || null,
                    page: parseInt(query.page as string),
                    perPage: parseInt(query.perPage as string),
                    sortBy: query.sortBy as TicketSortBy,
                    sortOrder: query.orderBy as SortOrder,
                },
            },
        });

        return {
            fetching,
            error,
            pageInfo: data?.tickets.pageInfo,
            items: data?.tickets.nodes,
        };
    },
    renderIcon: ({ isEntrapment }) => isEntrapment ? <Entrapment color="#FF5C5D" /> : <ServiceRequests color="#717171" />,
    renderItem(ticket) {
        const { serviceLocation, createdAt, status } = ticket;

        return (
            <>
                <Card.Cell>
                    <strong>{serviceLocation.name}</strong>
                </Card.Cell>
                <Card.Cell>
                    <DateTime
                        when={createdAt}
                        format={formatTicketDate(createdAt)}
                    />
                </Card.Cell>
                <Card.Cell>
                    <StatusTag status={status} />
                </Card.Cell>
            </>
        );
    },
});