import { ComponentChildren, h } from "preact";
import cx from "classnames";
import * as styles from "./StatusNodes.module.scss";
import { Checkmark } from "../Icons";

type Props = {
    children: ComponentChildren;
};

/**
 * Renders a list of nodes that represent statuses that may contain additional data nested
 * underneath them.
 */
export function StatusNodes({ children }: Props) {
    return (
        <div className={styles.list}>
            {children}
        </div>
    );
}

export namespace StatusNodes {

    export type NodeProps = {
        /** The label for the node. */
        label: string;
        /** Is this a completed node or the current/active node? */
        completed?: boolean;
        /** Contextual content to render underneath the node. */
        children?: ComponentChildren;
    };

    /**
     * Renders a single node and its contextual information within a list.
     */
    export function Node({ label, completed, children }: NodeProps) {
        const classes = cx(styles.node, {
            [styles.isCompleted]: completed,
        });

        return (
            <div className={classes}>
                {completed && <Checkmark color="#DA5D39" />}
                <div className={styles.label}>{label}</div>
                {children && (
                    <div className={styles.content}>
                        {children}
                    </div>
                )}
            </div>
        );
    }

}