import { h } from "preact";
import { Spinner } from "../components/indicators/Spinner";
import * as styles from "./Loader.module.scss";

type Props = {};

/**
 * Renders a full-screen loader.
 */
export function LoaderView({ }: Props) {
    return (
        <div class={styles.root}>
            <Spinner />
        </div>
    );
}