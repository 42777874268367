import { ComponentChildren, Fragment, h } from "preact";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { Checkmark, Close } from "./Icons";
import { Portal } from "./Portal";
import { useEffect } from "preact/hooks";
import * as styles from "./Drawer.module.scss";

type Props = {
    /** When true, the drawer will be "open" (on-screen). */
    open?: boolean;
    /** When true, the drawer will be left aligned. */
    alignLeft?: boolean;
    /** When true, all content within the drawer will be scrollable. */
    scrollable?: boolean;
    /** When true, a close icon (X) will be rendered. */
    hideCloseIcon?: boolean;
    /** Event that is called when the drawer wants to close. */
    onClose?(): void;
    /** Content that will be rendered within the drawer. */
    children?: ComponentChildren;
};

/**
 * Renders a simple drawer component that can slide out from any of the sides.
 */
export function Drawer({
    open,
    alignLeft,
    scrollable,
    hideCloseIcon,
    onClose,
    children,
}: Props) {
    const classes = cx(styles.root, {
        [styles.isOpen]: open,
        [styles.isScrollable]: scrollable,
        [styles.isLeftAligned]: alignLeft,
    });

    useEffect(() => {
        if (open && !document.body.classList.contains(styles.isBodyLocked)) {
            document.body.classList.toggle(styles.isBodyLocked, true);
            return () => document.body.classList.toggle(styles.isBodyLocked, false);
        }

        return () => { };
    }, [open]);

    return (
        <Portal>
            <div className={classes}>
                <div
                    className={styles.overlay}
                    onClick={onClose}
                />
                <div className={styles.drawer}>
                    {!hideCloseIcon && (
                        <a className={styles.closeIcon} onClick={onClose}>
                            <Close />
                        </a>
                    )}
                    {children}
                </div>
            </div>
        </Portal>
    );
}

export namespace Drawer {

    export type ContentProps = {
        /** Optional class to add to this element. */
        className?: string;
        /** Content that will be rendered within the content region. */
        children?: ComponentChildren;
    };

    /**
     * Creates a content region within a drawer that has padding.
     */
    export function Content({ className, children }: ContentProps) {
        return (
            <div className={cx(styles.content, className)}>
                {children}
            </div>
        );
    }

    export type ScrollProps = {
        /** Content that will be rendered within the scroll region. */
        children?: ComponentChildren;
    };

    /**
     * Creates a scrollable region that can be used within a drawer.
     */
    export function Scroll({ children }: ScrollProps) {
        return (
            <div className={styles.scroll}>
                {children}
            </div>
        );
    }

    export type HeadingProps = {
        /** The label that will be rendered in the heading. */
        label: string;
        /** The icon used with this heading. */
        icon?: JSX.Element;
    };

    /**
     * Renders a heading box for use in a drawer.
     */
    export function Heading({ label, icon }: HeadingProps) {
        return (
            <header className={styles.heading}>
                <span className={styles.headingIcon}>
                    {icon}
                </span>
                <span className={styles.headingLabel}>
                    {label}
                </span>
            </header>
        );
    }

    export type ButtonProps = {
        /** The label that will be rendered in the button. */
        label: string;
        /** The icon used with this button. */
        icon?: JSX.Element;
        /** When true, renders the text as render. */
        dangerous?: boolean;
    } & (
            | {
                /** The URL that the button links to. */
                href: string;
                /** Event that is triggered when the button is pressed. */
                onClick?(): void;
                /** Determines if the button should be highlighted and considered "active". */
                isActive?: never;
            }
            | {
                /** The URL that the button links to. */
                href?: never;
                /** Event that is triggered when the button is pressed. */
                onClick(): void;
                /** Determines if the button should be highlighted and considered "active". */
                isActive?: boolean;
            }
        );

    /**
     * Renders a single drawer button.
     */
    export function Button({ href, label, icon, onClick, isActive, dangerous }: ButtonProps) {
        const contents = (
            <>
                <span className={styles.buttonIcon}>
                    {icon}
                </span>
                <span className={styles.buttonLabel}>
                    {label}
                </span>
            </>
        );

        if (onClick && !href) {
            const classes = cx(styles.button, {
                [styles.isActive]: isActive,
                [styles.isDanger]: dangerous,
            });

            return (
                <a
                    className={classes}
                    onClick={onClick}
                >
                    {contents}
                </a>
            );
        }

        return (
            <NavLink
                to={href}
                className={styles.button}
                activeClassName={styles.isActiveLink}
                onClick={onClick}
            >
                {contents}
            </NavLink>
        );
    }

    export type ToggleButtonProps = {
        /** Is this button currently active? */
        isActive?: boolean;
        /** The label that will be rendered in the button. */
        label: string;
        /** Handles click events for the button. */
        onClick(): void;
    };

    /**
     * Renders a button that acts like a value toggle button.
     */
    export function ToggleButton({ isActive, label, onClick }: ToggleButtonProps) {
        const classes = cx(styles.toggleButton, {
            [styles.isActive]: isActive,
        });

        return (
            <a
                className={classes}
                onClick={onClick}
            >
                <span className={styles.buttonIcon}>
                    {isActive && <Checkmark />}
                </span>
                <span className={styles.buttonLabel}>
                    {label}
                </span>
            </a>
        );

    }

}