import { h } from "preact";
import { useForm } from "react-hook-form";
import { Button } from "../components/controls/Button";
import { TextField } from "../components/controls/TextField";
import { useAuth } from "../contexts/Auth";
import { useToast } from "../contexts/Toast";
import { CommentableType, UpdateCommentInput, UpdateCommentMutation, useUpdateCommentMutation, UserRole } from "../graphql/generated";
import cx from "classnames";
import * as styles from "./CommentsView.module.scss";
import { useState } from "preact/hooks";
import { CommentData } from "./CommentsView";

type FormValues = Pick<UpdateCommentInput, "content" | "isPublic">;

export type Props = {
    /** Existing data for the comment we're updating. */
    comment: null | CommentData;

    /** Event that is called after a comment is successfully updated. */
    onSuccess(data: UpdateCommentMutation["comment"]): void;

    /** Event that is called if a user cancels editing. */
    onCancel(): void;

    className: null | string;
};

/**
 * Form for editing existing comments. This is a subview of the larger comments view but has
 * been split off to avoid re-renders of the whole view due to potential form changes.
 */
export function UpdateCommentSubView({ comment, onSuccess, onCancel, className = "" }: Props) {
    const toast = useToast();
    const { user } = useAuth();
    const [result, updateComment] = useUpdateCommentMutation();
    const { register, handleSubmit, reset, formState: { isValid } } = useForm<FormValues>({
        mode: "onChange",
        defaultValues: {
            content: comment?.content,
        }
    });
    const [isPublic, setIsPublic] = useState(comment?.isPublic);
    const loading = result.fetching;

    const onSubmit = handleSubmit(async ({ content }) => {
        const { error, data } = await updateComment({
            input: {
                id: comment!.id,
                isPublic,
                content,
            },
        });

        if (error) {
            toast({
                type: "error",
                message: error.message,
            });
            return;
        }

        toast({
            type: "success",
            message: "Successfully updated comment.",
        });

        onSuccess(data!.comment);
    });

    if (!comment) return null;

    const classes = cx(styles.form, className, {
        [styles.isInternal]: !isPublic,
    });

    return (

        <form className={classes} onSubmit={onSubmit}>
            <div className={styles.formContent}>
                {/* {user!.role !== UserRole.Customer && (
                    <div className={styles.modeControls}>
                        <div className={styles.externalControl} onClick={() => setIsPublic(true)}>Customer</div>
                        <div className={styles.internalControl} onClick={() => setIsPublic(false)}>Internal</div>
                    </div>
                )} */}
                <TextField
                    register={register}
                    type="multiline"
                    name="content"
                    label="Message"
                    required
                />

                <div className={styles.commentEditingButtons}>
                    <Button
                        outlined
                        type="submit"
                        disabled={loading}
                        onClick={ev => {
                            ev.preventDefault();
                            reset();
                            setIsPublic(comment.isPublic);
                            onCancel();
                        }}
                        label="Cancel"
                    />

                    <Button
                        type="submit"
                        disabled={!isValid}
                        loading={loading}
                        label="Submit"
                    />
                </div>

            </div>
        </form>
    );
}