import { h } from "preact";
import * as styles from "./Spinner.module.scss";

/**
 * Renders a spinner that can be used for loading.
 */
export function Spinner({ size = 16 }) {
    return (
        <div
            className={styles.spinner}
            style={{ fontSize: size }}
        />
    );
}