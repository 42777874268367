import { h } from "preact";
import { createEntityRefField } from "../../factories/createEntityRefField";
import { SearchCustomersDocument, SearchCustomersQuery, SearchCustomersQueryVariables } from "../../graphql/generated";

type Customer = SearchCustomersQuery["customers"]["nodes"][0];

export const CustomerRefField = createEntityRefField<Customer>({
    // async fetchItem(id, { query }) {
    //     const { error, data } = await query<GetCustomerMinimalQuery, GetCustomerMinimalQueryVariables>(GetCustomerMinimalDocument, { id }).toPromise();
    //     return !error && data ? data.customer : null;
    // },
    async searchItems(search, { query }) {
        const { error, data } = await query<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, { search }).toPromise();
        return !error && data ? data.customers.nodes : [];
    },
    render(customer) {
        return (
            <span>{customer.name}</span>
        );
    },
});