.list {
  position: relative;
  margin: 50px 0;
}

.node {
  position: relative;
  padding: 0 0 30px 50px;
  opacity: 0.5;

  svg {
    position: absolute;
    left: 2px;
    width: 14px;
    height: 14px;
    z-index: 5;
    top: 2px;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }

  // node dashes
  &:before {
    left: 9px;
    bottom: 0;
    border-left: dotted 1px currentColor;
    z-index: 1;
  }

  // node circle
  &:after {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: solid 1px currentColor;
    background: #fff;
    z-index: 2;
  }

  // don't display the dotted line for the last node/step
  &:last-child:before {
    display: none;
  }

  &.isCompleted + & {
    opacity: 1;

    &:after {
      border-color: transparent;
      background: #da5d39;
    }
  }

  &.isCompleted {
    opacity: 1;

    &:before {
      border-color: #da5d39;
    }

    &:after {
      background: #fff !important;
      border-color: #da5d39 !important;
      content: "";
    }
  }
}

.label {
  position: relative;
}

.content {
  padding: 6px 0 0 0;
  font-size: 0.875em;
  opacity: 0.7;

  time {
    font: inherit;
    color: inherit;
    opacity: 1;
  }
}
