@import "../mixins";

// .header {
//     position: sticky;
//     top: 60px;
//     z-index: 45;
//     background: #fff;
//     border-bottom: solid 1px #eee;
// }

// .headerContent {
//     padding: 20px 0;

//     > h1 {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         font: inherit;
//         font-weight: bold;

//         > svg {
//             margin-right: 10px;
//             opacity: 0.7;
//         }
//     }
// }

// .headerContent,
.items {
    @extend %container;
    padding: 25px 0 100px;
}

// .controls {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     padding-top: 10px;
// }

.search {
    position: relative;
    margin-right: 10px;
    flex: 1;

    > input[type="text"] {
        padding-right: 50px;
        z-index: 1;
    }

    > svg {
        position: absolute;
        top: 50%;
        right: 0;
        opacity: 0.5;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
}

// .filters {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     cursor: pointer;
//     text-decoration: underline;
//     color: inherit;
//     user-select: none;
// }
