import { h, Fragment } from "preact";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { UpdateScheduleEventInput, UpdateScheduleEventMutation, UpdateScheduleEventMutationVariables, ScheduleEventType, useUpdateScheduleEventMutation, UserRole, GetScheduleEventQuery, TechOnCallScheduleEvent, useGetScheduleEventQuery, useDeleteScheduleEventMutation } from "../../graphql/generated";
import { SelectField } from "../../components/controls/SelectField";
import { UserRefField } from "../../components/controls/UserRefField";
import pickBy from "lodash/pickBy";
import { ServiceZoneRefField } from "../../components/controls/ServiceZoneRefField";
import { PRIORITY_OPTIONS } from "./CreateScheduleEventFormView";
import format from "date-fns/format";
import { DeleteEntityIconAction } from "../../components/DeleteEntityIconAction";
import * as styles from "./CreateScheduleEventFormView.module.scss";

type Fields = Omit<UpdateScheduleEventInput, "startsAt" | "endsAt" | "priority"> & {
    priority: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
};

type RouteParams = { id: string };
type MutRes = UpdateScheduleEventMutation;
type MutVar = UpdateScheduleEventMutationVariables;
type Query = GetScheduleEventQuery;

const getTime = (date: Date) => ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);

/**
 * Renders the form view for updating a schedule event.
 */
export const UpdateScheduleEventFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Edit Schedule Event",
    useDefaultValues: ({ data }) => {
        const event = data!.scheduleEvent! as TechOnCallScheduleEvent;
        const startsAt = new Date(event.startsAt);
        const endsAt = new Date(event.endsAt);

        return {
            id: event.id,
            // type: event.type,
            name: event.name,
            priority: event.priority ? "" + event.priority : "0",
            technicianId: event.technician?.id ?? "",
            serviceZoneId: event.serviceZone?.id ?? "",
            startDate: format(startsAt, "yyyy-MM-dd"),
            endDate: format(endsAt, "yyyy-MM-dd"),
            startTime: getTime(startsAt),
            endTime: getTime(endsAt),
        };
    },
    toVars: ({ startDate, startTime, endDate, endTime, priority, ...fields }) => {
        const startsAt = new Date(`${startDate} ${startTime}`);
        const endsAt = new Date(`${endDate} ${endTime}`);

        return {
            input: {
                ...pickBy(fields, x => !!x) as Fields,
                priority: priority ? parseInt(priority as string) : null,
                startsAt: startsAt.toISOString(),
                endsAt: endsAt.toISOString(),
            },
        };
    },
    showNotFound: data => !data || !data.scheduleEvent,
    useQuery: ({ id }) => useGetScheduleEventQuery({ variables: { id } }),
    useMutation: useUpdateScheduleEventMutation,
    onSuccess: (res, { history }) => history.replace(`/schedule`),
    onSuccessMessage: res => `Successfully updated schedule event.`,
    pageAction({ data, route }) {
        const history = route.history;
        const event = data!.scheduleEvent!;

        return (
            <DeleteEntityIconAction
                id={event.id}
                type="Schedule Event"
                useMutation={useDeleteScheduleEventMutation}
                onSuccess={() => history.replace("/schedule")}
            />
        );
    },
    render({ register, control, watch, data }) {
        const event = data!.scheduleEvent! as TechOnCallScheduleEvent;
        const [startDate] = watch(["startDate"]);

        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Event Name"
                />
                <div className="hbox">
                    <TextField
                        register={register}
                        label="Start Date"
                        name="startDate"
                        type="date"
                        required
                    />

                    <div className={styles.toText}>TO</div>

                    <TextField
                        register={register}
                        label="End Date"
                        name="endDate"
                        type="date"
                        required
                        min={startDate}
                    />
                </div>

                <div className="hbox">
                    <TextField
                        register={register}
                        label="Start Time"
                        name="startTime"
                        type="time"
                        required
                    />

                    <div className={styles.toText}>TO</div>

                    <TextField
                        register={register}
                        label="End Time"
                        name="endTime"
                        type="time"
                        required
                    />
                </div>

                {event.type !== ScheduleEventType.OfficesClosed && (
                    <UserRefField
                        placeholder="Start typing to search technicians"
                        control={control}
                        name="technicianId"
                        label="Technician"
                        role={[UserRole.Technician]}
                        data={event.technician}
                        required
                    />
                )}

                {event.type === ScheduleEventType.TechOncall && (
                    <>
                        <ServiceZoneRefField
                            placeholder="Start typing to search service zones"
                            control={control}
                            name="serviceZoneId"
                            label="Service Zone"
                            data={event.serviceZone}
                            required
                        />
                        <SelectField
                            register={register}
                            name="priority"
                            label="Priority"
                            options={PRIORITY_OPTIONS}
                            required
                        />
                    </>
                )}
            </>
        );
    },
});