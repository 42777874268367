import { h, Fragment } from "preact";
import { useCallback, useMemo, useState } from "preact/hooks";
import { History } from "history";
import { Card } from "../../components/CardList";
import { DeleteEntityButton } from "../../components/DeleteEntityButton";
import { Drawer } from "../../components/Drawer";
import { AddBuilding, AddUser, Building, Comments, Edit, Trash } from "../../components/Icons";
import { Spinner } from "../../components/indicators/Spinner";
import { TicketCardList } from "../../components/TicketCardList";
import { useToast } from "../../contexts/Toast";
import { createOverviewPage } from "../../factories/createOverviewPage";
import { GetCustomerQuery, SearchUsersDocument, SearchUsersQuery, SearchUsersQueryVariables, useDeleteCustomerMutation, useGetCustomerQuery, useGrantUserAccessToCustomerMutation, useRevokeUserAccessToCustomerMutation, UserRole } from "../../graphql/generated";
import { OptionLookupField } from "../../components/controls/OptionLookupField";
import { useClient } from "@urql/preact";
import { memo } from "preact/compat";
import { Avatar } from "../../components/Avatar";

/**
 * Renders a single customer and displays the customer's information and relationships.
 */
export const CustomerView = createOverviewPage<{ id: string }, GetCustomerQuery>({
    useQuery: ({ id }) => useGetCustomerQuery({ variables: { id } }),
    showNotFound: data => !data || !data.customer,
    title: ({ customer }) => customer!.name,
    subTitle: () => "Customer",
    info({ data }) {
        const customer = data.customer!;

        return (
            <>
                <h3>Name</h3>
                {customer.name}
            </>
        );
    },
    actions({ data, route, auth }) {
        if (auth.role !== UserRole.Dispatch) {
            return null;
        }

        const customer = data.customer!;

        return (
            <>
                <Drawer.Button
                    icon={<Comments />}
                    label="View Comments"
                    href={`/customers/${customer.id}/comments`}
                />
                <Drawer.Button
                    icon={<AddUser />}
                    label="Invite New User"
                    href={`/customers/${customer.id}/users/invite`}
                />
                <Drawer.Button
                    icon={<AddBuilding />}
                    label="Add Building"
                    href={`/customers/${customer.id}/buildings/create`}
                />
                <Drawer.Button
                    icon={<Edit />}
                    label="Edit Customer"
                    href={`/customers/${customer.id}/edit`}
                />
                <DeleteEntityButton
                    id={customer.id}
                    type="Customer"
                    useMutation={useDeleteCustomerMutation}
                    onSuccess={() => route.history.push("/customers")}
                />
            </>
        );
    },
    contents({ data, route, auth }) {
        const { buildings, tickets } = data.customer!;
        const history = route.history;

        return (
            <>

                <h3>Active Service Requests</h3>
                <TicketCardList
                    serviceRequest
                    tickets={tickets}
                    history={history}
                />

                <h3>Buildings</h3>
                <Card.List
                    icon={() => <Building />}
                    items={buildings}
                    onClick={b => history.push(`/buildings/${b.id}`)}
                >
                    {building => (
                        <h3>
                            {building.name}
                        </h3>
                    )}
                </Card.List>

                {auth.role === UserRole.Dispatch
                    ? (
                        <ManageUsers
                            initialUsers={data.customer!.users.map(ua => ua.user)}
                            customerId={data.customer!.id}
                            history={history}
                        />
                    )
                    : (
                        <UserList
                            users={data.customer!.users.map(ua => ua.user)}
                            history={history}
                        />
                    )}
            </>
        );
    },
});


type UserSubset = NonNullable<GetCustomerQuery["customer"]>["users"][0]["user"];

type UserListProps = {
    /** List of users to render. */
    users: UserSubset[];
    /** History object used to route the user to a user's profile page. */
    history: History;
};

function UserList({ users, history }: UserListProps) {
    if (users.length === 0) {
        return null;
    }

    return (
        <>
            <h3>Users</h3>
            <Card.List
                icon={user => <Avatar name={user.name} src={user.avatarUrl} />}
                onClick={(user) => history.push(`/users/${user.id}`)}
                items={users}
            >
                {(user) => (
                    <div>
                        <strong>{user.name}</strong><br />
                        <span>{user.email}</span>
                    </div>
                )}
            </Card.List>
        </>
    );
}

type ManageUsersProps = {
    /** Data set from the initial query. */
    initialUsers: UserSubset[];
    /** The ID of the customer that we're modifying. */
    customerId: string;
    /** History object used to route the user to a user's profile page. */
    history: History;
};

const ManageUsers = memo(({ initialUsers, customerId, history }: ManageUsersProps) => {
    const toast = useToast();
    const [users, setUsers] = useState(initialUsers);
    const [deletingUsers, setDeletingUsers] = useState<string[]>([]);
    const [{ fetching }, grantAccess] = useGrantUserAccessToCustomerMutation();
    const [, revokeAccess] = useRevokeUserAccessToCustomerMutation();

    const addUser = async (user: UserSubset) => {
        const { error } = await grantAccess({ userId: user.id, customerId });

        if (error) {
            toast({ type: "error", message: error.message });
            return;
        }

        toast({
            type: "success",
            message: `Successfully granted customer access to user "${user.name}".`,
        });

        setUsers(users.concat(user));
    };

    const removeUser = async ({ id: userId, name }: UserSubset) => {
        if (deletingUsers.includes(userId)) {
            console.warn("Attempting to delete a user that is already being deleted.");
            return;
        }

        setDeletingUsers(deletingUsers.concat(userId));

        const { error } = await revokeAccess({ userId, customerId });

        if (error) {
            toast({ type: "error", message: error.message });
        } else {
            setUsers(users.filter(u => u.id !== userId));

            toast({
                type: "success",
                message: `Successfully removed "${name}" from this customer account.`,
            });
        }

        setDeletingUsers(deletingUsers.filter(d => d !== userId));
    };

    return (
        <>
            <AddExistingUserField
                loading={fetching}
                users={users}
                onSelected={addUser}
            />
            <h3>Users</h3>
            <Card.List
                icon={user => <Avatar name={user.name} src={user.avatarUrl} />}
                items={users}
            >
                {(user) => (
                    <>
                        <Card.Cell>
                            <a onClick={() => history.push(`/users/${user.id}`)}>
                                <strong>{user.name}</strong><br/>
                                <span>{user.email}</span>
                            </a>
                        </Card.Cell>
                        <Card.Cell>
                            {deletingUsers.includes(user.id) ? (
                                <div>
                                    <Spinner/>
                                </div>
                            ) : (
                                <a onClick={() => removeUser(user)}>
                                    <Trash/>
                                </a>
                            )}
                        </Card.Cell>
                    </>
                )}
            </Card.List>
        </>
    );
});

type AddExistingUserFieldProps = {
    /** Disables the field when true (if we're performing an operation). */
    loading: boolean;
    /** Existing users that are already attached to this customer account. */
    users: UserSubset[];
    /** Event that is called when a user is selected. */
    onSelected(user: UserSubset): void;
};

function AddExistingUserField({ users, loading, onSelected }: AddExistingUserFieldProps) {
    const urqlClient = useClient();
    const [rerenderKey, setRerenderKey] = useState(0);
    const [results, setResults] = useState<UserSubset[]>([]);

    const userIds = useMemo(() => users.map(u => u.id), [users]);

    const onSearch = useCallback(async (search: string) => {
        if (search) {
            const { error, data } = await urqlClient.query<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, {
                search,
                exclude: userIds,
                role: UserRole.Customer,
            }).toPromise();

            setResults(!error && data ? data.users.nodes : []);
        } else {
            setResults([]);
        }
    }, [userIds, setResults]);

    const _onSelected = useCallback((user: UserSubset) => {
        setResults([]);
        onSelected(user);
        setRerenderKey(rerenderKey + 1);
    }, [rerenderKey, setRerenderKey]);

    return (
        <>
            <h3>
                Add Existing User
            </h3>
            <OptionLookupField
                key={rerenderKey}
                disabled={loading}
                options={results}
                placeholder="Start typing to search users"
                onSearch={onSearch}
                onSelected={_onSelected}
            >
                {user => (
                    <>
                        <Avatar name={user.name} src={user.avatarUrl} size={8} />
                        <span style={{ paddingLeft: 10 }}>{user.name}</span>
                        <span style={{ paddingLeft: 20, opacity: 0.6 }}>{user.email}</span>
                    </>
                )}
            </OptionLookupField>
            <p className="muted"><small>User will be notified of their new access privileges when added.</small></p>
        </>
    );
}