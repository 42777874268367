import { h } from "preact";
import { memo } from "preact/compat";
import { Address } from "../graphql/generated";
import { ServiceZones } from "./Icons";
// import * as styles from "./GoogleMapLink.module.scss";

type Props = {
    /** The address string or object we want to link to. */
    address: string | Omit<Address, "country">;
};

/**
 * Renders a simple URL to Google maps using either an address object or string.
 */
export const GoogleMapLink = memo(({ address }: Props) => {

    if (typeof address !== "string") {
        address = address.street + " " + address.city + ", " + address.state + " " + address.countryCode + " " + address.zipcode;
    }

    // TODO: this encoding should be improved
    const url = "https://www.google.com/maps/search/?api=1&query="
        + encodeURIComponent(address);

    return (
        <a href={url} target="_blank">
            <ServiceZones /> View on Google Maps
        </a>
    );
});