.button {
    background: #ff5c5d;
    color: #fff;
    // padding: 10px 16px;
    padding: 0;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    transition: transform 250ms ease;
    height: 36px;
    width: 80px;

    &:hover {
        transform: scale(1.25);
    }

    &:disabled {
        background: #999;
    }
}
