import { h } from "preact";
// import * as styles from "./EmailLink.module.scss";

type Props = {
    /** The email for format and render. */
    email: string;
};

/**
 * Renders a formatted email link.
 */
export function EmailLink({ email }: Props) {
    return (
        <a href={"mailto:" + email} title={email}>
            {email}
        </a>
    );
}