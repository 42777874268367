@import "../mixins";

.root {
    position: sticky;
    top: 60px;
    z-index: 45;
    background: #fff;
    border-bottom: solid 1px #eee;
    padding: 20px;
    width: auto;

    > a {
        cursor: pointer;
        user-select: none;
    }
}

.content {
    @extend %container;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 768px) {
        display: block;
    }
}

.row {
    @extend %row;
    width: 100%;
    justify-content: space-between;

    & + & {
        margin-top: 10px;
    }
}

.title {
    @extend %row;
    color: inherit;

    > .icon {
        position: relative;
        margin-right: 10px;
        // width: 50px;

        // > svg {
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        // }
    }
}

.info {
    display: block;

    > h1 {
        font: inherit;
        font-weight: bold;
    }

    > h2 {
        font: inherit;
        font-size: 0.875em;
        opacity: 0.5;
    }
}

.action {
    @extend %row;
    position: relative;
    min-width: 20px;
    background: transparent;
    cursor: pointer;
    user-select: none;
    color: inherit;
    text-decoration: underline;

    &:disabled {
        opacity: 0.4;
    }
}

// .action > svg,
// .title > .icon
