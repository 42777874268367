import { useMemo } from "preact/hooks";
import { get, useFormContext } from "react-hook-form";

/**
 * This is a custom hook that allows us to query form state for errors on a per field
 * (and component) level. This is to reduce the amount of work needed to get per field
 * validation up and running across the entire application.
 */
export function useFieldErrors(name: string) {
    const form = useFormContext();

    return useMemo(() => {
        if (!form) {
            return { isValid: true, error: "" };
        }

        const err = get(form.formState.errors, name);
        if (!err) {
            return { isValid: true, error: "" };
        }

        let message = "This field is invalid.";

        if (err.message) {
            message = err.message;
        } else if (err.type === "required") {
            message = "Please fill out this field.";
        } else if (err.ref && "validationMessage" in err.ref) {
            message = err.ref.validationMessage;
        }

        return {
            isValid: false,
            error: message,
        };
    }, [form]);
}