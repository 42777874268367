import { h, Fragment } from "preact";
import { createListPage, createOptionsFromEnum } from "../../factories/createListPage";
import { UserSortBy, GetUsersQuery, SortOrder, useGetUsersQuery, UserRole } from "../../graphql/generated";
import capitalize from "lodash/capitalize";
import { ManageUser, SortBy } from "../../components/Icons";
import { Avatar } from "../../components/Avatar";
import { Card } from "../../components/CardList";

type User = GetUsersQuery["users"]["nodes"][0];

/**
 * Renders the full list of users and allows for new user accounts to be created.
 */
export const EmployeesListView = createListPage<User>({
    title: "Manage Employees",
    icon: <ManageUser />,
    filterOptions: {
        sortBy: {
            label: "Sort By",
            icon: <SortBy />,
            default: UserSortBy.Name,
            options: createOptionsFromEnum(UserSortBy),
        },
    },
    footerActions: ({ history, auth }) => auth.role !== UserRole.Dispatch ? [] : [
        {
            label: "Add Employee",
            onClick: () => history.push("/employees/create"),
        },
    ],
    getUrl: ({ id }) => `/users/${id}`,
    useQuery: (query) => {
        const [{ fetching, error, data }] = useGetUsersQuery({
            variables: {
                query: {
                    search: (query.search as string) || null,
                    roles: [UserRole.Dispatch, UserRole.Technician],
                    page: parseInt(query.page as string),
                    perPage: parseInt(query.perPage as string),
                    sortBy: query.sortBy as UserSortBy,
                    sortOrder: query.orderBy as SortOrder,
                },
            },
        });

        return {
            fetching,
            error,
            pageInfo: data?.users.pageInfo,
            items: data?.users.nodes,
        };
    },
    renderIcon(user) {
        return (<Avatar name={user.name} src={user.avatarUrl} size={16} />);
    },
    renderItem(user) {
        return (
            <>
                <Card.Cell>
                    <h3>{user.name}</h3>
                </Card.Cell>
                <Card.Cell>
                    <span>{capitalize(user.role)}</span>
                </Card.Cell>
                <Card.Cell />
            </>
        );
    },
});