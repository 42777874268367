import { h } from "preact";
import { JSXInternal } from "preact/src/jsx";
import cx from "classnames";
import { FieldPath, FieldValues, UseFormRegister } from "react-hook-form";
import capitalize from "lodash/capitalize";
import snakeCase from "lodash/snakeCase";
import * as styles from "./Field.module.scss";

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
    > = JSXInternal.HTMLAttributes<HTMLSelectElement> & {
        /** The label used for the title and aria label of the button. */
        label?: string;
        /** When true, the label will be hidden. */
        hideLabel?: boolean;
        /** The options available for selection. */
        options: { [key: string]: string };
        /** When true, only the select box will be rendered. */
        controlOnly?: boolean;
        /** When provided, acts as a custom renderer for options. */
        children?(value: string, label: string): JSX.Element | JSX.Element[];
    } & (
        | {
            /** The field name. */
            name: string;
            /** The register function provided by React Hook Form. */
            register?: never;
        }
        | {
            /** The field name. */
            name: FieldPath<TFieldName>;
            /** The register function provided by React Hook Form. */
            register: UseFormRegister<TFieldValues>;
        }
    );

/**
 * This component just provides some convenience via the options prop, along with some
 * basic styling for working with select dropdowns.
 */
export function SelectField<FormValues>({ name, label, options, id, register, children, hideLabel, controlOnly, ...props }: Props<FormValues>) {
    if (!label) label = capitalize(name);
    if (!id) id = `select__${snakeCase(label)}`;

    const classes = cx(styles.field, {
        [styles.required]: props.required,
        [styles.hideLabel]: hideLabel,
    });

    let opts = [];
    const regProps = !register ? {} : register(name as any, {
        required: props.required,
    });

    for (let k in options) {
        opts.push(<option value={k}>
            {children ? children(k, options[k]) : options[k]}
        </option>);
    }

    const control = (
        <div className={styles.control}>
            <select id={id} {...regProps} {...props}>{opts}</select>
            <div className={styles.selectArrow} />
        </div>
    );

    if (controlOnly) {
        return control;
    }

    return (
        <div className={classes}>
            <label htmlFor={id} className={styles.label}>{label}</label>
            {control}
        </div>
    );
}