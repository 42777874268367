import { h } from "preact";
import { ErrorView } from "./ErrorView";
// import * as styles from "./NotFound.module.scss";

type Props = {};

/**
 * Renders a simple 404 style error page.
 */
export function NotFoundView({ }: Props) {
    return (
        <ErrorView message="Page Not Found" />
    );
}