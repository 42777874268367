import { h, ComponentChildren } from "preact";
import { memo } from "preact/compat";
import { useToast } from "../contexts/Toast";
import { TicketStatus, useAckTicketMutation } from "../graphql/generated";
import { Button } from "./controls/Button";
import { Spinner } from "./indicators/Spinner";
// import * as styles from "./GrabTicketButton.module.scss";

type Props = {
    /** ID of the ticket that we're trying to acknowledge. */
    ticketId: string;
    /** When true, the button will be disabled. */
    disabled?: boolean;
    /** Event that is called when the button action succeeds. */
    onSuccess?(data: { id: string, status: TicketStatus }): void;
    /** Content that will be rendered in the button. */
    children?: ComponentChildren;
};

/**
 * Renders a button that will attempt to acknowledge the current ticket.
 */
export const AckTicketButton = memo(({ ticketId, disabled, onSuccess, children }: Props) => {
    const toast = useToast();
    const [{ fetching }, ackTicket] = useAckTicketMutation();

    const onClick: h.JSX.MouseEventHandler<HTMLButtonElement> = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        const { error, data } = await ackTicket({ ticketId });

        if (error) {
            toast({ type: "error", message: error.message });
            return;
        }

        toast({ type: "success", message: `Service request ${ticketId} was successfully acknowledged.` });

        if (onSuccess) {
            onSuccess(data!.ticket);
        }
    };

    return (
        <Button
            label="Acknowledge Service Request"
            onClick={onClick}
            disabled={disabled}
            loading={fetching}
        >
            {children ?? "Acknowledge Service Request"}
        </Button>
    );
});