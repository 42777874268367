import { h } from "preact";
import { useToast } from "../../contexts/Toast";
import { TicketPriority, useSetTicketPriorityMutation } from "../../graphql/generated";
import { memo } from "preact/compat";
import { SelectField } from "./SelectField";

type Props = {
    /** The ticket id */
    ticketId: string;
    /** Currently selected value. */
    value: string;
    /** When true, the field will be disabled. */
    disabled?: boolean;
    /** Event that is called when the status of the ticket changes. */
    onChanged?(): void;
};

const PRIORITY_OPTIONS = {
    [TicketPriority.Entrapment]: "Entrapment",
    [TicketPriority.High]: "High",
    [TicketPriority.Standard]: "Standard",
    [TicketPriority.Low]: "Low",
};

/**
 * Renders the select field for changing call priority.
 */
export const CallPriorityField = memo(({ ticketId, value, disabled, onChanged }: Props) => {
    const toast = useToast();
    const [{ fetching }, mutate] = useSetTicketPriorityMutation();

    const onChange: h.JSX.GenericEventHandler<HTMLSelectElement> = async (ev) => {
        ev.preventDefault();
        const priority = ev.currentTarget!.value;
        const { error } = await mutate({ id: ticketId, priority: priority as TicketPriority });

        if (error) {
            toast({ type: "error", message: error.message });
            return;
        }

        toast({
            type: "success",
            message: `Successfully updated call priority to "${priority}".`,
        });

        if (onChanged) {
            onChanged();
        }
    };

    return (
        <SelectField
            hideLabel
            name="callPriority"
            options={PRIORITY_OPTIONS}
            value={value}
            onChange={onChange}
            disabled={fetching || disabled}
        />
    );
});