import { ComponentChildren, h } from "preact";
import { useAuth } from "../contexts/Auth";
import { Drawer } from "./Drawer";
import { useState } from "preact/hooks";
import logo from "../img/equick-logo.svg";
import * as styles from "./AppLayout.module.scss";

export type NavItem = Drawer.ButtonProps;

type Props = {
    /** The nav items to be displayed in the nav drawer. */
    navItems: NavItem[];
    /** The page contents that will be rendered in the app. */
    children: ComponentChildren;
}

/**
 * Rendes the main app layout. Namely this is the nav bar at the top and the navigation
 * drawer that opens from the side.
 */
export function AppLayout({ navItems, children }: Props) {
    const { user, logout } = useAuth();
    const [isOpen, setOpen] = useState(false);

    const close = () => setOpen(false);

    return (
        <div className={styles.root}>

            <nav className={styles.navbar}>
                <Drawer
                    alignLeft
                    open={isOpen}
                    onClose={close}
                >
                    <header className={styles.drawerHeader}>
                        <strong>{user!.name}</strong>
                        <small>{user!.email}</small>
                    </header>
                    <Drawer.Scroll>
                        {navItems.map(item =>
                            <Drawer.Button key={item.label} onClick={close} {...item} />
                        )}
                    </Drawer.Scroll>
                    <footer className={styles.drawerFooter}>
                        <Drawer.Button
                            // icon={<ManageUser />}
                            href={`/users/${user!.id}/edit`}
                            label="Edit Profile"
                            onClick={close}
                        />
                        <Drawer.Button
                            // icon={<ManageUser />}
                            href="/changePassword"
                            label="Change Password"
                            onClick={close}
                        />
                        <Drawer.Button onClick={logout} label="Logout" />
                    </footer>
                </Drawer>
                <div className={styles.trigger} onClick={() => setOpen(!isOpen)}>
                    <span />
                </div>
                <a className={styles.logo} href="/">
                    <img src={logo} alt="logo" />
                </a>
            </nav>

            <section>
                {children}
            </section>
        </div>
    );
}