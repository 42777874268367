import { h, Fragment } from "preact";
import { ServiceZoneRefField } from "../../components/controls/ServiceZoneRefField";
import { createFormPage } from "../../factories/createFormPage";
import { useDeleteServiceZoneMutation, useGetServiceZoneQuery, DeleteServiceZoneMutation, GetServiceZoneQuery, DeleteServiceZoneMutationVariables } from "../../graphql/generated";

type Fields = DeleteServiceZoneMutationVariables;
type RouteParams = { id: string };
type MutRes = DeleteServiceZoneMutation;
type MutVar = DeleteServiceZoneMutationVariables;
type Query = GetServiceZoneQuery;

/**
 * Renders the form view for adding a new serviceZone.
 */
export const DeleteServiceZoneFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Remove Service Zone",
    useDefaultValues: ({ data }) => ({
        id: data!.serviceZone.id,
        replacementId: "",
    }),
    toVars: fields => fields,
    useQuery: ({ id }) => useGetServiceZoneQuery({ variables: { id } }),
    useMutation: useDeleteServiceZoneMutation,
    onSuccess: (res, { history }) => history.replace("/serviceZones"),
    onSuccessMessage: res => `Successfully deleted service zone "${res.serviceZone.name}".`,
    render({ register, control, route }) {
        const id = route.match.params.id;

        return (
            <>
                <p>
                    Because this service zone currently has buildings assigned to it, you must specify
                    a different service zone that the assigned buildings will be moved to.
                </p>

                <ServiceZoneRefField
                    placeholder="Start typing to search service zones"
                    label="Replacement Service Zone"
                    name="replacementId"
                    control={control}
                    filter={sz => sz.id !== id}
                    required
                />
            </>
        );
    },
});