import { useMemo } from "preact/hooks";

export function useDebounce<T extends (...args: unknown[]) => void>(cb: T, delay: number, deps: unknown[] = []) {
    return useMemo(() => {
        let timer: number;
        return (...args: Parameters<T>) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                cb(...args);
            }, delay);
        };
    }, deps);
}