.note {
    margin: 50px 0;
}

.setAll,
.setAllButtons,
.subscriber {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.setAll {
    margin: 30px 0;
    justify-content: flex-end;

    > a {
        margin: 0 0 0 20px;
        display: block;
        user-select: none;
        font-weight: bold;
    }
}

.subscribers {
    &:hover {
        > .subscriber {
            opacity: 0.5;

            &:hover {
                opacity: 1.0;
            }
        }
    }
}

.subscriber {
    margin: 20px 0;
    transition: opacity 100ms ease;
}

.subscriberInfo {
    padding: 0 10px;
    flex: 1;
    font-size: 14px;
    color: #666;
    overflow: hidden;

    > h4 {
        font-size: 16px;
        font-weight: normal;
        color: #111;
    }
}

.select {
    width: 180px;
}