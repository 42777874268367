.hint {
    margin: 20px 0;
    padding: 20px;
    border: dashed 2px currentColor;

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > p,
    > ul,
    > ol,
    > button {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    > ul {
        list-style: inside disc;

        > li {
            margin: 5px 0;
        }
    }

    &.warning {
        border: solid 2px rgb(255, 147, 23);
        background: rgb(255, 232, 190);
    }
}
