import { h, Fragment } from "preact";
import { AddressFields } from "../../components/controls/AddressFields";
import { CustomerRefField } from "../../components/controls/CustomerRefField";
import { SelectField } from "../../components/controls/SelectField";
import { ServiceZoneRefField } from "../../components/controls/ServiceZoneRefField";
import { TextField } from "../../components/controls/TextField";
import { UserRefField } from "../../components/controls/UserRefField";
import { CONTRACT_CODE_OPTS } from "../../constants";
import { createFormPage } from "../../factories/createFormPage";
import { ContractCode, CreateBuildingInput, CreateBuildingMutation, CreateBuildingMutationVariables, useCreateBuildingMutation, UserRole } from "../../graphql/generated";

type Fields = CreateBuildingInput;
type RouteParams = { id?: string };
type MutRes = CreateBuildingMutation;
type MutVar = CreateBuildingMutationVariables;

/**
 * Renders the form view for adding a new building.
 */
export const CreateBuildingFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, null>({
    title: () => "Add Building",
    useDefaultValues: ({ match }) => {
        const customerId = match.params.id || "";

        return {
            name: "",
            serviceZoneId: "",
            customerId,
            email: "",
            phoneNumber: "",
            serviceLocation: "Unit 1",
            buildingAccessNotesUrl: "",
            contractCode: ContractCode.Fm8,
            technicianId: null,
            address: {
                street: "",
                city: "",
                state: "",
                zipcode: "",
                countryCode: "US",
            },
        };
    },
    toVars({ email, phoneNumber, ...fields }) {
        return {
            input: {
                ...fields,
                // empty strings are not allowed on the server, nullify empty optional fields
                email: email || null,
                phoneNumber: phoneNumber || null,
            },
        };
    },
    useMutation: useCreateBuildingMutation,
    onSuccess: (res, { history }) => history.replace(`/buildings/${res.building.id}`),
    onSuccessMessage: res => `Successfully invited new building "${res.building.name}".`,
    render({ register, control, route }) {
        const customerId = route.match.params.id;
        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Building Name"
                    required
                />

                {/* <TextField
                    register={register}
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    label="Building Email"
                />

                <PhoneNumberField
                    register={register}
                    name="phoneNumber"
                    label="Building Phone Number"
                /> */}

                <UserRefField
                    placeholder="Start typing to search"
                    control={control}
                    name="technicianId"
                    label="Assigned Technician"
                    role={[UserRole.Technician]}
                    required
                />

                {!customerId && (
                    <CustomerRefField
                        placeholder="Start typing to search"
                        label="Customer Account"
                        name="customerId"
                        control={control}
                        required
                    />
                )}

                <TextField
                    register={register}
                    type="text"
                    name="buildingAccessNotesUrl"
                    placeholder="https://"
                    label="Building Access Instructions URL"
                />

                <ServiceZoneRefField
                    placeholder="Start typing to search"
                    label="Service Zone"
                    name="serviceZoneId"
                    control={control}
                    required
                />

                <AddressFields
                    register={register}
                    required
                />

                <hr />

                <TextField
                    register={register}
                    type="text"
                    name="serviceLocation"
                    label="Default Service Location Name"
                    required
                />
                <SelectField
                    register={register}
                    name="contractCode"
                    label="Default Service Location Contract Code"
                    options={CONTRACT_CODE_OPTS}
                    required
                />
            </>
        );
    },
});