import { h, Fragment } from "preact";
import cx from "classnames";
import { Control, FieldPath, FieldValues, useController } from "react-hook-form";
import { useClient } from "@urql/preact";
import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
import { SearchBuildingsDocument, SearchBuildingsQuery, SearchBuildingsQueryVariables } from "../../graphql/generated";
import { OptionLookupField } from "./OptionLookupField";
import { SelectField } from "./SelectField";
import { Close } from "../Icons";
import * as styles from "./Field.module.scss";

type Building = NonNullable<SearchBuildingsQuery["buildings"]>["nodes"][0];

type Props<TFieldValues extends FieldValues = FieldValues> = {
    /** When true, this field will be required for the form. */
    required?: boolean;
    /** The service location field name. */
    name: FieldPath<TFieldValues>;
    /** Reference to the hook form control object. */
    control: Control<TFieldValues>;
};

/**
 * This is a special field that will actually render two controls. The first control is a
 * building ref field (but not really) that is used to find the relevant building. Once the
 * building is known, the service location will be rendered as a simple select field.
 */
export function ServiceLocationRefField<TFieldValues>({
    required,
    name,
    control,
}: Props<TFieldValues>) {
    const urqlClient = useClient();
    const [building, setBuilding] = useState<null | Building>(null);
    const [buildings, setBuildings] = useState<Building[]>([]);
    const [locationId, setLocationId] = useState("");

    const classes = cx(styles.field, {
        [styles.required]: required,
    });

    const { field } = useController({
        name,
        control,
        rules: { required },
    });

    const onSearch = useCallback(async (search: string) => {
        if (search) {
            const _results = await urqlClient
                .query<SearchBuildingsQuery, SearchBuildingsQueryVariables>(SearchBuildingsDocument, { search }).toPromise();
            setBuildings(_results.data?.buildings?.nodes ?? []);
        } else {
            setBuildings([]);
        }
    }, [setBuildings]);

    const opts = useMemo(() => {
        let out: { [key: string]: string } = {};

        const locs = building?.serviceLocations ?? [];
        for (const loc of locs) {
            out[loc.id] = loc.name;
        }

        return out;
    }, [building]);

    useEffect(() => {
        const locs = building?.serviceLocations ?? [];
        setLocationId(locs.length > 0 ? locs[0].id : "");
    }, [building]);

    useEffect(() => {
        field.onChange(locationId);
    }, [field, locationId]);

    let _contents: JSX.Element;

    // does the field contain a value already?
    if (building) {
        _contents = (
            <div className={styles.refInput}>
                {renderBuilding(building)}
                <a onClick={() => setBuilding(null)}>
                    <Close />
                </a>
            </div>
        );
    }
    else {
        _contents = (
            <OptionLookupField<Building>
                placeholder="Start typing to search buildings"
                required={required}
                options={buildings}
                onSearch={onSearch}
                onSelected={setBuilding}
                children={renderBuilding}
            />
        );
    }

    return (
        <>
            <div className={classes}>
                <label className={styles.label}>Building</label>
                {_contents}
            </div>

            <SelectField
                label="Service Location"
                value={locationId}
                onChange={ev => setLocationId((ev.target as HTMLSelectElement).value)}
                name={name}
                required={required}
                options={opts}
                disabled={!building}
            />
        </>
    );
}

function renderBuilding(building: Building) {
    return (
        <span>{building.name}</span>
    );
}