import { ComponentType, h } from "preact";
import { useToast } from "../contexts/Toast";
import { FooterActions } from "../components/FooterActions";
import { UseMutationResponse, UseQueryResponse } from "@urql/preact";
import { DeepPartial, FormProvider, UnpackNestedValue, useForm, UseFormReturn } from "react-hook-form";
import { PageHeader } from "../components/PageHeader";
import { RouteComponentProps } from "react-router-dom";
import { LoaderView } from "../views/Loader";
import { ErrorView } from "../views/ErrorView";
import { NotFoundView } from "../views/NotFound";
import { AuthUser, useAuth } from "../contexts/Auth";
import * as styles from "./createFormPage.module.scss";

type Props<Params, QueryData> = RouteComponentProps<Params> & { data?: QueryData };

type CreateFormPageOpts<FormFields, Params, QueryData, MutationResult, MutationVars = { input: FormFields }> = {
    /** Provides the page header's title from the entity data. */
    title(props: Props<Params, QueryData>): string;
    /** Provides the sub title that can optionally be used in the page's header. */
    subTitle?(props: Props<Params, QueryData>): string;
    /** Converts form field values into the necessary mutation variables. */
    toVars?(fields: UnpackNestedValue<FormFields>): MutationVars;
    /** Provides the default values for the form. */
    useDefaultValues(props: Props<Params, QueryData> & { auth: AuthUser }): FormFields;
    /** The mutation hook used with this form. */
    useMutation(): UseMutationResponse<MutationResult, MutationVars>;
    /** When present, adds a page action to the form's header. */
    pageAction?: ComponentType<{
        data?: QueryData;
        route: RouteComponentProps<Params>;
        auth: AuthUser;
    }>;
    /** Toast message that will be displayed when the mutation succeeds. */
    onSuccessMessage(res: MutationResult, props: Props<Params, QueryData>): string;
    /** Event that is called when the form mutation is executed successfully. */
    onSuccess?(res: MutationResult, props: Props<Params, QueryData>): void;
    /** Overrides the default cancel action when provided. */
    onCancel?(): void;
    /** Sub-component for displaying the fields for the form. */
    render: ComponentType<UseFormReturn<FormFields> & {
        data?: QueryData;
        route: RouteComponentProps<Params>;
        auth: AuthUser;
    }>;
} & (
        | {
            /** When present, allows a query to be performed to get related data used in the form. */
            useQuery(params: Params, props: RouteComponentProps<Params>): UseQueryResponse<QueryData, {}>;
            /** When present, allows a custom function to determine if a NotFound view should be displayed. */
            showNotFound?(data: QueryData): boolean;
        }
        | {
            useQuery?: never;
            showNotFound?: never;
        }
    );

const defaultToVars = (obj: any): any => {
    let out = { ...obj };
    for (let k in out) {
        if (typeof out[k] === "string" && !out[k]) {
            out[k] = null;
        }
    }

    return { input: out };
}

/**
 * Factory function that assists in the creation of form pages. Handles a lot of common boilerplate
 * and layout tasks related to forms, and even supports query hooks for edit forms.
 */
export function createFormPage<FormFields, Params, MutationResult, MutationVars, QueryData>({
    title,
    subTitle,
    toVars = defaultToVars,
    showNotFound,
    useQuery,
    useDefaultValues,
    useMutation,
    onSuccessMessage,
    onSuccess,
    onCancel,
    pageAction: PageActionComponent,
    render: FieldsComponent,
}: CreateFormPageOpts<FormFields, Params, QueryData, MutationResult, MutationVars>) {
    function FormComponent(props: RouteComponentProps<Params> & { data?: QueryData }) {
        const history = props.history;
        const data = props.data;

        const auth = useAuth();
        const toast = useToast();

        const [mutRes, mutate] = useMutation();
        const form = useForm<FormFields>({
            mode: "onChange",
            defaultValues: useDefaultValues({ ...props, auth: auth.user! }) as UnpackNestedValue<DeepPartial<FormFields>>,
        });


        const onSubmit = form.handleSubmit(async (formValues) => {
            const { error, data: res } = await mutate(toVars(formValues));

            if (error) {
                const message = error.graphQLErrors.length > 0
                    ? error.graphQLErrors[0].message
                    : error.message;

                toast({
                    type: "error",
                    message: message.replaceAll("[GraphQL]", ""),
                });
                return;
            }

            toast({
                type: "success",
                message: onSuccessMessage(res!, props),
            });

            if (onSuccess) {
                onSuccess(res!, props);
            }
        });

        return (
            <FormProvider {...form}>
                <form onSubmit={onSubmit}>
                    <PageHeader>
                        <PageHeader.Row>
                            <PageHeader.Title
                                title={title(props)}
                                subTitle={subTitle ? subTitle(props) : undefined}
                            />
                            {PageActionComponent && (
                                <PageActionComponent
                                    route={props}
                                    data={data}
                                    auth={auth.user!}
                                />
                            )}
                        </PageHeader.Row>
                    </PageHeader>
                    <section className={styles.items}>
                        <FieldsComponent
                            {...form}
                            route={props}
                            data={data}
                            auth={auth.user!}
                        />
                    </section>
                    <FooterActions
                        disableAll={mutRes.fetching}
                        actions={[
                            {
                                label: "Cancel",
                                outlined: true,
                                onClick(ev) {
                                    ev.preventDefault();
                                    if (onCancel) onCancel();
                                    else history.goBack();
                                },
                            },
                            {
                                label: "Save",
                                type: "submit",
                                disabled: !form.formState.isValid,
                            },
                        ]}
                    />
                </form>
            </FormProvider>
        );

    };

    if (!useQuery) {
        return FormComponent;
    }

    return function (props: RouteComponentProps<Params>) {
        const [{ fetching, error, data }, refetch] = useQuery(props.match.params, props);

        if (fetching) {
            return (
                <LoaderView />
            );
        }

        if (error) {
            return (
                <ErrorView
                    message={error.message}
                    onRetry={refetch ?? undefined}
                />
            );
        }

        if (!data || (showNotFound && showNotFound(data))) {
            return (
                <NotFoundView />
            );
        }

        return (
            <FormComponent data={data} {...props} />
        );
    }
}