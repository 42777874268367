%hover-pop {
    @media screen and (min-width: 820px) {
        transition: transform 250ms ease;

        &:hover {
            transform: scale(1.1);
        }
    }

    @media (prefers-reduced-motion) {
        transition: none !important;
    }
}

.avatar {
    display: inline-flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: medium;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    user-select: none;
    border-radius: 50%;
    width: 3em;
    align-self: center;

    > img {
        width: 100%;
        height: 100%;
    }

    &:before {
        display: block;
        padding-bottom: 100%;
        content: "";
    }
}

a.avatar,
.avatar.isClickable {
    @extend %hover-pop;
    cursor: pointer;
}
