import { h, Fragment } from "preact";
import { TextField } from "../components/controls/TextField";
import { createFormPage } from "../factories/createFormPage";
import { ChangePasswordMutation, ChangePasswordMutationVariables, useChangePasswordMutation } from "../graphql/generated";

type Fields = ChangePasswordMutationVariables & { newPasswordConfirmation: string };
type RouteParams = {};
type MutRes = ChangePasswordMutation;
type MutVar = ChangePasswordMutationVariables;

const PASSWORD_PATTERN = /[A-Za-z0-9\_\!\$\&]+/;

/**
 * Renders the form view for changing the user's password.
 */
export const ChangePasswordFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, null>({
    title: () => "Change Password",
    useDefaultValues: () => ({
        password: "",
        newPassword: "",
        newPasswordConfirmation: "",
    }),
    toVars: ({ password, newPassword }) => ({ password, newPassword }),
    useMutation: useChangePasswordMutation,
    onSuccess: (res, { history }) => history.goBack(),
    onSuccessMessage: res => "Your password was successfully updated.",
    render({ register, getValues }) {
        return (
            <>
                <TextField
                    register={register}
                    type="password"
                    name="password"
                    label="Current Password"
                    required
                />
                <TextField
                    register={register}
                    type="password"
                    name="newPassword"
                    label="New Password"
                    minLength={8}
                    maxLength={32}
                    pattern={PASSWORD_PATTERN}
                    required
                />
                <TextField
                    register={register}
                    type="password"
                    name="newPasswordConfirmation"
                    label="New Password (Confirmed)"
                    minLength={8}
                    maxLength={32}
                    pattern={PASSWORD_PATTERN}
                    required
                    validate={val => val === getValues().newPassword || "doesn't match 'New Password'"}
                />
            </>
        );
    },
});