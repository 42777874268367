import { h, Fragment } from "preact";
import { SelectField } from "../../components/controls/SelectField";
import { TextField } from "../../components/controls/TextField";
import { CONTRACT_CODE_OPTS } from "../../constants";
import { createFormPage } from "../../factories/createFormPage";
import { useUpdateServiceLocationMutation, useGetServiceLocationQuery, UpdateServiceLocationInput, UpdateServiceLocationMutation, GetServiceLocationQuery, UpdateServiceLocationMutationVariables } from "../../graphql/generated";

type Fields = UpdateServiceLocationInput;
type RouteParams = { id: string, buildingId: string };
type MutRes = UpdateServiceLocationMutation;
type MutVar = UpdateServiceLocationMutationVariables;
type Query = GetServiceLocationQuery;

/**
 * Renders the form view for updating service location.
 */
export const UpdateServiceLocationFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Edit Service Location",
    useDefaultValues: ({ data }) => {
        const sl = data!.building!.serviceLocation!;

        return {
            id: sl.id,
            name: sl.name,
            contractCode: sl.contractCode,
        };
    },
    showNotFound: data => !data || !data.building || !data.building.serviceLocation,
    useQuery: ({ id, buildingId }) => useGetServiceLocationQuery({ variables: { id, buildingId } }),
    useMutation: useUpdateServiceLocationMutation,
    onSuccess: (res, { history, match }) => history.replace(`/buildings/${match.params.buildingId}/serviceLocations/${match.params.id}`),
    onSuccessMessage: res => `Successfully updated service location "${res.serviceLocation.name}".`,
    render({ register }) {
        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Service Location Name"
                    required
                />
                <SelectField
                    register={register}
                    name="contractCode"
                    label="Contract Code"
                    options={CONTRACT_CODE_OPTS}
                    required
                />
            </>
        );
    },
});