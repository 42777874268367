import { h } from "preact";
import { useEffect } from "preact/hooks";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { AppLayout } from "../../components/AppLayout";
import { Avatar } from "../../components/Avatar";
import { useGetCustomersQuery } from "../../graphql/generated";
import { ChangePasswordFormView } from "../ChangePasswordFormView";
import { CommentsView } from "../CommentsView";
import { ErrorView } from "../ErrorView";
import { LoaderView } from "../Loader";
import { NotFoundView } from "../NotFound";
import { UpdateUserProfileFormView } from "../UpdateUserProfileFormView";
import { UserProfileView } from "../UserProfileView";
import { CustomerCreateTicketFormView } from "./CustomerCreateTicket";
import { CustomerEntryView } from "./CustomerEntryView";
import { CustomerReportEntrapmentFormView } from "./CustomerReportEntrapment";
import { CustomerTicketsListView } from "./CustomerTicketsListView";
import { CustomerTicketView } from "./CustomerTicketView";

type Props = {};

/**
 * User routes when logged in as a customer.
 */
export function CustomerRoutes({ }: Props) {
    // get all customers that the user has access to on initial load - this will probably never
    // exceed 5 accounts... most users will only have access to 1
    const [result, refetch] = useGetCustomersQuery({ variables: { query: { perPage: 150 } } });
    const { fetching, error, data } = result;

    useEffect(() => {
        if (result.fetching || !result.data) return;

        window.Intercom("update", {
            companies: result.data.customers.nodes.map(c => ({
                company_id: c.id,
                name: c.name,
            })),
        });
    }, [result]);

    if (fetching) {
        return (<LoaderView />);
    }

    if (error || !data) {
        return (
            <ErrorView
                message="Failed to fetch customer access information."
                onRetry={refetch}
            />
        );
    }

    const alwaysRoutes = [
        <Route path="/:type/:id/comments" exact component={CommentsView} />,
        <Route path="/changePassword" exact component={ChangePasswordFormView} />,
        <Route path="/users/:id/edit" exact component={UpdateUserProfileFormView} />,
        <Route path="/users/:id" exact component={UserProfileView} />,
    ];

    if (data.customers.nodes.length === 0) {
        return (
            <AppLayout navItems={[]}>
                <Switch>
                    {alwaysRoutes}
                    <ErrorView
                        message="You are not assigned to any customer accounts."
                    />
                </Switch>
            </AppLayout>
        );
    }

    const first = data.customers.nodes[0];
    const navItems = data.customers.nodes.map(c => ({
        href: "/acc/" + c.id,
        label: c.name,
        icon: <Avatar name={c.name} size={12} />,
    }));

    return (
        <AppLayout navItems={navItems}>
            <Switch>
                <Route path="/acc/:customerId/tickets/create/entrapment" exact component={CustomerReportEntrapmentFormView} />
                <Route path="/acc/:customerId/tickets/create" exact component={CustomerCreateTicketFormView} />
                <Route path="/acc/:customerId/tickets/:id" exact component={CustomerTicketView} />
                <Route path="/acc/:customerId/tickets" exact component={CustomerTicketsListView} />
                <Route path="/acc/:customerId" exact component={CustomerEntryView} />
                <Route path="/tickets/:id" exact component={CustomerTicketView} />
                {alwaysRoutes}
                <Redirect path="/" exact to={"/acc/" + first.id} />
                <NotFoundView />
            </Switch>
        </AppLayout>
    );
}