.tag {
    line-height: 1rem;
    font-size: 0.6rem;
    text-align: center;
    color: rgba(41, 41, 41, 0.6);
    background-color: #e2e2e2;
    border: 1px solid #c7c7c7;
    border-radius: 12px;
    width: 5rem;
    padding: 0.15rem 0.3rem;
    margin-right: 0.5rem;

    &.unassigned {
        background-color: #e2e2e2;
        border: 1px solid #c7c7c7;
    }

    &.paused {
        background-color: #fffcb4;
        border-color: #ebe8a2;
    }

    &.assigned {
        background-color: #d9f1fc;
        border-color: #b8d4e8;
    }

    &.enroute {
        background-color: #ffe2c0;
        border-color: #e2c8a0;
    }

    &.onsite {
        background-color: #efe2ff;
        border-color: #d0bdf8;
    }

    &.closed {
        background-color: #e4f8e2;
        border-color: #bce8b8;
    }
}
