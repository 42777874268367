.button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 3em;
    font: inherit;
    font-weight: bold;
    user-select: none;
    overflow: hidden;
    color: #fff;
    background-color: #131313;
    // border: 2px solid #fff;
    text-transform: uppercase;
    min-height: 44px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.5px;
    letter-spacing: 0.08em;

    // &:before {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     display: block;
    //     content: "";
    //     background: rgba(255, 255, 255, 0.2);
    //     opacity: 0;
    //     transition: opacity 100ms ease;
    //     z-index: 0;
    // }

    &.bordered {
        background-color: #fff;
        border: solid 2px #131313;
        color: #131313;
    }

    &.danger {
        background-color: #FF5C5D;
        color: #fff;
    }

    &.danger.bordered {
        border: solid 2px #FF5C5D;
        background-color: #Ffff;
        color: #FF5C5D;
    }

    &:focus,
    &:hover {
        &:before {
            opacity: 1;
        }
    }

    &:disabled {
        color: #717171;
        background-color: #C0C0C0;
        border-color: #B7B7B7;
        // opacity: 0.75;

        // &:before {
        //     opacity: 0;
        // }
    }

    // > .spinner {
    //     margin: 0 1rem;
    // }
    &.isFullWidth {
        width: 100%;
    }

    &.requestReset {
        margin-top: 30px;
    }

    @media(max-width: 768px) {
        padding: 0 1em;
    }
}

.label {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
    text-align: center;

    > svg {
        &:first-child {
            margin-right: 0.5rem;
        }
    }

}