import { h } from "preact";
import { Client, Provider } from "@urql/preact";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { AuthProvider } from "../contexts/Auth";
import { ToastProvider } from "../contexts/Toast";

type Props = {
    /** The URQL client object used for accessing our GraphQL API. */
    apiClient: Client;
};

/**
 * The root component for our application. This is configured and rendered to the
 * our DOM via the compositional root (index.tsx).
 */
export function Root({ apiClient }: Props) {
    return (
        <Provider value={apiClient}>
            <ToastProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <Routes />
                    </AuthProvider>
                </BrowserRouter>
            </ToastProvider>
        </Provider>
    );
}