import { h, Fragment } from "preact";
import { ManageClient, SortBy } from "../../components/Icons";
import { createListPage, createOptionsFromEnum } from "../../factories/createListPage";
import { CustomerSortBy, GetCustomersQuery, SortOrder, useGetCustomersQuery, UserRole } from "../../graphql/generated";

type Customer = GetCustomersQuery["customers"]["nodes"][0];

/**
 * Renders the full list of customers and allows for new customer accounts to be created.
 */
export const CustomersListView = createListPage<Customer>({
    title: "Manage Customers",
    icon: <ManageClient />,
    filterOptions: {
        sortBy: {
            label: "Sort By",
            icon: <SortBy />,
            default: CustomerSortBy.Name,
            options: createOptionsFromEnum(CustomerSortBy),
        },
    },
    footerActions: ({ history, auth }) => auth.role !== UserRole.Dispatch ? [] : [
        {
            label: "Add Customer Account",
            onClick: () => history.push("/customers/create"),
        },
    ],
    getUrl: ({ id }) => `/customers/${id}`,
    useQuery: (query) => {
        const [{ fetching, error, data }] = useGetCustomersQuery({
            variables: {
                query: {
                    search: (query.search as string) || null,
                    page: parseInt(query.page as string),
                    perPage: parseInt(query.perPage as string),
                    sortBy: query.sortBy as CustomerSortBy,
                    sortOrder: query.orderBy as SortOrder,
                },
            },
        });

        return {
            fetching,
            error,
            pageInfo: data?.customers.pageInfo,
            items: data?.customers.nodes,
        };
    },
    renderItem(customer) {
        return (
            <>
                <h3>{customer.name}</h3>
                <div style={{ marginLeft: 15 }}>{customer.email}</div>
            </>
        );
    },
});