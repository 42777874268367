import { h, Fragment } from "preact";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { usePauseTicketMutation, PauseTicketMutation, GetTicketMinimalQuery, PauseTicketMutationVariables, useGetTicketMinimalQuery, TicketStatus, UserRole } from "../../graphql/generated";

type Fields = PauseTicketMutationVariables;
type RouteParams = { id: string };
type MutRes = PauseTicketMutation;
type MutVar = PauseTicketMutationVariables;
type Query = GetTicketMinimalQuery;

/**
 * Renders the form view for closing a ticket.
 */
export const PauseTicketFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Pause Service Request",
    useDefaultValues: ({ data }) => ({
        id: data!.ticket!.id,
        comment: "",
    }),
    showNotFound: (data) => !data || !data.ticket || data.ticket.status === TicketStatus.Closed,
    useQuery: ({ id }) => useGetTicketMinimalQuery({ variables: { id } }),
    toVars: ({ id, comment }) => ({ id, comment }),
    useMutation: usePauseTicketMutation,
    onSuccess: (res, { history }) => history.replace(`/tickets/${res.ticket.id}`),
    onSuccessMessage: res => `Successfully updated service request: ${res.ticket.id}`,
    render({ register }) {
        return (
            <>
                <TextField
                    register={register}
                    type="multiline"
                    name="comment"
                    label="Comment"
                    required
                />
            </>
        );
    },
});