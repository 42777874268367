import { h, Fragment } from "preact";
import { createEntityRefField } from "../../factories/createEntityRefField";
import { SearchUsersDocument, SearchUsersQuery, SearchUsersQueryVariables, UserRole } from "../../graphql/generated";
import { Avatar } from "../Avatar";

type User = SearchUsersQuery["users"]["nodes"][0];

type Props = {
    /** When present, filters resulting users by role. */
    role?: UserRole[];
};

export const UserRefField = createEntityRefField<User, Props>({
    // async fetchItem(id, { query }, { role = [] }) {
    //     const { error, data } = await query<GetUserMinimalQuery, GetUserMinimalQueryVariables>(GetUserMinimalDocument, { id }).toPromise();
    //     return !error && data ? data.user : null;
    // },
    async searchItems(search, { query }, { role = [] }) {
        const { error, data } = await query<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, { search, role }).toPromise();
        return !error && data ? data.users.nodes : [];
    },
    render(user) {
        return (
            <>
                <Avatar name={user.name} src={user.avatarUrl} size={8} />
                <span style={{ paddingLeft: 10 }}>{user.name}</span>
                <span style={{ paddingLeft: 20, opacity: 0.6 }}>{user.email}</span>
            </>
        );
    },
});