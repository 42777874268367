import { h } from "preact";
import cx from "classnames";
import snakeCase from "lodash/snakeCase";
import capitalize from "lodash/capitalize";
import { FieldPath, FieldPathValue, FieldValues, UseFormRegister, Validate } from "react-hook-form";
import { JSXInternal } from "preact/src/jsx";
import * as styles from "./Field.module.scss";
import { useFieldErrors } from "../../hooks/useFieldErrors";

type TextInputType =
    | "multiline"
    | "text"
    | "date"
    | "datetime"
    | "datetime-local"
    | "time"
    | "month"
    // | "week"
    | "email"
    | "number"
    | "password"
    | "search"
    // | "tel"
    | "url";

const EMAIL_ADDRESS_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export type Props<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = Omit<JSXInternal.HTMLAttributes, "type" | "pattern" | "name"> & {
    /** The type of text field. If you want a radio/checkbox input then use a different component. */
    type: TextInputType;
    /** The label used for the title and aria label of the button. */
    label?: string;
    /** When provided, renders contextual text underneath the label. */
    help?: string;
    /** Validation pattern (in Regex) to be applied to the field. */
    pattern?: RegExp;
    /** The field name. */
    name: TFieldName;
    /** The register function provided by React Hook Form. */
    register: UseFormRegister<TFieldValues>;
    /** Provide custom validation. */
    validate?: Validate<FieldPathValue<TFieldValues, TFieldName>> | Record<string, Validate<FieldPathValue<TFieldValues, TFieldName>>>;
};

/**
 * Renders a text field with label, required status, help text and so on.
 */
export function TextField<TFieldValues>({ type, label, help, id, value, name, pattern, validate, register, ...props }: Props<TFieldValues>) {
    if (!label) label = capitalize(name as string);
    if (!id) id = `text__${snakeCase(label)}`;

    const { isValid, error } = useFieldErrors(name);

    const classes = cx(styles.field, {
        [styles.required]: props.required,
        [styles.isInvalid]: !isValid,
    });

    const regProps = !register ? {} : register(name as any, {
        required: props.required,
        max: props.max,
        maxLength: props.maxLength,
        min: props.min,
        minLength: props.minLength,
        pattern: type === "email" ? EMAIL_ADDRESS_REGEX : pattern,
        validate: validate,
        valueAsNumber: type === "number",
    });

    return (
        <div className={classes}>
            <label className={styles.label} htmlFor={id}>{label}</label>
            {type === "multiline"
                ? <textarea id={id} {...regProps} {...props as any}>{value}</textarea>
                : <input id={id} type={type} value={value} pattern={pattern && pattern.source} {...regProps} {...props as any} />
            }
            {error && (<p className={styles.error}>{error}</p>)}
        </div>
    );
}