import { h, render } from "preact";
import { apiClient } from "./graphql/client";
import { Root } from "./views/Root";
import debounce from "lodash/debounce";
import "./reset.css";
import "./index.scss";

render(
    <Root
        apiClient={apiClient}
    />
    , document.getElementById("app")!
);


window.addEventListener("resize", debounce(ev => {
    window.Intercom("update", {
        hide_default_launcher:  window.innerWidth <= 640,
    });
}, 150));