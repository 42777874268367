import { h, Fragment } from "preact";
import { createOverviewPage } from "../../factories/createOverviewPage";
import { GetTicketQuery, TicketStatus, TicketTechnicianStatus, useGetTicketQuery, useSetTechStatusForTicketMutation } from "../../graphql/generated";
import { Checkmark, Comments, Edit, ManageClient } from "../../components/Icons";
import format from "date-fns/format";
import { StatusNodes } from "../../components/indicators/StatusNodes";
import { Drawer } from "../../components/Drawer";
import { useToast } from "../../contexts/Toast";
import { memo } from "preact/compat";
import { DateTime } from "../../components/DateTime";
import { CallPriorityField } from "../../components/controls/CallPriorityField";
import { PhoneLink } from "../../components/PhoneLink";
import { EmailLink } from "../../components/EmailLink";
import { Link } from "react-router-dom";
import { getStatusLabel } from "../../components/indicators/StatusTag";
import { AckTicketButton } from "../../components/AckTicketButton";
import { Hint } from "../../components/Hint";
import { GoogleMapLink } from "../../components/GoogleMapLink";
import * as styles from "./TicketView.module.scss";
import { TicketComment } from "../../components/TicketComment";

/**
 * Renders a single ticket and displays the ticket"s information and relationships. This view
 * is intended purely for employees (dispatchers and technicians) and contains all critical information
 * and ticket actions.
 */
export const TicketView = createOverviewPage<{ id: string }, GetTicketQuery>({
  useQuery: ({ id }) => useGetTicketQuery({ variables: { id } }),
  showNotFound: data => !data || !data.ticket,
  title: ({ ticket }) => ticket!.building.name,
  subTitle: ({ ticket }) => format(new Date(ticket!.createdAt), "MM/dd/yyyy hh:mmaaa"),
  actions({ data, refetch, close }) {
    const { id, status, technician, isAcknowledged } = data.ticket!;

    if (status === TicketStatus.Closed) {
      return null;
    }

    const onSuccess = () => {
      refetch();
      close();
    };

    return (
      <>
        <Drawer.Button
          icon={<Comments />}
          label="View Comments"
          href={`/tickets/${id}/comments`}
        />
        <Drawer.Button
          icon={<ManageClient />}
          label={technician ? "Re-Assign Request" : "Assign Request"}
          href={`/tickets/${id}/assign`}
        />
        {status !== TicketStatus.Paused && (
          <Drawer.Button
            icon={<Edit />}
            label="Place On Pause"
            href={`/tickets/${id}/pause`}
          />
        )}
        {(
          isAcknowledged
          && status !== TicketStatus.EnRoute
          && status !== TicketStatus.OnSite
        ) && (
            <SetTechStatusAction
              ticketId={id}
              label="Tech is En-Route"
              status={TicketTechnicianStatus.EnRoute}
              onSuccess={onSuccess}
            />
          )}
        {status === TicketStatus.EnRoute && (
          <SetTechStatusAction
            ticketId={id}
            label="Tech is On-Site"
            status={TicketTechnicianStatus.OnSite}
            onSuccess={onSuccess}
          />
        )}
        <Drawer.Button
          icon={<Checkmark />}
          label="Close Request"
          href={`/tickets/${id}/close`}
        />
      </>
    );
  },
  // info() { },
  contents({ data, refetch }) {
    const { reporter, comments, activity, building, serviceLocation, ...ticket } = data.ticket!;

    return (
      <>
        {ticket.isAcknowledgable && (
          <Hint>
            <p>
              This service request has been assigned to you, but you have not yet acknowledged it. Please acknowledge
              the service request assignment before beginning work.
            </p>

            <AckTicketButton
              ticketId={ticket.id}
              onSuccess={refetch}
            />
          </Hint>
        )}

        <h3>Building Information</h3>
        <p>
          <Link to={`/buildings/${building.id}`}>
            <strong>{building.name}</strong>
          </Link><br />
          {building.address.street}<br />
          {building.address.city}, {building.address.state} {building.address.zipcode}<br />

          <GoogleMapLink address={building.address} />
        </p>

        <h3>Service Location</h3>
        <p>
          <Link to={`/buildings/${building.id}/serviceLocations/${serviceLocation.id}`}>
            {serviceLocation.name}
          </Link>
        </p>

        <h3>Call Priority</h3>
        {ticket.closedAt ? (
          <p>{ticket.priority}</p>
        ) : (
          <CallPriorityField
            ticketId={ticket.id}
            value={ticket.priority}
            disabled={!!ticket.closedAt}
            onChanged={refetch}
          />
        )}

        <h3>Status</h3>
        <p>{getStatusLabel(ticket.status)}</p>

        <h3>Requirements</h3>
        <p>
          {ticket.requiresVaccination
            ? "The location requires a technician who has been vaccinated against COVID-19"
            : "There are no additional requirements for this location."}
        </p>

        {building.buildingAccessNotesUrl && (
          <>
            <h3>Building Access Instructions</h3>
            <p>
              <a href={building.buildingAccessNotesUrl} target="_blank">
                Click here to view instructions
              </a>
            </p>
          </>
        )}

        <h3>Comments</h3>
        <TicketComment comment={comments.length > 0 ? comments[0] : null} ticketId={ticket.id} />



        <h3>Request Details</h3>
        <p>{ticket.notes}</p>

        <h3>Overtime Allowed</h3>
        <p>{ticket.overtimeAllowed ? "Yes" : "No"}</p>

        <h3>Submitted By</h3>
        <p>
          {reporter.name}
          {reporter.email && (<div><EmailLink email={reporter.email} /></div>)}
          {reporter.phoneNumber && (<div><PhoneLink number={reporter.phoneNumber} /></div>)}
        </p>

        <h3>Resolution</h3>
        <p>{ticket.resolution || "Pending"}</p>

        <h3>Service Request Activity</h3>
        <StatusNodes>
          {activity.map(a => (
            <StatusNodes.Node key={a.id} label={a.label} completed>
              <p>
                {a.summary && (<>{a.summary}<br /></>)}
                <DateTime when={a.createdAt} />
              </p>
            </StatusNodes.Node>
          ))}
        </StatusNodes>
      </>
    );
  },
});


type SetTechStatusActionProps = {
  /** Id of the ticket being modified. */
  ticketId: string;
  /** Label to be rendered in the action button. */
  label: string;
  /** Status we want to set the ticket to. */
  status: TicketTechnicianStatus;
  /** Event that is called when the mutation succeeds. */
  onSuccess(): void;
};

const SetTechStatusAction = memo(({ ticketId, label, status, onSuccess }: SetTechStatusActionProps) => {
  const toast = useToast();
  const [, setTechStatus] = useSetTechStatusForTicketMutation();

  const onClick = async () => {
    const { error } = await setTechStatus({ id: ticketId, status });

    if (error) {
      toast({ type: "error", message: error.message });
      return;
    }

    toast({ type: "success", message: "Successfully changed status service request status." });
    onSuccess();
  };

  return (
    <Drawer.Button
      icon={<Edit />}
      label={label}
      onClick={onClick}
    />
  );
});