import { h, Fragment } from "preact";
import cx from "classnames";
import capitalize from "lodash/capitalize";
import snakeCase from "lodash/snakeCase";
import { useCallback, useEffect, useState } from "preact/hooks";
import { FieldValues, FieldPath, Control, useController } from "react-hook-form";
import { OptionLookupField } from "../components/controls/OptionLookupField";
import { Client, useClient } from "@urql/preact";
import { Close } from "../components/Icons";
import * as styles from "../components/controls/Field.module.scss";

type Props<Data, TFieldValues extends FieldValues = FieldValues, ExtraProps = {}> = ExtraProps & {
    /** Optional ID to use in place of the automatically generated one. */
    id?: string;
    /** When true, this field will be required for the form. */
    required?: boolean;
    /** The label used for the title and aria label of the button. */
    label?: string;
    /** When provided, each search result will be filtered using this prop as a predicate. */
    filter?(item: Data, index: number): boolean;
    /** Default data used to represent this field. */
    data?: null | Data;
    /** The field name. */
    name: FieldPath<TFieldValues>;
    /** Reference to the hook form control object. */
    control: Control<TFieldValues>;
    /** The placeholder content to render in the search field. */
    placeholder?: string;
    /** When present, allows auxiliary information to be rendered AFTER the field for the current selection. */
    children?(data: Data, props: ExtraProps): null | JSX.Element;
};

type CreateEntityRefFieldOpts<Data, ExtraProps> = {
    /** Searches the API for the items that match the current query. */
    searchItems(search: string, client: Client, props: ExtraProps): Promise<Data[]>;
    /** Pulls the value off of data to use in the field. _Defaults to `id`._ */
    getValueFromData?(data: Data): string;
    /** Renders the entity being displayed. */
    render(data: Data, props: ExtraProps): JSX.Element;
};

/**
 * Creates an input field that will query the API for user's matching the given query
 * parameters and allows a result to be selected from the list.
 */
export function createEntityRefField<Data, ExtraProps = {}>({
    searchItems,
    getValueFromData = data => (data as unknown as { id: string }).id,
    render,
}: CreateEntityRefFieldOpts<Data, ExtraProps>) {
    return function <TFieldValues, ExtraProps>({
        id,
        required,
        label,
        name,
        control,
        filter,
        data: defaultItem = null,
        children,
        placeholder,
        ...props
    }: Props<Data, TFieldValues, ExtraProps>) {
        if (!label) label = capitalize(name as string);
        if (!id) id = `user_ref__${snakeCase(label)}`;

        const valid = true;

        const classes = cx(styles.field, {
            [styles.required]: required,
            [styles.isInvalid]: !valid,
        });

        const urqlClient = useClient();
        const [item, setItem] = useState<null | Data>(defaultItem);
        const [results, setResults] = useState<Data[]>([]);

        const { field } = useController({
            name,
            control,
            rules: { required },
        });

        useEffect(() => {
            if (typeof field.value === "string" && field.value && !defaultItem) {
                field.onChange("");
            }
        }, []);

        const onSearch = useCallback(async (search: string) => {
            if (search) {
                const _results = await searchItems(search, urqlClient, props as any);
                setResults(filter ? _results.filter(filter) : _results);
            } else {
                setResults([]);
            }
        }, [setResults]);

        const onSelected = useCallback((data: Data) => {
            setItem(data);
            field.onChange(getValueFromData(data));
        }, [field.onChange, setItem]);

        const onClear = useCallback(() => {
            setItem(null);
            field.onChange("");
        }, [field.onChange, setItem])

        let _children: null | JSX.Element = null;
        let _contents: JSX.Element;

        // does the field contain a value already?
        if (field.value && item) {
            _contents = (
                <div className={styles.refInput}>
                    {render(item, props as any)}
                    <a onClick={onClear}>
                        <Close />
                    </a>
                </div>
            );

            if (children) {
                _children = children(item, props as any);
            }
        }
        // does the field contain a value but the data isn't present? it must be loading in
        else if (field.value) {
            _contents = (
                <div>
                    Loading
                </div>
            );
        }
        else {
            _contents = (
                <OptionLookupField<Data>
                    placeholder={placeholder}
                    required={required}
                    options={results}
                    onSearch={onSearch}
                    onSelected={onSelected}
                    children={item => render(item, props as any)}
                />
            );
        }

        return (
            <>
                <div className={classes}>
                    <label className={styles.label} htmlFor={id}>{label}</label>
                    {_contents}
                </div>
                {_children}
            </>
        );
    };
}