.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 20px 0;

    .label {
        flex: 1;
        opacity: 0.7;
        color: currentColor;
    }

    > input {
        width: auto;
    }

    > input:checked + .label {
        opacity: 1;
    }
}
