import { h, Fragment } from "preact";
import { useMemo } from "preact/hooks";
import { Checkbox } from "../../components/controls/Checkbox";
import { SelectField } from "../../components/controls/SelectField";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { CreateTicketInput, CreateTicketMutation, CreateTicketMutationVariables, GetCustomerTicketFormFieldsQuery, TicketPriority, useCreateTicketMutation, useGetCustomerTicketFormFieldsQuery } from "../../graphql/generated";

type Fields = Omit<CreateTicketInput, "requireTechnicianTags"> & { tags: string[] };
type RouteParams = { customerId: string };
type MutRes = CreateTicketMutation;
type MutVar = CreateTicketMutationVariables;
type Query = GetCustomerTicketFormFieldsQuery;

/**
 * Renders the form view for updating a building.
 */
export const CustomerReportEntrapmentFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Report Entrapment",
    useDefaultValues: ({ data }) => {
        const buildings = data!.customer!.buildings;
        const firstLoc = buildings.length > 0 && buildings[0].serviceLocations.length > 0
            ? buildings[0].serviceLocations[0].id
            : "";

        return {
            serviceLocationId: firstLoc,
            notes: "",
            priority: TicketPriority.Entrapment,
            tags: [],
            overtimeAllowed: false,
        };
    },
    showNotFound: ({ customer }) => !customer || customer.buildings.length === 0,
    toVars: ({ tags, ...fields }: Fields) => ({ input: { ...fields, requireTechnicianTags: tags![0] ? tags : [], overtimeAllowed: !!fields.overtimeAllowed } }),
    useQuery: ({ customerId }) => useGetCustomerTicketFormFieldsQuery({ variables: { id: customerId } }),
    useMutation: useCreateTicketMutation,
    onSuccess: (res, { match, history }) => history.replace(`/acc/${match.params.customerId}/tickets/${res.ticket.id}`),
    onSuccessMessage: res => `Entrapment was successfully reported.`,
    render({ data, register, getValues }) {

        const locationOptions = useMemo(() => {
            let opts: { [key: string]: string } = {};

            for (const building of data!.customer!.buildings) {
                for (const loc of building.serviceLocations) {
                    opts[loc.id] = `${loc.name} (${building.name})`;
                }
            }

            return opts;
        }, [data?.customer?.buildings]);

        return (
            <>
                <SelectField
                    register={register}
                    label="Service Location"
                    name="serviceLocationId"
                    options={locationOptions}
                    required
                />

                <TextField
                    register={register}
                    type="multiline"
                    name="notes"
                    label="Request Details"
                />

                <Checkbox
                    register={register}
                    name="tags.0"
                    label="Vaccination Required"
                    value="COVID19_VAX"
                />

                <Checkbox
                    register={register}
                    name="overtimeAllowed"
                    label="Overtime Allowed"
                    value="allowed"
                />
            </>
        );
    },
});