import { h, Fragment } from "preact";
import { JSXInternal } from "preact/src/jsx";
import { ParsedQs } from "qs";
import { CombinedError } from "@urql/preact";
import { LoaderView } from "../views/Loader";
import { Card } from "../components/CardList";
import { Action, FooterActions } from "../components/FooterActions";
import { Pagination, Props as PaginationProps } from "../components/Pagination";
import * as Icons from "../components/Icons";
import { useDebounce } from "../hooks/useDebounce";
import { RouteComponentProps } from "react-router-dom";
import mapValues from "lodash/mapValues";
import { PageHeader } from "../components/PageHeader";
import startCase from "lodash/startCase";
import { AuthUser, useAuth } from "../contexts/Auth";
import { useSearchParams } from "../hooks/useSearchParams";
import { FilterOption, FiltersDrawer } from "../components/FiltersDrawer";
import * as styles from "./createListPage.module.scss";


type QueryResult<T> = Partial<{
    /** Are we currently performing a fetch? */
    fetching: boolean;
    /** Did we encounter an error? */
    error: CombinedError;
    /** Pagination info returned from the query. */
    pageInfo: Omit<PaginationProps, "page" | "onPageChange">;
    /** The data that will be rendered as cards. */
    items: T[];
}>;


type CreateListPageOpts<Params, Item> = {
    /** Title of the page that appears above the query controls. */
    title: string;
    /** The icon used with this button. */
    icon?: JSX.Element;
    /** Builds the URL to go to a listing's page. */
    getUrl(item: Item, props: RouteComponentProps<Params>): string;
    /** Default query parameters for the paginated view. */
    //defaultSearch?: ParsedQs;
    /** Filter and sort options that can selected via the filters drawer. */
    filterOptions?: { [field: string]: FilterOption; };
    /** Executes a query with the current parameters. */
    useQuery(search: ParsedQs, props: RouteComponentProps<Params>): QueryResult<Item>;
    /** Actions to be displayed at the bottom of the screen. */
    footerActions?(props: RouteComponentProps<Params> & { auth: AuthUser }): Action[];
    /** When present, renders an icon per result. */
    renderIcon?(item: Item): null | JSX.Element;
    /** Renders the contents that appear in a card for each listing. */
    renderItem(item: Item, index: number): JSX.Element;
};

export function createOptionsFromEnum<T>(e: T) {
    return Object.keys(e).map(k => ({
        value: e[k as keyof T],
        label: startCase(k),
    }));
}

/**
 * Factory function for creating paginated views that support filtering, ordering, and sorting
 * along with optional support for actions that appear in the footer.
 */
export function createListPage<Item, Params = any>({
    title,
    icon,
    //defaultSearch,
    filterOptions = {},
    getUrl,
    useQuery,
    footerActions,
    renderIcon,
    renderItem,
}: CreateListPageOpts<Params, Item>) {

    const _defaultSearch = mapValues(filterOptions, x => x.default || []);

    return function (props: RouteComponentProps<Params>) {
        const history = props.history;
        const auth = useAuth();
        const [search, setSearch] = useSearchParams(history, {
            ..._defaultSearch,
            search: "",
            //sortBy: sortOptions[0].value,
            orderBy: "ASC",
            page: "1",
            perPage: "10",
        });

        const onItemClick = (item: Item) => history.push(getUrl(item, props));
        //const onFilterChange = (key: string, value: string | string[]) => setSearch({ [key]: value });
        const onSearchChange = useDebounce<JSXInternal.GenericEventHandler<HTMLInputElement>>((ev) => {
            const value = (ev.target as HTMLInputElement).value;
            setSearch({ search: value, page: "1" }, true);
        }, 150);


        const { fetching, error, items, pageInfo } = useQuery(search, props);

        let content = (<LoaderView />);

        if (!fetching) {
            if (error || !items || !pageInfo) {
                content = (
                    <div>
                        Something went wrong.
                    </div>
                );
            } else {
                content = (
                    <>
                        <Card.List
                            items={items}
                            onClick={onItemClick}
                            icon={renderIcon}
                            children={renderItem}
                        />
                        <Pagination
                            {...pageInfo}
                            search={search}
                            onSearch={setSearch}
                        />
                    </>
                );
            }
        }

        return (
            <>
                <PageHeader>
                    <PageHeader.Title icon={icon} title={title} />
                    <PageHeader.Row>
                        <div className={styles.search}>
                            <input
                                type="text"
                                placeholder="Search"
                                onChange={onSearchChange}
                            />
                            <Icons.Search />
                        </div>

                        {filterOptions && (
                            <FiltersDrawer
                                options={filterOptions}
                                search={search}
                                onChange={setSearch}
                                withOrderBy
                            />
                        )}
                    </PageHeader.Row>
                </PageHeader>
                <section className={styles.items}>
                    {content}
                </section>
                <FooterActions
                    disableAll={fetching}
                    actions={footerActions ? footerActions({ ...props, auth: auth.user! }) : []}
                />
            </>
        );
    };
}
