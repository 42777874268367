@import "../mixins";

.info,
.items {
    > h3,
    > h3 {
        margin: 50px 0 10px;

        &:first-child {
            margin-top: 0;
        }
    }

    > hr {
        margin: 50px 0
    }
}

.items {
    @extend %container;
    padding: 30px 0;
}
