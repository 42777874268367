import { h } from "preact";
import { Route, Redirect, Switch } from "react-router";
import { AppLayout, NavItem } from "../../components/AppLayout";
import { Building, Calendar, ManageClient, ManageUser, ServiceRequests, ServiceZones } from "../../components/Icons";
import { NotFoundView } from "../NotFound";
import { UserProfileView } from "../UserProfileView";
import { CreateEmployeeFormView } from "./CreateEmployeeFormView";
import { BuildingsListView } from "./BuildingsListView";
import { BuildingView } from "./BuildingView";
import { CustomersListView } from "./CustomersListView";
import { CustomerView } from "./CustomerView";
import { EmployeesListView } from "./EmployeesListView";
import { ServiceZonesListView } from "./ServiceZonesListView";
import { ServiceZoneView } from "./ServiceZoneView";
import { TicketsListView } from "./TicketsListView";
import { TicketView } from "./TicketView";
import { CreateCustomerFormView } from "./CreateCustomerFormView";
import { UpdateCustomerFormView } from "./UpdateCustomerFormView";
import { CreateServiceLocationFormView } from "./CreateServiceLocationFormView";
import { CreateBuildingFormView } from "./CreateBuildingFormView";
import { CreateTicketFormView } from "./CreateTicketFormView";
import { CreateServiceZoneFormView } from "./CreateServiceZoneFormView";
import { DeleteServiceZoneFormView } from "./DeleteServiceZoneFormView";
import { UpdateServiceZoneFormView } from "./UpdateServiceZoneFormView";
import { ScheduleEventsListView } from "./ScheduleEventListView";
import { CreateScheduleEventFormView } from "./CreateScheduleEventFormView";
import { UpdateBuildingFormView } from "./UpdateBuildingFormView";
import { ServiceLocationView } from "./ServiceLocationView";
import { UpdateServiceLocationFormView } from "./UpdateServiceLocationFormView";
import { UpdateScheduleEventFormView } from "./UpdateScheduleEventFormView";
import { CommentsView } from "../CommentsView";
import { InviteCustomerUserFormView } from "./InviteCustomerUserFormView";
import { UpdateUserProfileFormView } from "../UpdateUserProfileFormView";
import { ChangePasswordFormView } from "../ChangePasswordFormView";
import { GetCurrentUserQuery, UserRole } from "../../graphql/generated";
import { ReportEntrapmentFormView } from "./ReportEntrapmentFormView";
import { CloseTicketFormView } from "./CloseTicketFormView";
import { AssignTicketFormView } from "./AssignTicketFormView";
import { PauseTicketFormView } from "./PauseTicketFormView";
import { TechnicianEntryView } from "./TechnicianEntryView";
import { useEffect } from "preact/hooks";
import { UpdateBuildingSubscribersView } from "./UpdateBuildingSubscribersView";

type Props = {
    user: NonNullable<GetCurrentUserQuery["me"]>;
};

const TECH_NAV_ITEMS: NavItem[] = [
    {
        href: "/",
        label: "My Service Requests",
        icon: <ServiceRequests />,
    },
    {
        href: "/tickets?sortBy=CREATED_AT&orderBy=DESC",
        label: "All Service Requests",
        icon: <ServiceRequests />,
    },
];

const DISPATCHER_NAV_ITEMS: NavItem[] = [
    {
        href: "/schedule?state[0]=FUTURE&state[1]=PRESENT",
        label: "Schedule",
        icon: <Calendar />,
    },
    {
        href: "/employees",
        label: "Employees",
        icon: <ManageUser />,
    },
    {
        href: "/customers",
        label: "Customer Accounts",
        icon: <ManageClient />,
    },
    {
        href: "/buildings",
        label: "Buildings",
        icon: <Building />,
    },
    {
        href: "/tickets?sortBy=CREATED_AT&orderBy=DESC",
        label: "Service Requests",
        icon: <ServiceRequests />,
    },
    {
        href: "/serviceZones",
        label: "Service Zones",
        icon: <ServiceZones />,
    },
];

/**
 * User routes when logged in as an employee (NOT A CUSTOMER). Though these routes are all made available, it's
 * possible that many of them may still render 404s or report errors (when performing mutations) if the user doesn't
 * have the required permissions to access certain data.
 */
export function EmployeeRoutes({ user }: Props) {
    const isTech = user.role === UserRole.Technician;

    useEffect(() => {
        window.Intercom("update", {
            company: {
                name: "Eltec",
            },
        });
    }, []);

    return (
        <AppLayout navItems={isTech ? TECH_NAV_ITEMS : DISPATCHER_NAV_ITEMS}>
            <Switch>
                <Route path="/:type/:id/comments" exact component={CommentsView} />
                <Route path="/schedule/create" exact component={CreateScheduleEventFormView} />
                <Route path="/schedule/:id" exact component={UpdateScheduleEventFormView} />
                <Route path="/schedule" exact component={ScheduleEventsListView} />
                <Route path="/customers/:id/users/invite" exact component={InviteCustomerUserFormView} />
                <Route path="/customers/:id/buildings/create" exact component={CreateBuildingFormView} />
                <Route path="/customers/:id/edit" exact component={UpdateCustomerFormView} />
                <Route path="/customers/create" exact component={CreateCustomerFormView} />
                <Route path="/customers/:id" exact component={CustomerView} />
                <Route path="/customers" exact component={CustomersListView} />
                <Route path="/changePassword" exact component={ChangePasswordFormView} />
                <Route path="/users/:id/edit" exact component={UpdateUserProfileFormView} />
                <Route path="/users/:id" exact component={UserProfileView} />
                <Route path="/employees/create" exact component={CreateEmployeeFormView} />
                <Route path="/employees" exact component={EmployeesListView} />
                <Route path="/buildings/create" exact component={CreateBuildingFormView} />
                <Route path="/buildings/:buildingId/serviceLocations/:id/edit" exact component={UpdateServiceLocationFormView} />
                <Route path="/buildings/:buildingId/serviceLocations/create" exact component={CreateServiceLocationFormView} />
                <Route path="/buildings/:buildingId/serviceLocations/:id" exact component={ServiceLocationView} />
                <Route path="/buildings/:id/edit/subscribers" exact component={UpdateBuildingSubscribersView} />
                <Route path="/buildings/:id/edit" exact component={UpdateBuildingFormView} />
                <Route path="/buildings/:id" exact component={BuildingView} />
                <Route path="/buildings" exact component={BuildingsListView} />
                <Route path="/tickets/create/entrapment" exact component={ReportEntrapmentFormView} />
                <Route path="/tickets/create" exact component={CreateTicketFormView} />
                <Route path="/tickets/:id/close" exact component={CloseTicketFormView} />
                <Route path="/tickets/:id/assign" exact component={AssignTicketFormView} />
                <Route path="/tickets/:id/pause" exact component={PauseTicketFormView} />
                <Route path="/tickets/:id" exact component={TicketView} />
                <Route path="/tickets" exact component={TicketsListView} />
                <Route path="/serviceZones/:id/delete" exact component={DeleteServiceZoneFormView} />
                <Route path="/serviceZones/:id/edit" exact component={UpdateServiceZoneFormView} />
                <Route path="/serviceZones/create" exact component={CreateServiceZoneFormView} />
                <Route path="/serviceZones/:id" exact component={ServiceZoneView} />
                <Route path="/serviceZones" exact component={ServiceZonesListView} />
                {isTech
                    ? (<Route path="/" exact component={TechnicianEntryView} />)
                    : (<Redirect from="/" exact to="/tickets" />)}
                <NotFoundView />
            </Switch>
        </AppLayout>
    );
}