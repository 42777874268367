import { h, Fragment } from "preact";
import { Drawer } from "./Drawer";
import { Button } from "./controls/Button";
import * as styles from "./ConfirmationDrawer.module.scss";

export type Props = {
    /** The label that will be rendered in the heading. */
    label: string;
    /** The icon used in the heading. */
    icon?: JSX.Element;
    /** The message that will be displayed. */
    message: string | JSX.Element;
    /** When true, the drawer will be adorned with red highlights. */
    danger?: boolean;
    /** The label used for the confirmation button. _Defaults to "Confirm"._ */
    confirmLabel?: string;
    /** Should the drawer be open and showin? */
    isOpen: boolean;
    /** Event that is called when the drawer should be closed. */
    onClose(): void;
    /** Event that is called when the action is confirmed. */
    onConfirm(): void | Promise<void>;
    /** When present, disables actions to allow for an operation to finish. */
    loading?: boolean;
};

/**
 * A drawer button that has its own "confirmation drawer" to perform a dangerous action.
 */
export function ConfirmationDrawer({
    label,
    icon,
    message,
    danger,
    loading = false,
    confirmLabel = "Confirm",
    isOpen,
    onConfirm,
    onClose,
}: Props) {
    const _onConfirm = async () => {
        onClose();
        await onConfirm();
    };

    return (
        <>
            <Drawer
                scrollable open={isOpen}
                onClose={loading ? undefined : onClose}
            >
                <Drawer.Heading
                    icon={icon}
                    label={label}
                />
                <Drawer.Content>
                    {message}
                </Drawer.Content>
                <div className={styles.confirmActions}>
                    <Button
                        label={confirmLabel}
                        dangerous={danger}
                        onClick={_onConfirm}
                        loading={loading}
                    />
                    <Button label="Cancel"
                        onClick={onClose}
                        disabled={loading}
                        outlined
                    />
                </div>
            </Drawer>

        </>
    );
}