import { h } from "preact";
import format from "date-fns/format";
// import * as styles from "./DateTime.module.scss";

type Props = {
    /** The date/time to render. */
    when: string | Date;
    /** The formatting to use, defaults to a format like "Jan 2nd, 1970". */
    format?: string;
    /** Optional class(es) to apply to the <time> tag. */
    className?: string;
};

/**
 * Renders a date and/or time relative to the user's timezone.
 */
export function DateTime({ when, format: f = "MM/dd/yyyy h:mmaaa", className }: Props) {
    const whenString = typeof when === "string" ? when : when.toString();

    return (
        <time
            className={className}
            dateTime={whenString}
        >
            {format(new Date(when), f)}
        </time>
    );
}