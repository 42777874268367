import { h, Fragment } from "preact";
import { useMemo } from "preact/hooks";
import { Avatar } from "../../components/Avatar";
import { SelectField } from "../../components/controls/SelectField";
import { createFormPage } from "../../factories/createFormPage";
import { NotificationPreference, GetBuildingSubscribersQuery, useGetBuildingSubscribersQuery, SetBuildingNotificationPreferencesMutationVariables, SetBuildingNotificationPreferencesMutation, useSetBuildingNotificationPreferencesMutation } from "../../graphql/generated";
import * as styles from "./UpdateBuildingSubscribersView.module.scss";

type Fields = SetBuildingNotificationPreferencesMutationVariables;
type RouteParams = { id: string };
type MutRes = SetBuildingNotificationPreferencesMutation;
type MutVar = SetBuildingNotificationPreferencesMutationVariables;
type Query = GetBuildingSubscribersQuery;

export const NOTIFY_OPTIONS = {
    [NotificationPreference.None]: "No Notifications",
    [NotificationPreference.PhoneFirst]: "Prefers SMS",
    [NotificationPreference.EmailOnly]: "Email Only",
    [NotificationPreference.All]: "Email and SMS",
};

/**
 * Renders the form view for updating a building.
 */
export const UpdateBuildingSubscribersView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Edit Building Subscribers",
    useDefaultValues: ({ data }) => {
        const building = data!.building!;

        return {
            input: building.subscribers.map(s => ({
                buildingId: building.id,
                userId: s.user.id,
                preference: s.preference,
            })),
        };
    },
    useQuery: ({ id }) => useGetBuildingSubscribersQuery({ variables: { id } }),
    toVars: fields => fields,
    useMutation: useSetBuildingNotificationPreferencesMutation,
    onSuccess: (res, { history, match }) => history.replace(`/buildings/${match.params.id}`),
    onSuccessMessage: res => `Successfully updated user notification subscriptions.`,
    render({ data, register, getValues, setValue }) {
        const { id, subscribers } = data!.building!;

        const setAllTo = useMemo(() => {
            const setAll = (preference: NotificationPreference) => {
                setValue("input", subscribers.map(s => ({
                    buildingId: id,
                    userId: s.user.id,
                    preference,
                })));
            };

            return {
                none: () => setAll(NotificationPreference.None),
                phone: () => setAll(NotificationPreference.PhoneFirst),
                email: () => setAll(NotificationPreference.EmailOnly),
                all: () => setAll(NotificationPreference.All),
            };
        }, [getValues, setValue]);

        return (
            <>

                <p className={styles.note}>
                    The selected users will be notified of updates related to service requests
                    in <em>addition</em> to the user reporting the service request. The reporting user
                    will currently always be notified, regardless of preferences.
                </p>

                <div className={styles.setAll}>
                    Set All To
                    <a onClick={setAllTo.none}>None</a>
                    <a onClick={setAllTo.phone}>SMS</a>
                    <a onClick={setAllTo.email}>Email</a>
                    <a onClick={setAllTo.all}>Both</a>
                </div>

                <div className={styles.subscribers}>
                    {subscribers.map(({ user }, i) => (
                        <div key={user.id} className={styles.subscriber}>
                            <Avatar name={user.name} src={user.avatarUrl} />
                            <div className={styles.subscriberInfo}>
                                <h4>{user.name}</h4>
                                <div>{user.email}</div>
                            </div>
                            <div className={styles.select}>
                                <SelectField
                                    register={register}
                                    id={"sub_" + user.id}
                                    name={`input.${i}.preference`}
                                    options={NOTIFY_OPTIONS}
                                    controlOnly
                                />
                            </div>
                        </div>
                    ))}
                </div>

            </>
        );
    },
});