import { createClient } from "@urql/preact";
import { TOKEN_KEY } from "../constants";

export const apiClient = createClient({
    url: "/graphql",
    requestPolicy: "network-only", // TODO: re-enable caching once necessary
    fetchOptions() {
        const token = localStorage.getItem(TOKEN_KEY);

        return {
            headers: {
                "Authorization": token ? `Bearer ${token}` : "",
            },
        };
    },
});