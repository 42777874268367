import { h } from "preact";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { memo } from "preact/compat";
import * as styles from "./Avatar.module.scss";

type Props = {
    /** The user's avatar image URL. */
    src?: string;
    /** Name of the user that the avatar represents. */
    name: string;
    /** Converts the avatar to a linked avatar. */
    href?: string;
    /** When present, replaces the name as the title for the avatar. */
    title?: string;
    /** When present, adds a click action to the button. If provided, overrides "href" linking. */
    onClick?(): void;
    /** A width to apply to the avatar. */
    width?: number | string;
    /** Custom font-size to apply to the avatar. Will affect width if width is not explicitly set. */
    size?: number | string;
};

/**
 * Renders user avatars if available. Otherwise, renders the name of the user that the avatar
 * represents.
 */
export const Avatar = memo(({
    name,
    title,
    src,
    href,
    width,
    size,
    onClick,
}: Props) => {
    const history = useHistory();
    const classes = cx(styles.avatar, {
        [styles.isClickable]: onClick,
    });

    const shortName = initials(name);
    const style = { ...color(name), width, fontSize: size };

    if (!onClick && href) {
        onClick = () => history.push(href);
    }

    const avatar = (
        <div
            aria-label={title ?? name}
            className={classes}
            style={style}
            title={title ?? name}
            onClick={onClick}
        >
            {src ? (
                <img src={src} alt={shortName} />
            ) : (
                <span>{shortName}</span>
            )}
        </div>
    );

    return avatar;
});

/**
 * Attempts to generate initials from a user's name. This definitely could be reworked into something
 * better, but this is good enough for now.
 */
function initials(str: string) {
    return str.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
}

/**
 * Generates a numeric sum of all the character's codes for the given string.
 */
function sumChars(str: string) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
    }

    return sum;
}

/**
 * Generates an HSL color pair using the given name as a deterministic seed value.
 */
function color(name: string) {
    const sum = sumChars(name);

    const hue = Math.round(((sum % 999) / 999) * 360);
    const sat = 30 + Math.round(((sum % 500) / 500) * 60);
    const val = 30 + Math.round(((sum % 300) / 300) * 60);

    const val2 = val > 50 ? 10 : 90;

    const backgroundColor = `hsl(${hue}, ${sat}%, ${val}%)`;
    const color = `hsl(${hue}, 98%, ${val2}%)`;

    return { backgroundColor, color };
}