import { h, Fragment } from "preact";
import { FormProvider, useForm } from "react-hook-form";
import { RouteComponentProps, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "../../components/controls/Button";
import { TextField } from "../../components/controls/TextField";
import { useToast } from "../../contexts/Toast";
import { useIsValidTokenQuery, useResetPasswordMutation } from "../../graphql/generated";
import { ErrorView } from "../ErrorView";
import { LoaderView } from "../Loader";
import { NotFoundView } from "../NotFound";
import * as styles from "./Login.module.scss";

type Props = RouteComponentProps<{ token: string }>;

const PASSWORD_PATTERN = /[A-Za-z0-9\_\!\$\&]+/;

/**
 * Renders the password reset view. This is the view users will be sent to
 * once they click the "Reset Password" link provided by email upon submitting
 * a "Forgot Password" request.
 */
export function PasswordResetView({ match }: Props) {
    const toast = useToast();
    const history = useHistory();
    const token = match.params.token;

    const [{ fetching, error, data }, refetch] = useIsValidTokenQuery({ variables: { token } });
    const [result, mutate] = useResetPasswordMutation();

    // const isInvite = token.startsWith("invite");

    if (fetching) {
        return (
            <>
                <div className={styles.spacer} />
                <LoaderView />
            </>
        );
    }

    if (error || !data) {
        return (
            <>
                <div className={styles.spacer} />
                <ErrorView
                    message="Something went wrong while trying to load this page."
                    onRetry={refetch}
                />
            </>
        );
    }

    if (!data.isValidToken) {
        return (
            <>
                <div className={styles.spacer} />
                <ErrorView
                    message={<>
                        This password reset link is either invalid or has expired. If you are trying to reset your password,
                        you can <Link to="/forgot-password">click here</Link> to request a new link and try again.
                    </>
                    }
                />
            </>
        );
    }

    const form = useForm({
        mode: "onChange",
        defaultValues: {
            password: "",
            confirmPassword: "",
        },
    });
    const { register, handleSubmit, getValues, formState: { isValid } } = form;

    const onSubmit = handleSubmit(async ({ password, confirmPassword }) => {
        // TODO: find a way to move this validation to the form itself!
        if (password !== confirmPassword) {
            toast({ type: "error", message: "You must confirm your new password. Provided passwords don't match." });
            return;
        }

        const { error } = await mutate({ token, newPassword: password });

        if (error) {
            toast({ type: "error", message: error.message });
            return;
        }

        toast({
            type: "success",
            message: "Your password was successfully reset! You may now log in.",
        });

        history.push("/login");
    });

    if (!token) {
        return (
            <NotFoundView />
        );
    }

    return (
        <FormProvider {...form}>
            <form className={styles.form} onSubmit={onSubmit}>
                <p className={styles.instructions}>
                    Please enter the desired password for your account.
                </p>

                <TextField
                    register={register}
                    label="Password"
                    name="password"
                    type="password"
                    minLength={8}
                    maxLength={32}
                    pattern={PASSWORD_PATTERN}
                    required
                />
                <TextField
                    register={register}
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    validate={v => v !== getValues().password ? "Passwords must match." : true}
                    required
                />

                <p className={styles.smallInstructions}>
                    Passwords must be between 8 and 32 characters and contain
                    at least 1 lowercase letter, 1 uppercase letter, and a number. Underscores (<code>_</code>), exclamation
                    marks (<code>!</code>), dollar signs (<code>$</code>), and ampersands (<code>&amp;</code>) are allowed.
                </p>

                <Button
                    type="submit"
                    disabled={!isValid}
                    loading={result.fetching}
                    label="Change Password"
                />
            </form>
        </FormProvider>
    );
}