@import "../../mixins";

.welcome,
.contents {
    @extend %container;
}

.welcome {
    padding-top: 30px;
    padding-bottom: 50px;

    > h1 {
        font-size: 32px;
        font-weight: bold;
    }
}

.contents {
    padding-bottom: 100px;

    > h3 {
        margin: 50px 0 10px;
    }

    > hr {
        margin: 50px 0;
    }
}

.pastTicketsLink {
    margin-top: 30px;
}
