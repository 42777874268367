import { h, Fragment } from "preact";
import { Link } from "react-router-dom";
import { DateTime } from "../../components/DateTime";
import { Comments } from "../../components/Icons";
import { StatusNodes } from "../../components/indicators/StatusNodes";
import { TicketComment } from "../../components/TicketComment";
import { createOverviewPage } from "../../factories/createOverviewPage";
import { GetCustomerTicketQuery, TicketPriority, TicketStatus, useGetCustomerTicketQuery } from "../../graphql/generated";

const STATUSES = [
    TicketStatus.Unassigned,
    TicketStatus.Paused,
    TicketStatus.Assigned,
    TicketStatus.EnRoute,
    TicketStatus.OnSite,
    TicketStatus.Closed,
];
const STATUS_ASSIGNED = STATUSES.indexOf(TicketStatus.Assigned);
const STATUS_ENROUTE = STATUSES.indexOf(TicketStatus.EnRoute);
const STATUS_ONSITE = STATUSES.indexOf(TicketStatus.OnSite);
const STATUS_CLOSED = STATUSES.indexOf(TicketStatus.Closed);


/**
 * Renders a single ticket and displays the ticket"s information and relationships.
 */
export const CustomerTicketView = createOverviewPage<{ id: string }, GetCustomerTicketQuery>({
    useQuery: ({ id }) => useGetCustomerTicketQuery({ variables: { id } }),
    showNotFound: data => !data || !data.ticket,
    title: () => "Service Request",
    contents({ data }) {
        const { building, serviceLocation, comments, ...ticket } = data.ticket!;

        const statusIndex = STATUSES.indexOf(ticket.status);
        const isPaused = ticket.status === TicketStatus.Paused;
        const isAssigned = statusIndex >= STATUS_ASSIGNED;
        const isEnroute = statusIndex >= STATUS_ENROUTE;
        const isOnSite = statusIndex >= STATUS_ONSITE;
        const isClosed = statusIndex >= STATUS_CLOSED;

        return (
            <>
                <h3>Service Location</h3>
                <p>
                    {serviceLocation.name}<br />
                    {building.name}
                </p>

                <h3>Priority Level</h3>
                <p>{renderPriorityLevel(ticket.priority)}</p>

                <h3>Comments</h3>
                <TicketComment comment={comments.length > 0 ? comments[0] : null} ticketId={ticket.id} />

                <h3>Request Details</h3>
                <p>{ticket.notes}</p>

                <h3>Overtime Allowed</h3>
                <p>{ticket.overtimeAllowed ? "Yes" : "No"}</p>

                <hr />

                <StatusNodes>
                    <StatusNodes.Node label="Request Received" completed>
                        <DateTime when={ticket.createdAt} />
                    </StatusNodes.Node>

                    {isPaused && (
                        <StatusNodes.Node label="Paused">
                            This service is currently paused and pending further action from our team.
                        </StatusNodes.Node>
                    )}

                    <StatusNodes.Node
                        label={isAssigned ? "Assigned Technician" : "Awaiting Technician Assignment"}
                        completed={isAssigned}
                    />

                    <StatusNodes.Node
                        label={isEnroute ? "Technician En Route" : "Awaiting Technician Dispatch"}
                        completed={isEnroute}
                    />

                    <StatusNodes.Node
                        label={isOnSite ? "Technician On-Site" : "Awaiting Technician Arrival"}
                        completed={isOnSite}
                    />

                    <StatusNodes.Node
                        label={isClosed ? "Resolved" : "Resolution"}
                        completed={isClosed}
                    >
                        {(isClosed && ticket.closedAt) && (
                            <DateTime when={ticket.closedAt!} />
                        )}
                    </StatusNodes.Node>

                </StatusNodes>
            </>
        );
    },
});


function renderPriorityLevel(level: TicketPriority) {
    switch (level) {
        case TicketPriority.Entrapment:
            return "Entrapment (within 1 hours)";
        case TicketPriority.High:
            return "High (within 4 hours)";
        case TicketPriority.Standard:
            return "Standard (within 24 hours)";
        case TicketPriority.Low:
            return "Low (Next Service Visit)";
        default:
            return "N/A";
    }
}