import { h, Fragment } from "preact";
import { Link } from "react-router-dom";
import { Card } from "../../components/CardList";
import { DeleteEntityButton } from "../../components/DeleteEntityButton";
import { Drawer } from "../../components/Drawer";
import { Building, Comments, Edit, Trash } from "../../components/Icons";
import { History } from "history";
import { TicketCardList } from "../../components/TicketCardList";
import { createOverviewPage } from "../../factories/createOverviewPage";
import { GetCustomerQuery, GetServiceZoneQuery, SearchUsersDocument, SearchUsersQuery, SearchUsersQueryVariables, useAddTechToServiceZoneMutation, useDeleteServiceZoneMutation, useGetServiceZoneQuery, useRemoveTechFromServiceZoneMutation, UserRole } from "../../graphql/generated";
import { OptionLookupField } from "../../components/controls/OptionLookupField";
import { useCallback, useMemo, useState } from "preact/hooks";
import { useClient } from "@urql/preact";
import { useToast } from "../../contexts/Toast";
import { Spinner } from "../../components/indicators/Spinner";
import { memo } from "preact/compat";
import { Avatar } from "../../components/Avatar";
// import * as styles from "./ServiceZoneView.module.scss";

/**
 * Renders a single service zone and displays the service zone's information and relationships.
 */
export const ServiceZoneView = createOverviewPage<{ id: string }, GetServiceZoneQuery>({
    useQuery: ({ id }) => useGetServiceZoneQuery({ variables: { id } }),
    showNotFound: data => !data || !data.serviceZone,
    title: ({ serviceZone }) => serviceZone!.name,
    subTitle: () => "Service Zone",
    info({ data }) {
        const serviceZone = data.serviceZone!;
        const { supervisor } = serviceZone;

        return (
            <>
                <h3>Zone Name</h3>
                <p>{serviceZone.name}</p>

                <h3>Supervisor</h3>
                <p>
                    <Link to={`/users/${supervisor.id}`}>
                        <strong>{supervisor.name}</strong><br />
                        <span>{supervisor.email}</span>
                    </Link>
                </p>
            </>
        );
    },
    actions({ data, route, auth }) {
        if (auth.role !== UserRole.Dispatch) {
            return null;
        }

        const serviceZone = data.serviceZone!;

        const deleteAction = serviceZone.buildings.length > 0
            ? (
                <Drawer.Button
                    icon={<Trash />}
                    label="Remove Service Zone"
                    href={`/serviceZones/${serviceZone.id}/delete`}
                />
            )
            : (
                <DeleteEntityButton
                    id={serviceZone.id}
                    type="Service Zone"
                    useMutation={useDeleteServiceZoneMutation}
                    onSuccess={() => route.history.push("/serviceZones")}
                />
            );

        return (
            <>
                <Drawer.Button
                    icon={<Comments />}
                    label="View Comments"
                    href={`/serviceZones/${serviceZone.id}/comments`}
                />
                <Drawer.Button
                    icon={<Edit />}
                    label="Edit Service Zone"
                    href={`/serviceZones/${serviceZone.id}/edit`}
                />
                {deleteAction}
            </>
        );
    },
    contents({ data, route, auth }) {
        const { id, tickets, technicians, buildings } = data.serviceZone!;
        const history = route.history;

        return (
            <>

                <h3>Active Service Requests</h3>
                <TicketCardList
                    serviceRequest
                    tickets={tickets}
                    history={history}
                />

                {auth.role === UserRole.Dispatch
                    ? (
                        <ManageTechs
                            initialTechs={technicians}
                            serviceZoneId={id}
                            history={history}
                        />
                    ) : (
                        <TechList
                            techs={technicians}
                            history={history}
                        />
                    )}

                <h3>Assigned Buildings</h3>
                <Card.List
                    icon={() => <Building />}
                    items={buildings}
                    onClick={b => history.push(`/buildings/${b.id}`)}
                >
                    {building => (
                        <h3>
                            {building.name}
                        </h3>
                    )}
                </Card.List>
            </>
        );
    },
});


type TechListProps = {
    /** List of techs to render. */
    techs: TechSubset[];
    /** History object used to route the tech to a tech's profile page. */
    history: History;
};

function TechList({ techs, history }: TechListProps) {
    if (techs.length === 0) {
        return null;
    }

    return (
        <>
            <h3>Assigned Technicians</h3>
            <Card.List
                icon={user => <Avatar name={user.name} src={user.avatarUrl} />}
                onClick={(user) => history.push(`/users/${user.id}`)}
                items={techs}
            >
                {(user) => (
                    <div>
                        <h3>{user.name}</h3>
                        <span>{user.email}</span>
                    </div>
                )}
            </Card.List>
        </>
    );
}


type TechSubset = NonNullable<GetCustomerQuery["customer"]>["users"][0]["user"];

type ManageTechsProps = {
    /** Data set from the initial query. */
    initialTechs: TechSubset[];
    /** The ID of the service zone that we're modifying. */
    serviceZoneId: string;
    /** History object used to route the user to a user's profile page. */
    history: History;
};

const ManageTechs = memo(({ initialTechs, serviceZoneId, history }: ManageTechsProps) => {
    const toast = useToast();
    const [users, setUsers] = useState(initialTechs);
    const [removingTechs, setRemovingTechs] = useState<string[]>([]);
    const [{ fetching }, addTechToServiceZone] = useAddTechToServiceZoneMutation();
    const [, removeTechFromServiceZone] = useRemoveTechFromServiceZoneMutation();

    const addTech = async (user: TechSubset) => {
        const { error } = await addTechToServiceZone({ technicianId: user.id, serviceZoneId });

        if (error) {
            toast({ type: "error", message: error.message });
            return;
        }

        toast({
            type: "success",
            message: `Successfully added "${user.name}" to this service zone.`,
        });

        setUsers(users.concat(user));
    };

    const removeTech = async ({ id: technicianId, name }: TechSubset) => {
        if (removingTechs.includes(technicianId)) {
            console.warn("Attempting to remove a tech that is already being removed.");
            return;
        }

        setRemovingTechs(removingTechs.concat(technicianId));

        const { error } = await removeTechFromServiceZone({ technicianId, serviceZoneId });

        if (error) {
            toast({ type: "error", message: error.message });
        } else {
            setUsers(users.filter(u => u.id !== technicianId));

            toast({
                type: "success",
                message: `Successfully removed "${name}" from this service zone.`,
            });
        }

        setRemovingTechs(removingTechs.filter(d => d !== technicianId));
    };

    return (
        <>
            <AddExistingUserField
                loading={fetching}
                users={users}
                onSelected={addTech}
            />
            <h3>Assigned Technicians</h3>
            <Card.List
                icon={user => <Avatar name={user.name} src={user.avatarUrl} />}
                items={users}
            >
                {(user) => (
                    <>
                        <Card.Cell>
                            <a onClick={() => history.push(`/users/${user.id}`)}>
                                <strong>{user.name}</strong><br />
                                <span>{user.email}</span>
                            </a>
                        </Card.Cell>
                        <Card.Cell>
                            {removingTechs.includes(user.id) ? (
                                <div>
                                    <Spinner />
                                </div>
                            ) : (
                                <a onClick={() => removeTech(user)}>
                                    <Trash />
                                </a>
                            )}
                        </Card.Cell>
                    </>
                )}
            </Card.List>
        </>
    );
});

type AddExistingUserFieldProps = {
    /** Disables the field when true (if we're performing an operation). */
    loading: boolean;
    /** Existing users that are already attached to this service zone. */
    users: TechSubset[];
    /** Event that is called when a user is selected. */
    onSelected(user: TechSubset): void;
};

function AddExistingUserField({ users, loading, onSelected }: AddExistingUserFieldProps) {
    const urqlClient = useClient();
    const [rerenderKey, setRerenderKey] = useState(0);
    const [results, setResults] = useState<TechSubset[]>([]);

    const userIds = useMemo(() => users.map(u => u.id), [users]);

    const onSearch = useCallback(async (search: string) => {
        if (search) {
            const { error, data } = await urqlClient.query<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, {
                search,
                exclude: userIds,
                role: UserRole.Technician,
            }).toPromise();

            setResults(!error && data ? data.users.nodes : []);
        } else {
            setResults([]);
        }
    }, [userIds, setResults]);

    const _onSelected = useCallback((user: TechSubset) => {
        setResults([]);
        onSelected(user);
        setRerenderKey(rerenderKey + 1);
    }, [rerenderKey, setRerenderKey]);

    return (
        <>
            <h3>
                Add Technician
            </h3>
            <OptionLookupField
                key={rerenderKey}
                disabled={loading}
                options={results}
                placeholder="Start typing to search technicians"
                onSearch={onSearch}
                onSelected={_onSelected}
            >
                {user => (
                    <>
                        <Avatar name={user.name} src={user.avatarUrl} size={8} />
                        <span style={{ paddingLeft: 10 }}>{user.name}</span>
                        <span style={{ paddingLeft: 20, opacity: 0.6 }}>{user.email}</span>
                    </>
                )}
            </OptionLookupField>
        </>
    );
}