import { ContractCode, TicketPriority } from "./graphql/generated";

/**
 * The localStorage key that the JSON web token used for authentication will be stored under.
 */
export const TOKEN_KEY = "equick:token";

/**
 * Values available for selection when choosing contract codes.
 */
export const CONTRACT_CODE_OPTS = {
    [ContractCode.Fm8]: "FM8",
    [ContractCode.Fm24]: "FM24",
    [ContractCode.Fmtd]: "FMTD",
    [ContractCode.Le]: "LE",
    [ContractCode.Sma]: "SMA",
    [ContractCode.Modernization]: "Modernization",
    [ContractCode.NonContract]: "Non-Contract",
    [ContractCode.Route]: "Route",
    [ContractCode.Warranty]: "Warranty"
};

/**
 * Priority level options available when creating a ticket.
 */
export const CREATE_TICKET_PRIORITY_OPTIONS = [
    {
        label: "Hold for Normal Weekday Hours",
        value: TicketPriority.Low,
        description: "Does not include service on weekends.",
    },
    {
        label: "Standard",
        value: TicketPriority.Standard,
        description: "Service within 24 hours. Times will vary.",
    },
    {
        label: "High",
        value: TicketPriority.High,
        description: "Service usually within 4 hours or same day.",
    },
    {
        label: "Specific Contract Terms Apply",
        value: TicketPriority.Contract,
    }
];