import { useMemo } from "preact/hooks";
import { History } from "history";
import { parse, ParsedQs, stringify } from "qs";

export type SetSearchParams = (params: ParsedQs, replace?: boolean) => void;

/**
 * React hook for parsing and setting query string values using an object literal. The default params
 * object is used for determining allowed param values + types and is necessary for the hook to handle
 * desired conversions automatically based on runtime type.
 */
export function useSearchParams(history: History, defaultParams: ParsedQs = {}): [ParsedQs, SetSearchParams] {
    return useMemo(() => {
        const parsed = parse(history.location.search.slice(1));
        const query = { ...defaultParams, ...parsed };

        const setQuery = (params: ParsedQs, replace: boolean = false) => {
            const search = stringify({ ...query, ...params });

            const setRoute = replace ? history.replace : history.push;

            setRoute({
                pathname: history.location.pathname,
                search: search,
            });
        };

        return [query, setQuery];
    }, [history.location.search]);
}