import { h, Fragment } from "preact";
import { ParsedQs } from "qs";
import { useDisclosure } from "../hooks/useDisclosure";
import { Drawer } from "./Drawer";
import { OrderBy, SortBy } from "./Icons";
import { memo } from "preact/compat";
import { PageHeader } from "./PageHeader";

export type Props = {
    /** Filter and sort options that can selected via the filters drawer. */
    options: { [field: string]: FilterOption; };
    /** The current search parameters. */
    search: ParsedQs;
    /** Called when a change to the current filter/sort selections changes. */
    onChange(filters: ParsedQs, replace?: boolean): void;
    /** When true, orderBy options will be appended automatically to the drawer.*/
    withOrderBy?: boolean;
};

export type FilterOption = {
    /** The label for the filter/sort group. */
    label: string;
    /** Optional icon that will appear in the header of a filter group. */
    icon?: JSX.Element;
    /** The options that are available for this filter group. */
    options: {
        /** The label for the option (that will be displayed to users). */
        label: string;
        /** The value used when communicating with the server. */
        value: string;
    }[];
} & (
        | {
            /** The default value for this field group. */
            default: string;
            /** Marks the filter group as a multi-select. */
            multiselect?: false;
        }
        | {
            /** The default value for this field group. */
            default?: never;
            /** Marks the filter group as a multi-select. */
            multiselect: true;
        }
    );

/**
 * This component assists in the creation of a drawer that is able to interact with the current
 * routing search parameters to enable advanced filtering, sorting, and ordering functionality.
 */
export const FiltersDrawer = memo(({ options, search, onChange, withOrderBy }: Props) => {
    const drawerState = useDisclosure();
    let filters: JSX.Element[] = [];

    const onOrderByChange = (orderBy: "ASC" | "DESC") => onChange({ orderBy }, true);

    for (const filterKey in options) {
        const filter = options[filterKey];
        const GroupComp = filter.multiselect ? MultiSelectDrawerGroup : SelectDrawerGroup;

        filters.push(<GroupComp
            icon={filter.icon}
            label={filter.label}
            value={search[filterKey as keyof typeof search] as any}
            options={filter.options}
            onChange={v => onChange({ [filterKey]: v }, true)}
        />);
    }

    return (
        <>
            <PageHeader.IconAction
                label="Filters"
                icon={<SortBy />}
                onClick={drawerState.onOpen}
            />
            <Drawer
                scrollable
                open={drawerState.isOpen}
                onClose={drawerState.onClose}
            >
                {filters}

                {/*
                    <SelectDrawerGroup
                        label="Sort By"
                        icon={<Icons.SortBy />}
                        value={search.sortBy}
                        options={sortOptions}
                        onChange={onSortOrderChange}
                    /> */}

                {withOrderBy && (
                    <SelectDrawerGroup
                        label="Order By"
                        icon={<OrderBy />}
                        value={search.orderBy}
                        options={[
                            { value: "ASC", label: "Ascending" },
                            { value: "DESC", label: "Descending" },
                        ]}
                        onChange={onOrderByChange}
                    />
                )}
            </Drawer>
        </>
    );
});


type SelectDrawerGroupProps = {
    label: string;
    icon?: JSX.Element;
    value: ParsedQs[""];
    onChange(value: string): void;
    options: {
        label: string;
        value: string;
    }[];
};

const SelectDrawerGroup = memo(({
    label,
    icon,
    value,
    onChange,
    options,
}: SelectDrawerGroupProps) => {
    if (typeof value !== "string") value = "";

    return (
        <>
            <Drawer.Heading
                label={label}
                icon={icon}
            />
            {options.map(o => {
                return (
                    <Drawer.ToggleButton
                        key={o.value}
                        label={o.label}
                        isActive={value === o.value}
                        onClick={() => onChange(o.value)}
                    />
                );
            })}
        </>
    );
});

type MultiSelectDrawerGroup = {
    label: string;
    icon?: JSX.Element;
    value: ParsedQs[""];
    onChange(value: string[]): void;
    options: {
        label: string;
        value: string;
    }[];
};

const MultiSelectDrawerGroup = memo(({
    label,
    icon,
    value,
    onChange,
    options,
}: MultiSelectDrawerGroup) => {
    let stringValues: string[] = [];

    if (Array.isArray(value)) {
        for (const v of value) {
            if (typeof v === "string") {
                stringValues.push(v);
            }
        }
    }

    return (
        <>
            <Drawer.Heading
                label={label}
                icon={icon}
            />
            {options.map(o => {
                const selected = stringValues.includes(o.value);

                return (
                    <Drawer.ToggleButton
                        key={o.value}
                        label={o.label}
                        isActive={selected}
                        onClick={() => onChange(selected
                            ? stringValues.filter(x => x !== o.value)
                            : stringValues.concat(o.value)
                        )}
                    />
                );
            })}
        </>
    );
});