import { ComponentType, Fragment, h } from "preact";
import cx from "classnames";
import { JSXInternal } from "preact/src/jsx";
import { Spinner } from "../indicators/Spinner";
import * as styles from "./Button.module.scss";

export type Props = Omit<JSXInternal.HTMLAttributes, "size" | "loading"> & {
    /** Icon that will be displayed to the left of the text. */
    icon?: ComponentType<{ color?: string }>;
    /** The label used for the title and aria label of the button. */
    label: string;
    /** Size of the button, defaults to md. */
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    /** Determines the alignment of the button contents. */
    align?: "left" | "center" | "right";
    /** When true, the button will try to fill the full width of its container. */
    fullWidth?: boolean;
    /** When true, the button will be rendered with only a border and text. */
    outlined?: boolean;
    /** When true, the button will be rendered red. */
    dangerous?: boolean;
    /** When true, the button will be disabled and a "loader" will be displayed. */
    loading?: boolean;
};

export function Button({
    label,
    className = "",
    icon: Icon,
    type = "button",
    size = "md",
    align = "center",
    fullWidth,
    loading,
    disabled,
    outlined,
    dangerous,
    children,
    ...buttonProps
}: Props) {
    const classes = cx(`${styles.button} ${styles[size]} ${styles[align]}`, className, {
        [styles.isFullWidth]: fullWidth,
        [styles.bordered]: outlined,
        [styles.danger]: dangerous,
    });

    if (!children) children = label;
    if (Icon) {
        children = (
            <>
                <Icon color="currentColor" />
                {children}
            </>
        );
    }

    return (
        <button
            className={classes}
            type={type}
            aria-label={label}
            title={label}
            disabled={loading || disabled}
            {...buttonProps}
        >
            {loading
                ? (<Spinner />)
                : <span className={styles.label}>{children}</span>}
        </button>
    );
}