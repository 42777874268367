import { h } from "preact";
import { ComponentChildren, createContext } from "preact";
import { useContext, useEffect, useMemo, useState } from "preact/hooks";
import { Close, Checkmark, Entrapment } from "../components/Icons";
import * as styles from "./Toast.module.scss";

export type ToastOptions = {
    /** The body of the toast message. */
    message: string;
    /** Defines the color and behaviour of the toast message. */
    type: "info" | "warning" | "success" | "error";
};

type ToastState = ToastOptions & {
    /** Unique key assigned to the toast message at creation. */
    key: string;
    /** The duration of time that the message should be displayed for (if any). */
    duration: number;
};

type ToastMessageProps = ToastState & {
    /** Dismisses the toast message. */
    onDismiss(): void;
};


export type ToastFunc = (opts: ToastOptions) => void;


type Props = {
    /** Children that will be rendered with access to the toast context. */
    children?: ComponentChildren;
};

const Context = createContext<ToastFunc>(() => { });

let toastCounter = 0;

/**
 * Displays notification messages to the user.
 */
export function ToastProvider({ children }: Props) {
    const [messages, setMessages] = useState<ToastState[]>([]);

    const toaster = useMemo(() => (opts: ToastOptions) => {
        let key = (toastCounter++).toString();

        setMessages(messages.concat({
            key,
            duration: 8000,
            ...opts,
        }));

        return key;
    }, [setMessages, messages]);

    const dismissToast = (key: string) => {
        const i = messages.findIndex(m => m.key === key);
        if (i === -1) return;
        const newMessages = messages.slice(0);
        newMessages.splice(i);
        setMessages(newMessages);
    };

    return (
        <Context.Provider value={toaster}>
            <div class={styles.toasts}>
                {messages.map(msg => (
                    <ToastMessage
                        {...msg}
                        key={msg.key}
                        onDismiss={() => dismissToast(msg.key)}
                    />
                ))}
            </div>
            {children}
        </Context.Provider>
    );
}


/**
 * Internal component that handles the rendering of a single toast component, along with
 * display timeout for the toast message if applicable.
 */
function ToastMessage({ type, duration, message, onDismiss }: ToastMessageProps) {
    const classes = styles.toast + " " + styles[type];

    useEffect(() => {
        if (duration <= 0) return;

        const timeout = setTimeout(onDismiss, duration);

        return () => clearTimeout(timeout);
    }, [duration, onDismiss]);

    const toastIcon = type === "error" ? <Close color="#FF5C5D" /> : <Checkmark color="#94EC86" />;

    return (
        <div class={classes}>
            <div className={styles.icon}>{toastIcon}</div>
            <p>{message}</p>
            <button
                class={styles.close}
                aria-label="Dismiss"
                title="Dismiss"
                onClick={onDismiss}
            >
                <div className={styles.close}><Close color="#fff"/></div>
            </button>
        </div>
    );
}


/**
 * Hook utility for displaying a toast notification.
 */
export const useToast = () => useContext(Context);