import { h, Fragment } from "preact";
import { Card } from "../components/CardList";
import { DateTime } from "../components/DateTime";
import { DeleteEntityButton } from "../components/DeleteEntityButton";
import { Drawer } from "../components/Drawer";
import { Building, Edit } from "../components/Icons";
import { TicketCardList } from "../components/TicketCardList";
import { useAuth } from "../contexts/Auth";
import { createOverviewPage } from "../factories/createOverviewPage";
import { GetUserQuery, useDeleteUserMutation, useGetUserQuery, UserRole } from "../graphql/generated";
import { formatEventType } from "./employee/ScheduleEventListView";
import * as styles from "./UserProfileView.module.scss";

/**
 * Displays a read-only version of a user's profile.
 */
export const UserProfileView = createOverviewPage<{ id: string }, GetUserQuery>({
    useQuery: ({ id }) => useGetUserQuery({ variables: { id } }),
    showNotFound: data => !data || !data.user,
    title: ({ user }) => user!.name,
    subTitle: ({ user }) => {
        switch (user!.role) {
            case UserRole.Customer:
                return "User";
            case UserRole.Dispatch:
                return "Dispatcher";
            case UserRole.Technician:
                return "Technician";
        }
    },
    info({ data }) {
        const user = data.user!;

        return (
            <>
                <h3>Name</h3>
                {user.name}

                <h3>Email Address</h3>
                {user.email}

                <h3>Phone Number</h3>
                {user.phoneNumber ?? "N/A"}

                {user.role !== UserRole.Customer && (
                    <>
                        <h3>COVID-19 Vaccination</h3>
                        {user.isVaccinated
                            ? "This user has received a COVID-19 vaccination."
                            : "This user has not been marked as having received a COVID-19 vaccination."}
                    </>
                )}
            </>
        );
    },
    actions({ data, route }) {
        const auth = useAuth();

        if (auth.user?.id === data.user!.id) {
            return (
                <>
                    <Drawer.Button
                        label="Edit Profile"
                        href={`/users/${data.user!.id}/edit`}
                        icon={<Edit />}
                    />
                    <Drawer.Button
                        label="Change Password"
                        href={`/changePassword`}
                        icon={<Edit />}
                    />
                </>
            );
        }

        if (auth.user?.role !== UserRole.Dispatch) {
            return null;
        }

        return (
            <>
                <Drawer.Button
                    label="Edit User"
                    href={`/users/${data.user!.id}/edit`}
                    icon={<Edit />}
                />
                <DeleteEntityButton
                    id={data.user!.id}
                    type="user"
                    warningText={data.user!.role === UserRole.Technician
                        ? "Are you sure you want to delete this technician? Doing so will immediately unassign them any active service requests and will delete all schedule events pertaining to this user. This cannot be undone."
                        : "Are you sure you want to delete this user? This cannot be undone."}
                    useMutation={useDeleteUserMutation}
                    onSuccess={() => route.history.replace("/employees")}
                />
            </>
        );
    },
    contents({ data, route, auth }) {
        const { buildings, assignedTickets, reportedTickets, upcomingScheduleEvents, role } = data.user!;
        const history = route.history;

        return (
            <>
                {role === UserRole.Technician && (
                    <>
                        <h3>Active Assigned Service Requests</h3>
                        <TicketCardList
                            serviceRequest
                            tickets={assignedTickets}
                            history={history}
                        />
                    </>
                )}

                <h3>Active Reported Service Requests</h3>
                <TicketCardList
                    serviceRequest
                    tickets={reportedTickets}
                    history={history}
                />

                {buildings.length > 0 && (
                    <>
                        <h3>Assigned Buildings</h3>
                        <Card.List
                            icon={() => <Building />}
                            items={buildings}
                            onClick={b => history.push(`/buildings/${b.id}`)}
                        >
                            {building => (
                                <h3>
                                    {building.name}
                                </h3>
                            )}
                        </Card.List>
                    </>
                )}

                {role === UserRole.Technician && (
                    <>
                        <h3>Upcoming Schedule Events</h3>
                        <Card.List
                            items={upcomingScheduleEvents}
                            onClick={auth.role === UserRole.Dispatch
                                ? (ev) => history.push(`/schedule/${ev.id}`)
                                : undefined}
                        >
                            {ev => {
                                const subtext = formatEventType(ev);

                                return (
                                    <Card.Info>
                                        <h3>
                                            {ev.name && (<div className={styles.eventName}>{ev.name}</div>)}
                                            <div className={styles.eventSubtext}>{subtext}</div>
                                        </h3>
                                        <div className={styles.eventDates}>
                                            <DateTime when={ev.startsAt} />
                                            <span>to</span>
                                            <DateTime when={ev.endsAt} />
                                        </div>
                                    </Card.Info>
                                );
                            }}
                        </Card.List>
                    </>
                )}
            </>
        );
    },
});
