import { h } from "preact";
import { createEntityRefField } from "../../factories/createEntityRefField";
import { SearchTechsDocument, SearchTechsQuery, SearchTechsQueryVariables } from "../../graphql/generated";

type Tech = SearchTechsQuery["techs"]["nodes"][0];

type Props = {};

/**
 * This is a special form of reference field that will look up information about a tech.
 */
export const SpecializedTechRefField = createEntityRefField<Tech, Props>({
    async searchItems(search, { query }) {
        const { error, data } = await query<SearchTechsQuery, SearchTechsQueryVariables>(SearchTechsDocument, { search }).toPromise();
        return !error && data ? data.techs.nodes : [];
    },
    render(user) {
        return (
            <span>{user!.name}</span>
        );
    },
});