import { h } from "preact";
import snakeCase from "lodash/snakeCase";
import capitalize from "lodash/capitalize";
import cx from "classnames";
import { FieldPath, FieldValues, UseFormRegister } from "react-hook-form";
import * as styles from "./Field.module.scss";
import { useFieldErrors } from "../../hooks/useFieldErrors";

type Props<TFieldValues extends FieldValues = FieldValues> = {
    /** Optional ID to pass to the field. */
    id?: string;
    /** The label used for the title and aria label of the button. */
    label?: string;
    /** The field name. */
    name: FieldPath<TFieldValues>;
    /** The register function provided by React Hook Form. */
    register: UseFormRegister<TFieldValues>;
    /** When true, the field will be marked as required. */
    required?: boolean;
};

const PATTERN = /^\+[1-9]\d{6,14}$/;

function validate(value: string) {
    if (!value) return true;

    if (PATTERN.test(reformat(value))) {
        return true;
    }

    return "Please provide a valid phone number.";
}

function reformat(value: string) {
    if (!value) return "";
    value = value.replaceAll(/([^0-9]+)/g, "");
    if (value.length === 0) return "";
    if (value.charAt(0) !== "1") value = "1" + value;
    return "+" + value;
}

/**
 * Renders an input field for working with phone numbers.
 */
export function PhoneNumberField<TFieldValues>({ label, id, name, required, register }: Props<TFieldValues>) {
    if (!label) label = capitalize(name as string);
    if (!id) id = `tel__${snakeCase(label)}`;

    const { isValid, error } = useFieldErrors(name);

    const classes = cx(styles.field, {
        [styles.required]: required,
        [styles.isInvalid]: !isValid,
    });

    const { onBlur, ...regProps } = register(name as any, {
        required: required,
        validate: validate as any,
    });

    const handleBlur: h.JSX.FocusEventHandler<HTMLInputElement> = (ev) => {
        const target = (ev.target as HTMLInputElement);
        target.value = reformat(target.value);
        onBlur(ev);
    };

    return (
        <div className={classes}>
            <label className={styles.label} htmlFor={id}>{label}</label>
            <input
                id={id}
                type="tel"
                pattern={PATTERN.source}
                placeholder="+1##########"
                onBlur={handleBlur}
                {...regProps}
            />
            {error && (<p className={styles.error}>{error}</p>)}
        </div>
    );
}