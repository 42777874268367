import { h } from "preact";
import { useForm } from "react-hook-form";
import { Button } from "../../components/controls/Button";
import { TextField } from "../../components/controls/TextField";
import { useToast } from "../../contexts/Toast";
import { useRequestPasswordResetMutation } from "../../graphql/generated";
import * as styles from "./ForgotPassword.module.scss";

// import * as styles from "./PasswordResetRequest.module.scss";

type Props = {};

/**
 * Renders the form for requesting a password reset.
 */
export function ForgotPasswordView({ }: Props) {
    const toast = useToast();
    const [result, mutate] = useRequestPasswordResetMutation();
    const { register, reset, handleSubmit, formState: { isValid } } = useForm<{ email: string }>({ mode: "onChange" });

    const onSubmit = handleSubmit(async ({ email }) => {
        const { error } = await mutate({ email });

        if (error) {
            toast({
                type: "error",
                message: "Failed to send request to reset password. Please try again.",
            });
            return;
        }

        toast({
            type: "success",
            message: `A password reset link will be sent to "${email}", if an account with that email exists. Please check your inbox and spam folders.`,
        });

        reset();
    });

    return (
        <form onSubmit={onSubmit}>
            <TextField
                register={register}
                name="email"
                label="E-mail Address"
                type="email"
                placeholder="name@example.com"
                required
            />
            <Button
                type="submit"
                label="Request Password Reset"
                disabled={!isValid}
                loading={result.fetching}
                fullWidth
                className={styles.requestReset}
            />
        </form>
    );
}