import { useEffect } from "preact/hooks";

/**
 * Hook utility that binds a handler to the window's keydown event.
 */
export function useKeyDown(
    handler: (ev: KeyboardEvent) => void,
    el?: null | HTMLElement | Document | Window,
    deps: unknown[] = []
) {
    useEffect(() => {
        const _el = el ?? window;
        _el.addEventListener("keydown", handler);

        return () => {
            _el.removeEventListener("keydown", handler);
        };
    }, [handler, el, ...deps]);
}