import { h } from "preact";
import cx from "classnames";
import { FieldPath, FieldValues, UseFormRegister } from "react-hook-form";
import capitalize from "lodash/capitalize";
import snakeCase from "lodash/snakeCase";
import * as styles from "./Field.module.scss";

type Props<OptionValue extends string, TFieldValues extends FieldValues = FieldValues> = {
    /** The label used for the title and aria label of the button. */
    label?: string;
    /** The field name. */
    name: FieldPath<TFieldValues>;
    /** The register function provided by React Hook Form. */
    register: UseFormRegister<TFieldValues>;
    /** When true, a selection is required. */
    required?: boolean;
    /** When true, the fields will be disabled. */
    disabled?: boolean;
    /** When provided, renders some additional contextual information under the top-level label. */
    help?: string | JSX.Element;
    /** The options to present as radio input. */
    options: {
        /** The human-readable label that is presented per option. */
        label: string;
        /** The value represented by each option. */
        value: OptionValue;
        /** Optionally provide additional information underneath each radio option. */
        description?: string | JSX.Element;
    }[];
};

/**
 * Renders a collection of radio options.
 *
 * NOTE: Currently the required option is purely cosmetic, as there is a weird bug that happens
 * when marking options as "required". This bug requires that the LAST option be clicked at least
 * once... Instead, it is recommended that you NEVER use this component without a defualt value
 * being assigned.
 */
export function RadioOptionsField<T extends string, TFieldValues>({ label, options, name, register, required, disabled, help }: Props<T, TFieldValues>) {
    if (!label) label = capitalize(name as string);
    const id = snakeCase(name);

    const classes = cx(styles.field, {
        [styles.required]: required,
    });

    const regProps = register(name, {
        // required: required,
        disabled: disabled,
    });

    return (
        <div className={classes}>
            <label className={styles.label}>{label}</label>
            {help && (<div className={styles.help}>{help}</div>)}
            <div>
                {options.map(o => (
                    <label
                        className={styles.radioOption}
                        htmlFor={`radio_${id}_${o.value}`}
                        key={o.value}
                    >
                        <div className={styles.radioOptionLine}>
                            <div className={styles.radioControl}>
                                <input
                                    type="radio"
                                    id={`radio_${id}_${o.value}`}
                                    value={o.value}
                                    disabled={disabled}
                                    {...regProps}
                                />
                            </div>
                            <div className={styles.radioLabel}>{o.label}</div>
                        </div>
                        {o.description && (
                            <div className={styles.radioDescription}>{o.description}</div>
                        )}
                    </label>
                ))}
            </div>
        </div >
    );
}