import { h, Fragment } from "preact";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { useCloseTicketMutation, CloseTicketMutation, GetTicketMinimalQuery, CloseTicketMutationVariables, useGetTicketMinimalQuery, TicketStatus } from "../../graphql/generated";

type Fields = CloseTicketMutationVariables;
type RouteParams = { id: string };
type MutRes = CloseTicketMutation;
type MutVar = CloseTicketMutationVariables;
type Query = GetTicketMinimalQuery;

/**
 * Renders the form view for closing a ticket.
 */
export const CloseTicketFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Close Service Request",
    useDefaultValues: ({ data }) => ({
        id: data!.ticket!.id,
        resolution: "",
    }),
    showNotFound: (data) => !data || !data.ticket || data.ticket.status === TicketStatus.Closed,
    useQuery: ({ id }) => useGetTicketMinimalQuery({ variables: { id } }),
    toVars: ({ id, resolution }) => ({ id, resolution }),
    useMutation: useCloseTicketMutation,
    onSuccess: (res, { history }) => history.goBack(),
    onSuccessMessage: res => `Successfully updated service request: ${res.ticket.id}`,
    render({ register }) {
        return (
            <>
                <TextField
                    register={register}
                    type="multiline"
                    name="resolution"
                    label="Resolution"
                    required
                />
            </>
        );
    },
});