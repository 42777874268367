import { h } from "preact";
import snakeCase from "lodash/snakeCase";
import capitalize from "lodash/capitalize";
import { FieldPath, FieldValues, UseFormRegister } from "react-hook-form";
import * as styles from "./Checkbox.module.scss";

export type Props<TFieldValues extends FieldValues = FieldValues, TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    /** The field name. */
    name: TFieldName;
    /** The register function provided by React Hook Form. */
    register: UseFormRegister<TFieldValues>;
    /** Label that will be rendered for the checkbox. */
    label?: string;
    /** Value that will be toggled by this checkbox. */
    value: string;
    /** Optionally provide an ID for the field. */
    id?: string;
};

/**
 * This component provides a simple, styled checkbox that has an associated label next to it.
 */
export function Checkbox<TFieldValues>({ label, id, name, register, value }: Props<TFieldValues>) {
    if (!label) label = capitalize(name as string);
    if (!id) id = `checkbox__${snakeCase(label)}`;

    return (
        <div className={styles.checkbox}>
            <input type="checkbox" id={id} {...register(name)} value={value} />
            <label className={styles.label} htmlFor={id}>{label}</label>
        </div>
    );
}