import { h, Fragment } from "preact";
import { useDisclosure } from "../hooks/useDisclosure";
import { DeleteEntityDrawer, Props as DeleteEntityDrawerProps } from "./DeleteEntityDrawer";
import { Trash } from "./Icons";
import { PageHeader } from "./PageHeader";

type Props<MutationResult> = Omit<DeleteEntityDrawerProps<MutationResult>, "isOpen" | "onClose">;

/**
 * A drawer button that handles the majority of the boilerplate around delete a specific
 * record via GraphQL.
 */
export function DeleteEntityIconAction<MutationResult>(props: Props<MutationResult>) {
    const drawer = useDisclosure();

    return (
        <>
            <DeleteEntityDrawer
                {...props}
                isOpen={drawer.isOpen}
                onClose={drawer.onClose}
            />
            <PageHeader.IconAction
                icon={<Trash />}
                onClick={drawer.onOpen}
            />
        </>
    );
}