import { UseMutationResponse } from "@urql/preact";
import { h } from "preact";
import { useToast } from "../contexts/Toast";
import { ConfirmationDrawer } from "./ConfirmationDrawer";
import { Trash } from "./Icons";

export type Props<MutationResult> = {
    /** The type of record being deleted (singular). */
    type: string;
    /** The ID of the entity record that we're going to delete. */
    id: string;
    /** The warning text that will be displayed. */
    warningText?: string | JSX.Element;
    /** Should the drawer be open and showin? */
    isOpen: boolean;
    /** Event that is called when the drawer should be closed. */
    onClose(): void;
    /** The mutation hook used to delete the record. */
    useMutation(): UseMutationResponse<MutationResult>;
    /** Event that will trigger after the mutation completes successfully. */
    onSuccess?(data: MutationResult): void;
};

/**
 * A drawer that handles the majority of the boilerplate around delete a specific record via GraphQL.
 */
export function DeleteEntityDrawer<MutationResult>({
    useMutation,
    id,
    type,
    warningText,
    isOpen,
    onClose,
    onSuccess,
}: Props<MutationResult>) {
    const toast = useToast();
    const [{ fetching }, mutate] = useMutation();

    if (!warningText) {
        warningText = `Are you sure you want to remove this ${type.toLowerCase()}? This cannot be undone.`
    }

    const onConfirm = async () => {
        const { error, data } = await mutate({ id })

        if (error) {
            toast({
                type: "error",
                message: error.message,
            });
            return;
        }

        toast({
            type: "success",
            message: `Successfully removed ${type.toLowerCase()}.`,
        });

        if (onSuccess) {
            onSuccess(data!);
        }
    }

    return (
        <ConfirmationDrawer
            danger
            icon={<Trash />}
            label={`Remove ${type}`}
            loading={fetching}
            message={warningText}
            isOpen={isOpen}
            onConfirm={onConfirm}
            onClose={onClose}
            confirmLabel="Remove"
        />
    );
}