import { h, Fragment } from "preact";
import { Link } from "react-router-dom";
import { DeleteEntityButton } from "../../components/DeleteEntityButton";
import { Drawer } from "../../components/Drawer";
import { Edit } from "../../components/Icons";
import { TicketCardList } from "../../components/TicketCardList";
import { createOverviewPage } from "../../factories/createOverviewPage";
import { GetServiceLocationQuery, useDeleteServiceLocationMutation, useGetServiceLocationQuery, UserRole } from "../../graphql/generated";
// import * as styles from "./ServiceLocationView.module.scss";

/**
 * Renders a single service location and displays the service location's information and relationships.
 */
export const ServiceLocationView = createOverviewPage<{ id: string, buildingId: string }, GetServiceLocationQuery>({
    useQuery: ({ buildingId, id }) => useGetServiceLocationQuery({ variables: { buildingId, id } }),
    showNotFound: data => !data || !data.building || !data.building.serviceLocation,
    title: ({ building }) => building!.serviceLocation!.name,
    subTitle: () => "Service Location",
    info({ data }) {
        const { id: buildingId, name: buildingName, serviceLocation, serviceZone, customer } = data.building!;

        return (
            <>
                <h3>Name</h3>
                {serviceLocation!.name}

                <h3>Contract Code</h3>
                <p>{serviceLocation!.contractCode}</p>

                <h3>Building</h3>
                <Link to={`/buildings/${buildingId}`}>
                    {buildingName}
                </Link>

                <h3>Customer</h3>
                <Link to={`/customers/${customer.id}`}>
                    {customer.name}
                </Link>

                <h3>Service Zone</h3>
                <Link to={`/serviceZones/${serviceZone.id}`}>
                    {serviceZone.name}
                </Link>
            </>
        );
    },
    actions({ data, route, auth }) {
        if (auth.role !== UserRole.Dispatch) {
            return null;
        }

        const { id: buildingId, serviceLocation, serviceLocations } = data.building!;

        return (
            <>
                <Drawer.Button
                    icon={<Edit />}
                    label="Edit Service Location"
                    href={`/buildings/${buildingId}/serviceLocations/${serviceLocation!.id}/edit`}
                />
                {serviceLocations.length > 1 && (
                    <DeleteEntityButton
                        id={serviceLocation!.id}
                        type="Service Location"
                        useMutation={useDeleteServiceLocationMutation}
                        onSuccess={() => route.history.push(`/buildings/${buildingId}`)}
                    />
                )}
            </>
        );
    },
    contents({ data, route }) {
        const history = route.history;
        const { serviceLocation } = data.building!;
        const tickets = serviceLocation!.tickets;

        return (
            <>
                <h3>Active Service Requests</h3>
                <TicketCardList
                    serviceRequest
                    tickets={tickets}
                    history={history}
                />
            </>
        );
    },
});