.tabs {
    background: #fff;
    border-bottom: solid 1px #ccc;
}

.container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    max-width: 640px;
}

.tab {
    cursor: pointer;
    position: relative;
    padding: 10px;
    flex: 1;
    text-align: center;
    opacity: 0.5;
    color: currentColor;
    z-index: 10;
    user-select: none;

    &.isActive {
        opacity: 1;

        &:after {
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            height: 2px;
            content: "";
            background: currentColor;
        }
    }
}
