.cards {
    display: flex;
    flex-direction: column;
}

.card {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 30px 20px;
    background: #fff;
    color: #7f7f7f;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
    transition: transform 100ms ease;
    font-size: 0.875em;
    z-index: 1;

    &.isClickable {
        cursor: pointer;
        user-select: none;

        @media (min-width: 768px) {
            &:hover {
                transform: scale(1.05);
                z-index: 10;
            }
        }
    }

    &.withChevron {
        padding-right: 40px;

        &:after {
            position: absolute;
            top: 50%;
            right: 10px;
            width: 16px;
            height: 16px;
            border-right: solid 1px #666;
            border-bottom: solid 1px #666;
            background: transparent;
            transform: translate(-50%, -50%) rotate(-45deg);
            content: "";
        }
    }

    & + & {
        margin-top: 10px;
    }

    a > strong,
    div > strong,
    > div h3 {
        color: black;
    }
}

.cardIcon {
    margin-right: 15px;
}

.cardContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #7f7f7f;

    // &:not(:first-child):not(:last-child) {
    //     justify-content: center;
    // }

    // &:last-child {
    //     justify-content: flex-end;
    // }

    > svg {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
    }
}

.info {
    display: flex;
    align-items: center;

    .icon {
        margin-right: 0.75rem;
    }

    .content {
        display: flex;
        flex-flow: column;
    }
}

.cell {
    flex: 1;
    display: flex;
    justify-content: flex-start;

    & + & {
        justify-content: center;
    }

    &:last-child {
        flex: content;
        justify-content: flex-end;
    }

    > svg {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
    }
}
