import { h, Fragment } from "preact";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { useUpdateCustomerMutation, useGetCustomerQuery, UpdateCustomerInput, UpdateCustomerMutation, GetCustomerQuery, UpdateCustomerMutationVariables } from "../../graphql/generated";

type Fields = UpdateCustomerInput;
type RouteParams = { id: string };
type MutRes = UpdateCustomerMutation;
type MutVar = UpdateCustomerMutationVariables;
type Query = GetCustomerQuery;

/**
 * Renders the form view for updating a customer.
 */
export const UpdateCustomerFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Edit Customer",
    useDefaultValues: ({ data }) => {
        const c = data!.customer!;

        return {
            id: c.id,
            name: c.name,
            email: c.email,
            phoneNumber: c.phoneNumber,
        };
    },
    useQuery: ({ id }) => useGetCustomerQuery({ variables: { id } }),
    useMutation: useUpdateCustomerMutation,
    onSuccess: (res, { history }) => history.replace(`/customers/${res.customer.id}`),
    onSuccessMessage: res => `Successfully updated customer "${res.customer.name}".`,
    render({ register }) {
        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Company / Customer Name"
                    required
                />
                {/* <TextField
                    register={register}
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    label="Company Email"
                />
                <PhoneNumberField
                    register={register}
                    name="phoneNumber"
                    label="Company Phone Number"
                /> */}
            </>
        );
    },
});