import { h } from "preact";
import { useMemo } from "preact/hooks";
import cx from "classnames";
import { Button, Props as ButtonProps } from "./controls/Button";
import { memo } from "preact/compat";
import * as styles from "./FooterActions.module.scss";

export type Action = ButtonProps;

export type Props = {
    /** Optionally disable all actions. Useful if the page is performing an operation already. */
    disableAll?: boolean;
    /** List of actions that will be rendered. */
    actions: Action[];
};

/**
 * Renders a collection of sticky actions that will appear at the bottom of the screen.
 * The layout of the actions will adapt based on the number of actions provided.
 */
export const FooterActions = memo(({ actions, disableAll }: Props) => {
    const classes = cx(styles.root, {
        [styles.isStacked]: actions.length > 2,
    });

    const buttons = useMemo(() => {
        return actions.map(({ disabled, ...action }, index) => (
            <Button
                key={index}
                {...action}
                disabled={disabled || disableAll}
            />
        ));
    }, [actions, disableAll]);

    return (
        <footer className={classes}>
            {buttons}
        </footer>
    );
});