

import { h, Fragment } from "preact";
import { Link } from "react-router-dom";
import * as styles from "../views/CommentsView.module.scss"
import { Avatar } from "./Avatar";
import { DateTime } from "./DateTime";
// import * as styles from "./PhoneLink.module.scss";

type Props = {
    /** The phone number formatted as +1########## */
    comment: null | {
        id: string;
        content: string;
        author?: null | {
            id: string;
            name: string;
            avatarUrl: string;
        }
        createdAt: string;
    };
    ticketId: string;
};



/**
 * Simple component for rendering the latest comment on a ticketview.
 */

export const TicketComment = ({ comment, ticketId }: Props) => {

    if (!comment) {
        return (
            <Link to={`/tickets/${ticketId}/comments`} className={styles.ticketViewLinkEmpty}>
                Create comment
            </Link>
        )
    }

    return (
        <>
            <div key={comment.id} className={styles.comment}>
                <header>
                    <Avatar
                        name={comment?.author?.name ? comment.author.name : "System"}
                        src={comment?.author?.avatarUrl}
                        size={10}
                    />
                    <div className={styles.commentDetails}>
                        <div className={styles.author}>{comment?.author?.name}</div>
                        <DateTime when={comment.createdAt} />
                    </div>
                </header>
                <section className={styles.ticketCommentBody}>
                    {comment.content}
                    <Link to={`/tickets/${ticketId}/comments`} className={styles.ticketViewLink}>
                        View&nbsp;more
                    </Link>
                </section>
            </div>
        </>
    );
};
