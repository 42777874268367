import { ComponentChildren, h } from "preact";
import cx from "classnames";
import { memo } from "preact/compat";
import * as styles from "./CardList.module.scss";

export type Props = {
    /** When present, renders the given icon or avatar to the left of the card content. */
    icon?: null | JSX.Element;
    /** Event that is called when the card is clicked. Styles card as a clickable object. */
    onClick?(): void;
    /** The content to render in the card. */
    children?: ComponentChildren;
};

export function Card({ icon, onClick, children }: Props) {
    const classes = cx(styles.card, {
        [styles.isClickable]: !!onClick,
        [styles.withChevron]: !!onClick,
    });

    return (
        <div
            onClick={onClick}
            className={classes}
        >
            {icon && (
                <div className={styles.cardIcon}>{icon}</div>
            )}
            <div className={styles.cardContent}>
                {children}
            </div>
        </div>
    );
}


type CardProps = {
    children: ComponentChildren;
    icon?: null | JSX.Element;
}

export namespace Card {

    export type ListProps<T> = {
        /** The card items to be rendered. */
        items: T[];
        /** Allows an icon to be rendered per item. */
        icon?(data: T): null | JSX.Element;
        /** Event that is called when the card is clicked. */
        onClick?(data: T): void;
        /** Renders the interior contents of a card. */
        children(data: T, index: number): JSX.Element;
    };

    /**
     * Renders a list of cards and provides some common styles per card.
     */
    export const List = memo(function <T>({ items, icon, onClick, children }: Card.ListProps<T>) {
        const cards = items.map((data, index) => {
            return (
                <Card
                    icon={icon && icon(data)}
                    onClick={onClick ? () => onClick(data) : undefined}
                    key={index}
                >
                    {children(data, index)}
                </Card>
            );
        });

        return (
            <div className={styles.cards}>
                {cards.length > 0 ? cards : (
                    <p>
                        There are no results available.
                    </p>
                )}
            </div>
        );
    });

    export function Cell({ children }: { children?: ComponentChildren }) {
        return (
            <div className={styles.cell}>
                {children}
            </div>
        );
    }

    export function Info({ children, icon = null }: CardProps) {
        return (
            <div className={styles.info}>
                {icon && <div className={styles.icon}>{icon}</div>}
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        );
    }
}