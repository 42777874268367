.toText {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 0;
    text-align: center;
    opacity: 0.4;
    font-weight: bold;
    font-size: 0.75em;
}
