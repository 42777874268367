import { ComponentChildren, h } from "preact";
import cx from "classnames";
import * as styles from "./PageHeader.module.scss";

export type Props = {
    /** The child content to be rendered in this page header. */
    children: ComponentChildren;
};

/**
 * Renders a common page header.
 */
export function PageHeader({ children }: Props) {
    return (
        <header className={styles.root}>
            <div className={styles.content}>
                {children}
            </div>
        </header>
    );
}

export namespace PageHeader {

    export type RowProps = {
        /** The child content to be rendered in this page header row. */
        children: ComponentChildren;
    };

    /**
     * Renders content in a center-aligned row with in the header.
     */
    export function Row({ children }: RowProps) {
        return (
            <div className={styles.row}>
                {children}
            </div>
        );
    }

    export type TitleProps = {
        /** Optional icon that will appear to the left of the title + subtitle. */
        icon?: JSX.Element;
        /** Main title content that is displayed in the header. */
        title: string;
        /** Optional sub title to be displayed below the main title. */
        subTitle?: string;
        /** When present, the title will have an "info" icon next to it and be clickable. */
        onClick?(): void;
    };


    /**
     * Renders the title and subtitle for a page. Optionally supports the ability for the title to be clickable
     * or for the
     */
    export function Title({ icon, title, subTitle, onClick }: TitleProps) {
        const Tag = onClick ? "a" : "div";

        return (
            <Tag className={styles.title} onClick={onClick}>
                {icon && (
                    <div className={styles.icon}>
                        {icon}
                    </div>
                )}
                <div className={styles.info}>
                    <h1>{title}</h1>
                    {subTitle && (
                        <h2>{subTitle}</h2>
                    )}
                </div>
            </Tag>
        );
    }

    type IconActionProps = {
        /** The label that will be used as the hover text for button. */
        label?: string;
        /** The icon used with this action. */
        icon: JSX.Element;
        /** Event that is triggered when the action is pressed. */
        onClick(): void;
    };

    /**
     * Renders an icon action that can be used in a page header (on the right hand side).
     */
    export function IconAction({ icon, label = "", onClick }: IconActionProps) {
        return (
            <a
                title={label}
                onClick={onClick}
                className={styles.action}
            >
                {icon}
                {label && (<span>{label}</span>)}
            </a>
        );
    }

}