@import "../mixins";

.items {
    @extend %container;
    padding-bottom: 100px;

    hr {
        margin: 30px 0;
        border: 0;
        border-top: solid #ccc 1px;
    }
}
