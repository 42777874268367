import { h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import cx from "classnames";
import { useDebounce } from "../../hooks/useDebounce";
import { useKeyDown } from "../../hooks/useKeydown";
import { Search } from "../Icons";
import * as styles from "./OptionLookupField.module.scss";

type Props<Data> = {
    /** The options to be presented in the field. */
    options: Data[];
    /** When true, the field will be disabled. */
    disabled?: boolean;
    /** When true, the field will be rendered as "required". */
    required?: boolean;
    /** The placeholder text to display in the input. */
    placeholder?: string;
    /** When true, the field will fill its container's full width. */
    fullWidth?: boolean;
    /** Event that is called when the search/filter field is changed. */
    onSearch(input: string): void;
    /** Event that is called when a result is selected. */
    onSelected(data: Data): void;
    /** Renders a single result value for the lookup field. */
    children(data: Data, index: number): JSX.Element | JSX.Element[];
};

/**
 * Creates an input field that allows the user to search for entities from a data source
 * and select one of the results (using keyboard or mouse).
 */
export function OptionLookupField<Data>({
    options,
    disabled,
    required,
    fullWidth,
    onSearch,
    onSelected,
    placeholder,
    children,
}: Props<Data>) {
    const ref = useRef<HTMLInputElement>(null);
    const [selected, setSelected] = useState(0);
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setSelected(0);
        // if (selected >= options.length) {
        //     setSelected(options.length);
        // }
    }, [options]);

    useKeyDown((ev) => {
        if (ev.key === "Enter") {
            ev.preventDefault();

            if (selected < options.length) {
                onSelected(options[selected]);
            }
            return;
        }

        if (ev.key === "Escape") {
            setFocus(false);
            return;
        }

        if (ev.key === "ArrowUp") {
            ev.preventDefault();

            let deltaMod = (selected - 1) % options.length;
            if (deltaMod < 0) deltaMod = options.length + deltaMod;

            setSelected(deltaMod);
            return;
        }

        if (ev.key === "ArrowDown") {
            ev.preventDefault();
            const deltaMod = (selected + 1) % options.length;
            setSelected(deltaMod);
        }
    }, ref.current);

    const onSearchChange = useDebounce(async () => {
        if (ref.current) {
            onSearch(ref.current.value);
            setFocus(true);
        }
    }, 150, [ref, setFocus, onSearch]);

    const classes = cx(styles.field, {
        [styles.showResults]: focus && !disabled && (ref.current?.value) && options.length > 0,
        [styles.fullWidth]: fullWidth,
        [styles.required]: required,
    });

    return (
        <div className={classes}>
            <Search />
            <input
                ref={ref}
                type="text"
                disabled={disabled}
                onChange={onSearchChange}
                onFocus={() => setFocus(true)}
                placeholder={placeholder || ""}
            />
            <div
                className={styles.overlay}
                onClick={() => setFocus(false)}
            />
            <nav className={styles.results}>
                {options.map((o, i) => {
                    const classes = cx(styles.result, {
                        [styles.isSelected]: selected === i,
                    });

                    return (
                        <div
                            key={i}
                            className={classes}
                            onClick={() => onSelected(o)}
                        >
                            {children(o, i)}
                        </div>
                    );
                })}
            </nav>
        </div>
    );
}