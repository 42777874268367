import { h, Fragment } from "preact";
import { Card } from "./CardList";
import { History } from "history";
import { ServiceLocation, Ticket } from "../graphql/generated";
import { DateTime } from "./DateTime";
import { StatusTag } from "./indicators/StatusTag";
import { isToday } from "date-fns";
import { Entrapment, ServiceRequests } from "./Icons";

type TicketData = Pick<Ticket, "id" | "status" | "priority" | "isEntrapment" | "createdAt" | "updatedAt"> & {
    serviceLocation: Pick<ServiceLocation, "name">;
}

type Props = {
    /** Optionally add a prefix to each ticket URL. */
    prefix?: string;
    /** List of ticket data being rendered. */
    tickets: TicketData[];
    /** History object used by our app's router. */
    history?: History;
    /** When true, displays the service request icon. */
    serviceRequest?: boolean;
};

/**
 * Renders a list of ticket cards.
 */
export function TicketCardList({ prefix = "", serviceRequest = false, tickets, history }: Props) {
    const onClick = history && ((t: TicketData) => history.push(`${prefix}/tickets/${t.id}`));

    return (
        <Card.List
            items={tickets}
            onClick={onClick}
            icon={({ isEntrapment }) => isEntrapment ? <Entrapment color="#FF5C5D" /> : <ServiceRequests color="#717171" />}
        >
            {ticket => {
                const { serviceLocation, createdAt, status } = ticket;

                return (
                    <>
                        <Card.Cell>
                            {serviceLocation && <strong>{serviceLocation.name}</strong>}
                        </Card.Cell>
                        <Card.Cell>
                            <DateTime
                                when={createdAt}
                                format={formatTicketDate(createdAt)}
                            />
                        </Card.Cell>
                        <Card.Cell>
                            <StatusTag status={status} />
                        </Card.Cell>
                    </>
                );
            }}
        </Card.List>
    );
}

export const formatTicketDate = (createdAt: string) => {
    const date = new Date(createdAt);
    if (isToday(date)) {
        return "h:mmaaa";
    } else {
        return "eee, MMM d";
    }
};