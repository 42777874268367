import { h } from "preact";
import { TicketPriority } from "../../graphql/generated";
import { Entrapment, PriorityHigh, PriorityLow, PriorityStandard } from "../Icons";

type Props = {
  /** The priority of the ticket. */
  priority: TicketPriority;
}

/**
 * Renders the icon for the respective ticket priority.
 */
export function TicketPriorityIcon({ priority }: Props) {
    switch (priority) {
        case "STANDARD":
            return <PriorityStandard color="#2EC3F2" />;
        case "LOW":
            return <PriorityLow color="#8F8F8F" />;
        case "HIGH":
            return <PriorityHigh color="#F0AD4E" />;
        case "ENTRAPMENT":
            return <Entrapment color="#FF5C5D" />
        default:
            return <PriorityStandard color="#2EC3F2" />;
    }
}