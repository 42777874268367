import { h, Fragment } from "preact";
import { PhoneNumberField } from "../../components/controls/PhoneNumberField";
import { RadioOptionsField } from "../../components/controls/RadioOptionsField";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { useCreateUserMutation, UserRole } from "../../graphql/generated";

const EMPLOYEE_ROLES = [
    {
        value: UserRole.Technician,
        label: "Technician",
        description: "A technician is a user who can be assigned to service requests and entrapments.",
    },
    {
        value: UserRole.Dispatch,
        label: "Dispatcher",
        description: "A dispatcher is an administrative user who is able to onboard customers, manage users, and perform a variety of other critical tasks.",
    },
];

/**
 * Renders the form view for adding a new employee.
 */
export const CreateEmployeeFormView = createFormPage({
    title: () => "Add Employee",
    useDefaultValues: () => ({
        name: "",
        email: "",
        phoneNumber: "",
        role: "TECHNICIAN",
    }),
    useMutation: useCreateUserMutation,
    onSuccess: (res, { history }) => history.replace(`/users/${res.user.id}`),
    onSuccessMessage: res => `Successfully invited new employee "${res.user.name}".`,
    render({ register }) {
        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Name"
                    required
                />
                <TextField
                    register={register}
                    name="email"
                    placeholder="name@example.com"
                    label="Email Address"
                    type="email"
                    required
                />
                <PhoneNumberField
                    register={register}
                    name="phoneNumber"
                    label="Phone Number"
                />
                <RadioOptionsField
                    register={register}
                    help="User type cannot be changed once created."
                    name="role"
                    label="Designation"
                    options={EMPLOYEE_ROLES}
                    required
                />

            </>
        );
    },
});