import { h } from "preact";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { LoginView } from "./guest/Login";
import { ForgotPasswordView } from "./guest/ForgotPassword";
import { useAuth } from "../contexts/Auth";
import { NotFoundView } from "./NotFound";
import { PasswordResetView } from "./guest/PasswordReset";
import { GuestLayout } from "../components/GuestLayout";
import { UserRole } from "../graphql/generated";
import { CustomerRoutes } from "./customer/CustomerRoutes";
import { EmployeeRoutes } from "./employee/EmployeeRoutes";

type Props = {};

/**
 * Provides the routes for the application.
 */
export function Routes({ }: Props) {
    const { user } = useAuth();
    const location = useLocation();

    // if the visitor isn't logged in...
    if (!user) {
        return (
            <GuestLayout>
                <Switch>
                    <Route path="/reset-password/:token" component={PasswordResetView} />
                    <Route path="/forgot-password" component={ForgotPasswordView} />
                    <Route path="/login" component={LoginView} />
                    <Redirect to={`/login?redirectTo=${location.pathname}`} />
                </Switch>
            </GuestLayout>
        );
    }

    switch (user.role) {
        case UserRole.Customer:
            return (
                <CustomerRoutes />
            );
        case UserRole.Technician:
        case UserRole.Dispatch:
            return (
                <EmployeeRoutes user={user} />
            );
        default:
            return (
                <NotFoundView />
            );
    }
}
