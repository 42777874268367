import { h, Fragment } from "preact";
import { TextField } from "../../components/controls/TextField";
import { UserRefField } from "../../components/controls/UserRefField";
import { createFormPage } from "../../factories/createFormPage";
import { CreateServiceZoneInput, CreateServiceZoneMutation, CreateServiceZoneMutationVariables, useCreateServiceZoneMutation, UserRole } from "../../graphql/generated";

type Fields = CreateServiceZoneInput;
type RouteParams = {};
type MutRes = CreateServiceZoneMutation;
type MutVar = CreateServiceZoneMutationVariables;

/**
 * Renders the form view for adding a new service zone.
 */
export const CreateServiceZoneFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, null>({
    title: () => "Add Service Zone",
    useDefaultValues: () => ({
        name: "",
        supervisorId: "",
    }),
    useMutation: useCreateServiceZoneMutation,
    onSuccess: (res, { history }) => history.replace(`/serviceZones/${res.serviceZone.id}`),
    onSuccessMessage: res => `Successfully created new service zone "${res.serviceZone.name}".`,
    render({ register, control }) {
        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Name"
                    required
                />

                <UserRefField
                    role={[UserRole.Dispatch]}
                    placeholder="Start typing to search dispatchers"
                    name="supervisorId"
                    label="Supervisor"
                    control={control}
                    required
                />
            </>
        );
    },
});