.field {
    position: relative;
    padding-top: 10px;
    width: 100%;

    &:not(.required) {
        > label {
            &:after {
                display: inline-block;
                padding-left: 15px;
                font-size: 0.875em;
                opacity: 0.7;
                content: "(Optional)";
            }
        }
    }

    &.hideLabel {
        margin-top: 0;
        padding-top: 0;

        > label {
            display: none;
        }
        > input,
        select {
            margin-top: 0;
        }
    }

    &.isInvalid {
        label,
        input {
            border-color: #ff5c5d;
            color: #ff5c5d;
        }
    }
}

.help {
    margin: 0 0 1rem;
    font-size: 0.875em;
    opacity: 0.5;
}

// .control {
//     position: relative;

//     > label {
//         position: absolute;
//         top: -0.5em;
//         left: 1rem;
//         display: block;
//         margin: 0 0 0 -0.5em;
//         padding: 0 0.5em;
//         background: var(--color-body-bg);
//         font-size: 0.75em;
//     }
// }

.label {
    font-size: 12px;
    line-height: 24px;
}

.selectIcon {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    font-size: 1.5em;
}

.refInput {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--color-body-bg);
    border: solid 1px #ccc;
    color: inherit;
    width: 100%;
    height: 2.5em;
    padding: 0 1em;
    -webkit-appearance: none;

    > a {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        cursor: pointer;
        opacity: 0.5;
    }
}

.radioOption {
    display: block;
    margin: 10px 0;
    font-size: 1em;
    font-style: normal;
    font-weight: normal;
}

.radioOptionLine,
.radioControl {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.radioControl {
    width: 30px;

    > input {
        width: auto;
    }
}

.radioLabel {
    flex: 1;
    user-select: none;
}

.radioDescription {
    margin-top: 5px;
    margin-left: 30px;
    font-size: 0.75em;
    opacity: 0.7;
    flex: 1;
    user-select: none;
}

.control {
    position: relative;
}

.selectArrow {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 16px;
    height: 16px;
    border-right: solid 1px #666;
    border-bottom: solid 1px #666;
    background: transparent;
    transform: translate(-50%, -75%) rotate(45deg);
    content: "";
    user-select: none;
    pointer-events: none;
}

.error {
    padding: 5px;
    color: #ff5c5d;
    font-size: 12px;
}
