.root {
    position: fixed;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
    z-index: 40;

    > button + button {
        margin-left: 10px;
    }

    @media (max-width: 768px) {
        > button {
            flex: 1;
        }

        &.isStacked {
            flex-direction: column;

            > button + button {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}
