import { h, Fragment } from "preact";
import { Building, SortBy } from "../../components/Icons";
import { createListPage, createOptionsFromEnum } from "../../factories/createListPage";
import { BuildingSortBy, GetBuildingsQuery, SortOrder, useGetBuildingsQuery, UserRole } from "../../graphql/generated";

type Building = GetBuildingsQuery["buildings"]["nodes"][0];

/**
 * Renders the full list of buildings and allows for new building accounts to be created.
 */
export const BuildingsListView = createListPage<Building>({
    title: "Manage Buildings",
    icon: <Building />,
    filterOptions: {
        sortBy: {
            label: "Sort By",
            icon: <SortBy />,
            default: BuildingSortBy.Name,
            options: createOptionsFromEnum(BuildingSortBy),
        },
    },
    footerActions: ({ history, auth }) => auth.role !== UserRole.Dispatch ? [] : [
        {
            label: "Add Building",
            onClick: () => history.push("/buildings/create"),
        },
    ],
    getUrl: ({ id }) => `/buildings/${id}`,
    useQuery: (query) => {
        const [{ fetching, error, data }] = useGetBuildingsQuery({
            variables: {
                query: {
                    search: (query.search as string) || null,
                    page: parseInt(query.page as string),
                    perPage: parseInt(query.perPage as string),
                    sortBy: query.sortBy as BuildingSortBy,
                    sortOrder: query.orderBy as SortOrder,
                },
            },
        });

        return {
            fetching,
            error,
            pageInfo: data?.buildings.pageInfo,
            items: data?.buildings.nodes,
        };
    },
    renderIcon: () => <Building />,
    renderItem(building) {
        return (
            <>
                <h3>{building.name}</h3>
                <div style={{ marginLeft: 15 }}>{building.customer.name}</div>
            </>
        );
    },
});