import { h, Fragment } from "preact";
import { RouteComponentProps } from "react-router-dom";
import { Avatar } from "../../components/Avatar";
import { Card } from "../../components/CardList";
import { DateTime } from "../../components/DateTime";
import { GrabTicketButton } from "../../components/GrabTicketButton";
import { Entrapment, ServiceRequests } from "../../components/Icons";
import { StatusTag } from "../../components/indicators/StatusTag";
import { Pagination } from "../../components/Pagination";
import { Tabs } from "../../components/Tabs";
import { formatTicketDate } from "../../components/TicketCardList";
import { TicketStatus, useGetTechEntryDataQuery } from "../../graphql/generated";
import { useSearchParams } from "../../hooks/useSearchParams";
import { ErrorView } from "../ErrorView";
import { LoaderView } from "../Loader";
import { NotFoundView } from "../NotFound";
import * as styles from "./TechnicianEntryView.module.scss";

type Props = RouteComponentProps;

/**
 * Renders the entry view for the technician.
 */
export function TechnicianEntryView({ history }: Props) {
    const [search, setSearch] = useSearchParams(history);

    const page = typeof search.page === "string" ? parseInt(search.page) : 1;

    const [{ fetching, error, data }, refetch] = useGetTechEntryDataQuery({
        variables: { page },
    });

    if (fetching) {
        return (
            <LoaderView />
        );
    }

    if (error) {
        return (
            <ErrorView
                message={error.message}
                onRetry={refetch}
            />
        );
    }

    if (!data || !data.me) {
        return (
            <NotFoundView />
        );
    }

    const tech = data.me;
    const myTickets = tech.assignedTickets;
    const pageInfo = data.tickets.pageInfo;
    const tickets = data.tickets.nodes;
    const showAll = search.showAll === "1";
    const showPagination = showAll && (pageInfo.hasNextPage || pageInfo.hasPreviousPage);
    const onTicketGrabbed = () => setSearch({ showAll: "0" });

    return (
        <>
            <header className={styles.header}>
                <div className={styles.banner} />
                <div className={styles.techInfo}>
                    <Avatar name={tech.name} src={tech.avatarUrl} size={20} />
                    <h1>{tech.name}</h1>
                    {tech.onCall && (<div>On-Call</div>)}
                </div>
            </header>
            <nav className={styles.stickyTabs}>
                <Tabs>
                    <Tabs.Tab
                        isActive={!showAll}
                        onClick={() => setSearch({ showAll: "0" })}
                    >
                        <strong>My Requests</strong> ({myTickets.length})
                    </Tabs.Tab>
                    <Tabs.Tab
                        isActive={showAll}
                        onClick={() => setSearch({ showAll: "1" })}
                    >
                        <strong>All Open Requests</strong> ({pageInfo.totalCount})
                    </Tabs.Tab>
                </Tabs>
            </nav>
            <section className={styles.content}>
                <Card.List
                    items={showAll ? tickets : myTickets}
                    onClick={ticket => history.push(`/tickets/${ticket.id}`)}
                >
                    {ticket => {
                        const { serviceLocation, createdAt, status, isEntrapment } = ticket;
                        const icon = isEntrapment ? <Entrapment color="#FF5C5D" /> : <ServiceRequests color="#717171" />;

                        return (
                            <>
                                <Card.Cell>
                                    {icon}
                                    <strong>{serviceLocation.name}</strong>
                                </Card.Cell>
                                <Card.Cell>
                                    <DateTime
                                        when={createdAt}
                                        format={formatTicketDate(createdAt)}
                                    />
                                </Card.Cell>
                                <Card.Cell>
                                    {ticket.status !== TicketStatus.Unassigned
                                        ? (<StatusTag status={status} />)
                                        : (<GrabTicketButton
                                            technicianId={tech.id}
                                            ticketId={ticket.id}
                                            onSuccess={onTicketGrabbed}
                                        />)}
                                </Card.Cell>
                            </>
                        );
                    }}
                </Card.List>

                {showPagination && (
                    <Pagination
                        {...pageInfo}
                        search={search}
                        onSearch={setSearch}
                    />
                )}

            </section>
        </>
    );
}
