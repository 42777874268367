import { h, Fragment } from "preact";
import { Link, RouteComponentProps } from "react-router-dom";
import { ConfirmationDrawer } from "../../components/ConfirmationDrawer";
import { FooterActions } from "../../components/FooterActions";
import { Hint } from "../../components/Hint";
import { Entrapment, ServiceRequests } from "../../components/Icons";
import { TicketCardList } from "../../components/TicketCardList";
import { useAuth } from "../../contexts/Auth";
import { useGetCustomerTicketOverviewQuery } from "../../graphql/generated";
import { useDisclosure } from "../../hooks/useDisclosure";
import { ErrorView } from "../ErrorView";
import { LoaderView } from "../Loader";
import { NotFoundView } from "../NotFound";
import * as styles from "./CustomerEntryView.module.scss";

type Props = RouteComponentProps<{ customerId: string }>;

const REPORT_MESSAGE = "Have you verified that someone is trapped? If you cannot verify, please select \"Cancel\" and make a service request.";

/**
 * This is effectively the "home page" view for when a customer has logged in and is
 * viewing a customer account that they have access to.
 */
export function CustomerEntryView({ match, history }: Props) {
    const auth = useAuth();
    const user = auth.user!;
    const customerId = match.params.customerId;
    const reportDrawerState = useDisclosure();

    const [{ data, error, fetching }, refetch] = useGetCustomerTicketOverviewQuery({
        variables: { id: customerId },
    });

    if (fetching) {
        return (
            <LoaderView />
        );
    }

    if (error || !data) {
        return (
            <ErrorView
                message="Failed to fetch customer information."
                onRetry={refetch}
            />
        );
    }

    if (!data.customer) {
        return (
            <NotFoundView />
        );
    }

    const { tickets, buildings } = data.customer;

    return (
        <>
            <header>
                <div className={styles.welcome}>
                    <h1>Welcome, {user.name}!</h1>
                </div>
            </header>
            <section className={styles.contents}>
                {tickets.length > 0 && (
                    <>
                        <h3>Open Service Requests</h3>
                        <TicketCardList
                            serviceRequest
                            prefix={"/acc/" + customerId}
                            tickets={tickets}
                            history={history}
                        />
                    </>
                )}

                <div className={styles.pastTicketsLink}>
                    <Link to={`/acc/${customerId}/tickets`}>
                        Closed Service Requests &rarr;
                    </Link>
                </div>
            </section>
            {buildings.length === 0 ? (
                <Hint type="warning">
                    <p>
                        There are currently no buildings related to this customer account. Please reach out
                        to our support team to add a building to your account.
                    </p>
                </Hint>
            ) : (
                <>
                    <FooterActions
                        actions={[
                            {
                                icon: ServiceRequests as any,
                                label: "Request Service",
                                onClick: () => history.push(`/acc/${customerId}/tickets/create`),
                            },
                            {
                                icon: Entrapment as any,
                                label: "Report Entrapment",
                                dangerous: true,
                                outlined: true,
                                onClick: reportDrawerState.onOpen,
                            },
                        ]}
                    />
                    <ConfirmationDrawer
                        label="Report Entrapment"
                        message={REPORT_MESSAGE}
                        confirmLabel="Yes, Please Help"
                        danger
                        isOpen={reportDrawerState.isOpen}
                        onClose={reportDrawerState.onClose}
                        onConfirm={() => history.push(`/acc/${customerId}/tickets/create/entrapment`)}
                    />
                </>
            )}
        </>
    );
}