import { h, Fragment } from "preact";
import { UseFormRegister } from "react-hook-form";
// import { AddressInput } from "../../graphql/generated";
import { TextField } from "./TextField";
// import * as styles from "./AddressFields.module.scss";

type Props<T> = {
    register: UseFormRegister<any>;
    required?: boolean;
};

/**
 * Renders the address fields for a form.
 */
export function AddressFields<T>({
    register,
    required,
}: Props<T>) {
    return (
        <>
            <TextField
                register={register}
                type="text"
                name="address.street"
                label="Street"
                required={required}
            />
            <TextField
                register={register}
                type="text"
                name="address.city"
                label="City"
                required={required}
            />
            <TextField
                register={register}
                type="text"
                name="address.state"
                label="State"
                required={required}
            />
            <TextField
                register={register}
                type="text"
                name="address.zipcode"
                label="Zipcode"
                placeholder="##### or #####-####"
                pattern={/^\d{5}([-]?\d{4})?$/}
                required={required}
            />
        </>
    );
}