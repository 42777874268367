.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.onRetry {
    cursor: pointer;
}
