import { ComponentChildren, ComponentType, h } from "preact";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import * as styles from "./Tabs.module.scss";

type Props = {
    /** The tabs to render. */
    children?: ComponentChildren
};

/**
 * Renders a set of navigation links as a row of tabs.
 */
export function Tabs({ children }: Props) {
    return (
        <div className={styles.tabs}>
            <div className={styles.container}>
                {children}
            </div>
        </div>
    );
}

export namespace Tabs {

    export type TabProps = {
        /** The content to render for the tab. */
        children: ComponentChildren;
    } & (
            | {
                /** The URL for the tab, if it's a link style tab. */
                url: string;
                /** Whether or not an exact path match is necessary. */
                exact?: boolean;
                /** The event that will be called when the tab is clicked, if it's a button tab. */
                onClick?: never;
                /** The active state of the tab, if it's a button tab. */
                isActive?: never;
            }
            | {
                /** The URL for the tab, if it's a link style tab. */
                url?: never
                /** Whether or not an exact path match is necessary. */
                exact?: never;
                /** The event that will be called when the tab is clicked, if it's a button tab. */
                onClick(): void;
                /** The active state of the tab, if it's a button tab. */
                isActive?: boolean;
            }
        );

    /**
     * Renders a single tab as either a button or a link within a group of tabs.
     */
    export function Tab({ children, url, exact, onClick, isActive }: TabProps) {
        if (url) {
            return (
                <NavLink
                    to={url}
                    className={styles.tab}
                    activeClassName={styles.isActive}
                    exact={exact}
                >
                    {children}
                </NavLink>
            );
        }

        const classes = cx(styles.tab, {
            [styles.isActive]: isActive,
        });

        return (
            <div
                className={classes}
                onClick={onClick}
            >
                {children}
            </div>
        );
    }

    export type SwitchProps<T> = {
        /** Props to pass to each tabbed item. */
        props: T;
        /** The tab content to render for this switch. */
        children: {
            /** The label to render for the tab. */
            label: ComponentChildren;
            /** The component or function to render when the tab is active. */
            component: ComponentType<T>;
        }[];
    };

}