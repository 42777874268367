import { h, Fragment } from "preact";
import { TextField } from "../../components/controls/TextField";
import { UserRefField } from "../../components/controls/UserRefField";
import { createFormPage } from "../../factories/createFormPage";
import { useUpdateServiceZoneMutation, useGetServiceZoneQuery, UpdateServiceZoneInput, UpdateServiceZoneMutation, GetServiceZoneQuery, UpdateServiceZoneMutationVariables, UserRole } from "../../graphql/generated";

type Fields = UpdateServiceZoneInput;
type RouteParams = { id: string };
type MutRes = UpdateServiceZoneMutation;
type MutVar = UpdateServiceZoneMutationVariables;
type Query = GetServiceZoneQuery;

/**
 * Renders the form view for updating a service zone.
 */
export const UpdateServiceZoneFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Edit Service Zone",
    useDefaultValues: ({ data }) => {
        const sz = data!.serviceZone!;

        return {
            id: sz.id,
            name: sz.name,
            supervisorId: sz.supervisor.id,
        };
    },
    useQuery: ({ id }) => useGetServiceZoneQuery({ variables: { id } }),
    useMutation: useUpdateServiceZoneMutation,
    onSuccess: (res, { history }) => history.replace(`/serviceZones/${res.serviceZone.id}`),
    onSuccessMessage: res => `Successfully updated service zone "${res.serviceZone.name}".`,
    render({ register, control, data }) {
        const { supervisor } = data!.serviceZone!;

        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Name"
                    required
                />

                <UserRefField
                    role={[UserRole.Dispatch]}
                    placeholder="Start typing to search dispatchers"
                    name="supervisorId"
                    label="Supervisor"
                    data={supervisor}
                    control={control}
                    required
                />
            </>
        );
    },
});