import { h, Fragment } from "preact";
import { AddressFields } from "../../components/controls/AddressFields";
import { ServiceZoneRefField } from "../../components/controls/ServiceZoneRefField";
import { TextField } from "../../components/controls/TextField";
import { UserRefField } from "../../components/controls/UserRefField";
import { createFormPage } from "../../factories/createFormPage";
import { useUpdateBuildingMutation, useGetBuildingQuery, UpdateBuildingInput, UpdateBuildingMutation, GetBuildingQuery, UpdateBuildingMutationVariables, UserRole } from "../../graphql/generated";

type Fields = Omit<UpdateBuildingInput, "id">;
type RouteParams = { id: string };
type MutRes = UpdateBuildingMutation;
type MutVar = UpdateBuildingMutationVariables;
type Query = GetBuildingQuery;

/**
 * Renders the form view for updating a building.
 */
export const UpdateBuildingFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Edit Building",
    useDefaultValues: ({ data }) => {
        const b = data!.building!;
        const tech = b.technician;

        return {
            id: b.id,
            name: b.name,
            email: b.email,
            phoneNumber: b.phoneNumber,
            serviceZoneId: b.serviceZone.id,
            technicianId: tech ? tech.id : "",
            buildingAccessNotesUrl: "",
            address: {
                street: b.address.street,
                city: b.address.city,
                state: b.address.state,
                zipcode: b.address.zipcode,
                countryCode: b.address.countryCode,
            },
        };
    },
    useQuery: ({ id }) => useGetBuildingQuery({ variables: { id } }),
    useMutation: useUpdateBuildingMutation,
    onSuccess: (res, { history }) => history.replace(`/buildings/${res.building.id}`),
    onSuccessMessage: res => `Successfully updated building "${res.building.name}".`,
    render({ data, register, control }) {
        const { serviceZone, technician } = data!.building!;

        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Building Name"
                    required
                />

                {/* <TextField
                    register={register}
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    label="Building Email"
                />

                <PhoneNumberField
                    register={register}
                    name="phoneNumber"
                    label="Building Phone Number"
                /> */}

                <UserRefField
                    placeholder="Start typing to search"
                    control={control}
                    name="technicianId"
                    label="Assigned Technician"
                    role={[UserRole.Technician]}
                    data={technician}
                    required
                />

                <ServiceZoneRefField
                    placeholder="Start typing to search"
                    label="Service Zone"
                    name="serviceZoneId"
                    data={serviceZone}
                    control={control}
                    required
                />

                <TextField
                    register={register}
                    type="text"
                    name="buildingAccessNotesUrl"
                    placeholder="https://"
                    label="Building Access Instructions URL"
                />

                <AddressFields
                    register={register}
                    required
                />
            </>
        );
    },
});