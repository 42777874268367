import { h } from "preact";
import { useLoginMutation } from "../../graphql/generated";
import { useForm } from "react-hook-form";
import { Link, RouteComponentProps } from "react-router-dom";
import { TextField } from "../../components/controls/TextField";
import { Button } from "../../components/controls/Button";
import { TOKEN_KEY } from "../../constants";
import { useToast } from "../../contexts/Toast";
import { useAuth } from "../../contexts/Auth";
import * as styles from "./Login.module.scss";

type QueryParams = {
    /** When present, tells the view where to redirect to on successful login. */
    redirectTo?: string;
};

type FormValues = {
    /** The email value entered by the user. */
    email: string;
    /** The password value entered by the user. */
    password: string;
};

/**
 * Renders the form that handles input and the API request to login a user.
 */
export function LoginView({ history, location }: RouteComponentProps<QueryParams>) {
    const toast = useToast();
    const auth = useAuth();
    const [loginResult, login] = useLoginMutation();
    const query = new URLSearchParams(location.search);
    const email = query.get("email");

    const { register, handleSubmit, formState: { isValid } } = useForm<FormValues>({
        mode: "onChange",
        defaultValues: {
            email: typeof email === "string" ? email : "",
            password: "",
        },
    });
    const loading = loginResult.fetching;

    const onSubmit = handleSubmit(async (formValues) => {
        const { error, data } = await login(formValues);

        if (error) {
            toast({
                type: "error",
                message: "Failed to login using the provided credentials. Please try again.",
            });
            return;
        }

        // set the token in local storage
        localStorage.setItem(TOKEN_KEY, data!.token);

        // refresh the stored authentication data
        await auth.refresh();

        // redirect the user to where they were originally trying to get to
        const query = new URLSearchParams(location.search);
        history.replace(query.get("redirectTo") ?? "/");
    });

    return (

        <form className={styles.form} onSubmit={onSubmit}>
            <p className={styles.instructions}>Enter email address and password below.</p>

            <TextField
                register={register}
                name="email"
                label="Email Address"
                type="email"
                required
                placeholder="name@example.com"
            />
            <TextField
                register={register}
                name="password"
                label="Password"
                type="password"
                required
            />
            <Button
                type="submit"
                disabled={!isValid}
                loading={loading}
                label="Log In"
                fullWidth
                className="submit"
            />
            <Link to="/forgot-password">Forgot password?</Link>
            {/* <Link to="/register">Create Account</Link> */}
        </form>
    );
}