.field {
    position: relative;

    &:not(.required) {
        > label {
            &:after {
                display: inline-block;
                padding-left: 15px;
                font-size: 0.875em;
                opacity: 0.7;
                content: "(Optional)";
            }
        }
    }

    &.fullWidth {
        width: 100%;
    }

    > input {
        padding-right: 50px;
        z-index: 1;
    }

    > svg {
        position: absolute;
        top: 50%;
        right: 0;
        opacity: 0.5;
        transform: translate(-50%, -50%);
        z-index: 10;
    }

    &.showResults {
        z-index: 75;

        > .overlay {
            display: block;
        }

        > .results {
            pointer-events: all;
            transform: translateY(0px);
            opacity: 1;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
}

.results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    pointer-events: none;
    transition: opacity 100ms ease, transform 150ms ease;
    transform: translateY(-10px);
    opacity: 0;
    overflow: auto;
    z-index: 10;
}

.result {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;

    & + & {
        border-top: solid 1px #ccc;
    }

    &:hover,
    &.isSelected {
        background: rgba(0, 0, 0, 0.05);
    }
}
