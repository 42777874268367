import { ComponentChildren, h } from "preact";
import * as styles from './GuestLayout.module.scss';
import logo from '../img/equick-logo.svg';

type Props = {
    children: ComponentChildren;

}
export function GuestLayout({ children }: Props) {
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <img className={styles.logo} src={logo} alt="EQuick Logo" />
                <h1 className={styles.heading}>EQUICK</h1>
                <h2 className={styles.subHeading}>Service Request</h2>
                {children}
            </div>
        </div>
    )
}