import { h, Fragment } from "preact";
import { SpecializedTechRefField } from "../../components/controls/SpecializedTechRefField";
import { Hint } from "../../components/Hint";
import { createFormPage } from "../../factories/createFormPage";
import { useAssignTicketMutation, AssignTicketMutation, GetTicketMinimalQuery, AssignTicketMutationVariables, useGetTicketMinimalQuery, TicketStatus } from "../../graphql/generated";

type Fields = AssignTicketMutationVariables;
type RouteParams = { id: string };
type MutRes = AssignTicketMutation;
type MutVar = AssignTicketMutationVariables;
type Query = GetTicketMinimalQuery;

/**
 * Renders the form view for closing a ticket.
 */
export const AssignTicketFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Assign Service Request",
    useDefaultValues: ({ data }) => ({
        ticketId: data!.ticket!.id,
        technicianId: "",
    }),
    showNotFound: (data) => !data || !data.ticket || data.ticket.status === TicketStatus.Closed,
    useQuery: ({ id }) => useGetTicketMinimalQuery({ variables: { id } }),
    toVars: ({ ticketId, technicianId }) => ({ ticketId, technicianId }),
    useMutation: useAssignTicketMutation,
    onSuccess: (res, { history }) => history.replace(`/tickets/${res.ticket.id}`),
    onSuccessMessage: res => `Successfully updated service request: ${res.ticket.id}`,
    render({ data, control }) {
        const ticket = data!.ticket!;

        return (
            <>
                <SpecializedTechRefField
                    name="technicianId"
                    label="Technician"
                    control={control}
                    required
                >
                    {tech => {
                        let warnings = [];

                        if (tech.unavailable) {
                            warnings.push(
                                <li key="unavailable">
                                    This technician is currently marked as being "unavailable" and may not be able to handle
                                    this service request in a desirable time frame.
                                </li>
                            );
                        }

                        if (!tech.isVaccinated && ticket.requiresVaccination) {
                            warnings.push(
                                <li key="vax">
                                    This technician is not marked as having been vaccinated against COVID-19, but the service
                                    request has explicitly noted that vaccinations are required.
                                </li>
                            );
                        }

                        return warnings.length > 0
                            ? (
                                <Hint type="warning">
                                    <h3>Warning</h3>
                                    <ul>{warnings}</ul>
                                </Hint>
                            )
                            : null;
                    }}
                </SpecializedTechRefField>
            </>
        );
    },
});