import { useCallback, useState } from "preact/hooks";

/**
 * Utility hook for performing common open/close behaviours for modals, tooltips, etc.
 */
export function useDisclosure(isOpenDefault = false) {
    const [isOpen, setIsOpen] = useState(isOpenDefault);

    const onOpen = useCallback(() => setIsOpen(true), []);
    const onClose = useCallback(() => setIsOpen(false), []);
    const toggle = useCallback(() => setIsOpen(state => !state), []);

    return { isOpen, onOpen, onClose, toggle };
}