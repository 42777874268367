import { h } from "preact";
import { useForm } from "react-hook-form";
import { Button } from "../components/controls/Button";
import { TextField } from "../components/controls/TextField";
import { useAuth } from "../contexts/Auth";
import { useToast } from "../contexts/Toast";
import { CommentableType, CreateCommentInput, CreateCommentMutation, useCreateCommentMutation, UserRole } from "../graphql/generated";
import cx from "classnames";
import * as styles from "./CommentsView.module.scss";
import { useState } from "preact/hooks";

type FormValues = Pick<CreateCommentInput, "content" | "isPublic">;

export type Props = {
    /** Type of the comment subject. */
    type: CommentableType;
    /** ID of the comment subject/ */
    id: string;
    /** Event that is called after a comment is successfully created. */
    onSuccess(data: CreateCommentMutation["comment"]): void;
};

/**
 * Form for creating new comments. This is a subview of the larger comments view but has
 * been split off to avoid re-renders of the whole view due to potential form changes.
 */
export function CreateCommentSubView({ type, id, onSuccess }: Props) {
    const toast = useToast();
    const { user } = useAuth();
    const [result, createComment] = useCreateCommentMutation();
    const { register, handleSubmit, reset, formState: { isValid }, setValue, getValues } = useForm<FormValues>({ 
        mode: "onChange",
    });
    const [isPublic, setIsPublic] = useState(true);
    const loading = result.fetching;

    const onSubmit = handleSubmit(async ({ content }) => {
        const { error, data } = await createComment({
            input: {
                subjectId: id,
                subjectType: type,
                isPublic,
                content,
            },
        });

        if (error) {
            toast({
                type: "error",
                message: error.message,
            });
            return;
        }

        reset();

        toast({
            type: "success",
            message: "Successfully added comment.",
        });

        onSuccess(data!.comment);
    });

    const classes = cx(styles.form, {
        [styles.isInternal]: !isPublic,
    });

    return (

        <form className={classes} onSubmit={onSubmit}>
            <div className={styles.formContent}>
                { user!.role !== UserRole.Customer && (
                    <div className={styles.modeControls}>
                        <div className={styles.externalControl} onClick={() => setIsPublic(true)}>Customer</div>
                        <div className={styles.internalControl} onClick={() => setIsPublic(false)}>Internal</div>
                    </div>
                )}
                <TextField
                    register={register}
                    type="multiline"
                    name="content"
                    label="Message"
                    required
                />

                <Button
                    color="primary"
                    type="submit"
                    disabled={!isValid}
                    loading={loading}
                    label="Submit"
                    fullWidth
                />
            </div>
        </form>
    );
}