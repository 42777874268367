import { h, Fragment } from "preact";
import { Entrapment, ServiceRequests, SortBy } from "../../components/Icons";
import { createListPage, createOptionsFromEnum } from "../../factories/createListPage";
import { GetTicketsQuery, TicketSortBy, SortOrder, useGetTicketsQuery, TicketStatus, TicketPriority } from "../../graphql/generated";
import { TicketPriorityIcon } from "../../components/indicators/TicketPriorityIcon";
import { DateTime } from "../../components/DateTime";
import { Card } from "../../components/CardList";

type Ticket = GetTicketsQuery["tickets"]["nodes"][0];


/**
 * Renders the full list of service request tickets.
 */
export const TicketsListView = createListPage<Ticket>({
    title: "Service Requests",
    icon: <ServiceRequests />,
    filterOptions: {
        status: {
            label: "Status",
            multiselect: true,
            options: createOptionsFromEnum(TicketStatus),
        },
        priority: {
            label: "Priority",
            multiselect: true,
            options: createOptionsFromEnum(TicketPriority),
        },
        sortBy: {
            label: "Sort By",
            icon: <SortBy />,
            default: TicketSortBy.CreatedAt,
            options: createOptionsFromEnum(TicketSortBy),
        },
    },
    footerActions: ({ history }) => [
        {
            icon: ServiceRequests as any,
            label: "Request Service",
            onClick: () => history.push("/tickets/create"),
        },
        {
            icon: Entrapment as any,
            label: "Report Entrapment",
            outlined: true,
            dangerous: true,
            onClick: () => history.push("/tickets/create/entrapment"),
        },
    ],
    getUrl: ({ id }) => `/tickets/${id}`,
    useQuery: (query) => {
        const [{ fetching, error, data }] = useGetTicketsQuery({
            variables: {
                query: {
                    status: (query.status as TicketStatus[]) || null,
                    priority: (query.priority as TicketPriority[]) || null,
                    search: (query.search as string) || null,
                    page: parseInt(query.page as string),
                    perPage: parseInt(query.perPage as string),
                    sortBy: query.sortBy as TicketSortBy,
                    sortOrder: query.orderBy as SortOrder,
                },
            },
        });

        return {
            fetching,
            error,
            pageInfo: data?.tickets.pageInfo,
            items: data?.tickets.nodes,
        };
    },
    renderItem(ticket) {
        const { priority, createdAt, building, technician } = ticket;

        return (
            <>
                <Card.Cell>
                    <TicketPriorityIcon priority={priority} />
                    <strong>{building.name}</strong>
                </Card.Cell>
                <Card.Cell>
                    {technician ? technician.name : "Unassigned"}
                </Card.Cell>
                <Card.Cell>
                    <DateTime when={createdAt} format={"Pp"} />
                </Card.Cell>
            </>
        );
    },
});