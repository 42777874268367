import { h, Fragment } from "preact";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { CreateScheduleEventInput, CreateScheduleEventMutation, CreateScheduleEventMutationVariables, ScheduleEventType, useCreateScheduleEventMutation, UserRole } from "../../graphql/generated";
import { SelectField } from "../../components/controls/SelectField";
import { UserRefField } from "../../components/controls/UserRefField";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import pickBy from "lodash/pickBy";
import { ServiceZoneRefField } from "../../components/controls/ServiceZoneRefField";
import * as styles from "./CreateScheduleEventFormView.module.scss";

type Fields = Omit<CreateScheduleEventInput, "startsAt" | "endsAt" | "priority"> & {
    priority: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
};

type RouteParams = {};
type MutRes = CreateScheduleEventMutation;
type MutVar = CreateScheduleEventMutationVariables;

export const TYPE_OPTIONS = {
    [ScheduleEventType.OfficesClosed]: "Office Closure",
    [ScheduleEventType.TechOncall]: "On-Call Technician",
    [ScheduleEventType.TechUnavailable]: "Technician Unavailable",
};

export const PRIORITY_OPTIONS = {
    "0": "Primary",
    "1": "Backup",
};

/**
 * Renders the form view for adding a new schedule event.
 */
export const CreateScheduleEventFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, null>({
    title: () => "Add Schedule Event",
    useDefaultValues: () => {
        const startsAt = new Date();
        const endsAt = addDays(startsAt, 1);

        return {
            name: "",
            type: ScheduleEventType.OfficesClosed,
            startDate: format(startsAt, "yyyy-MM-dd"),
            endDate: format(endsAt, "yyyy-MM-dd"),
            startTime: "00:00",
            endTime: "23:59",
            priority: "0",
            serviceZoneId: "",
            technicianId: "",
        };
    },
    toVars: ({ startDate, startTime, endDate, endTime, priority, ...fields }) => {
        const startsAt = new Date(`${startDate} ${startTime}`);
        const endsAt = new Date(`${endDate} ${endTime}`);

        return {
            input: {
                ...pickBy(fields, x => !!x) as Fields,
                priority: priority ? parseInt(priority as string) : null,
                startsAt: startsAt.toISOString(),
                endsAt: endsAt.toISOString(),
            },
        };
    },
    useMutation: useCreateScheduleEventMutation,
    onSuccess: (res, { history }) => history.replace(`/schedule`),
    onSuccessMessage: res => `Successfully created new schedule event.`,
    render({ register, control, watch }) {
        const [type, startDate] = watch(["type", "startDate"]);

        return (
            <>
                <SelectField
                    register={register}
                    name="type"
                    label="Event Type"
                    options={TYPE_OPTIONS}
                />
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Event Name"
                />
                <div className="hbox">
                    <TextField
                        register={register}
                        label="Start Date"
                        name="startDate"
                        type="date"
                        required
                    />

                    <div className={styles.toText}>TO</div>

                    <TextField
                        register={register}
                        label="End Date"
                        name="endDate"
                        type="date"
                        required
                        min={startDate}
                    />
                </div>

                <div className="hbox">
                    <TextField
                        register={register}
                        label="Start Time"
                        name="startTime"
                        type="time"
                        required
                    />

                    <div className={styles.toText}>TO</div>

                    <TextField
                        register={register}
                        label="End Time"
                        name="endTime"
                        type="time"
                        required
                    />
                </div>

                {type !== ScheduleEventType.OfficesClosed && (
                    <UserRefField
                        placeholder="Start typing to search technicians"
                        control={control}
                        name="technicianId"
                        label="Technician"
                        role={[UserRole.Technician]}
                        required
                    />
                )}

                {type === ScheduleEventType.TechOncall && (
                    <>
                        <ServiceZoneRefField
                            placeholder="Start typing to search service zones"
                            control={control}
                            name="serviceZoneId"
                            label="Service Zone"
                            required
                        />
                        <SelectField
                            register={register}
                            name="priority"
                            label="Priority"
                            options={PRIORITY_OPTIONS}
                            required
                        />
                    </>
                )}
            </>
        );
    },
});