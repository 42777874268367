import { h, ComponentChildren } from "preact";
import { memo } from "preact/compat";
import { useToast } from "../contexts/Toast";
import { TicketStatus, useAssignTicketMutation } from "../graphql/generated";
import { Spinner } from "./indicators/Spinner";
import * as styles from "./GrabTicketButton.module.scss";

type Props = {
    /** ID of the ticket that we're trying to assign to ourselves. */
    ticketId: string;
    /** ID of the current technician user. */
    technicianId: string;
    /** When true, the button will be disabled. */
    disabled?: boolean;
    /** Event that is called when the button action succeeds. */
    onSuccess?(data: { id: string, status: TicketStatus }): void;
    /** Content that will be rendered in the button. */
    children?: ComponentChildren;
};

/**
 * Renders a button that will attempt to assign the current ticket to the current
 * user, provided that the current user is a technician. The user's role will NOT
 * be checked by this component and should be checked before rendering.
 */
export const GrabTicketButton = memo(({ ticketId, technicianId, disabled, onSuccess, children }: Props) => {
    const toast = useToast();
    const [{ fetching }, assignTicket] = useAssignTicketMutation();

    const onClick: h.JSX.MouseEventHandler<HTMLButtonElement> = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        const { error, data } = await assignTicket({ ticketId, technicianId });

        if (error) {
            toast({ type: "error", message: error.message });
            return;
        }

        toast({ type: "success", message: `Service request ${ticketId} is now assigned to you.` });

        if (onSuccess) {
            onSuccess(data!.ticket);
        }
    };

    const content = children ?? "Grab It";

    return (
        <button
            className={styles.button}
            onClick={onClick}
            disabled={fetching || disabled}
        >
            {fetching ? <Spinner size={12} /> : content}
        </button>
    );
});