import { h, Fragment } from "preact";
import { Checkbox } from "../../components/controls/Checkbox";
import { ServiceLocationRefField } from "../../components/controls/ServiceLocationRefField";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { CreateTicketInput, CreateTicketMutation, CreateTicketMutationVariables, TicketPriority, useCreateTicketMutation } from "../../graphql/generated";

type Fields = Omit<CreateTicketInput, "requireTechnicianTags"> & { tags: string[] };
type RouteParams = {};
type MutRes = CreateTicketMutation;
type MutVar = CreateTicketMutationVariables;

/**
 * Renders the form view for updating a building.
 */
export const ReportEntrapmentFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, null>({
    title: () => "Report Entrapment",
    useDefaultValues: ({ data }) => {
        return {
            serviceLocationId: "",
            notes: "",
            priority: TicketPriority.Entrapment,
            tags: [],
            overtimeAllowed: false,
        };
    },
    toVars: ({ tags, ...fields }: Fields) => ({ input: { ...fields, requireTechnicianTags: tags![0] ? tags : [], overtimeAllowed: !!fields.overtimeAllowed } }),
    useMutation: useCreateTicketMutation,
    onSuccess: (res, { history }) => history.replace(`/tickets/${res.ticket.id}`),
    onSuccessMessage: res => `Entrapment was successfully reported.`,
    render({ data, register, control }) {
        return (
            <>
                <ServiceLocationRefField
                    name="serviceLocationId"
                    control={control}
                    required
                />

                <TextField
                    register={register}
                    type="multiline"
                    name="notes"
                    label="Request Details"
                />

                <Checkbox
                    register={register}
                    name="tags.0"
                    label="Vaccination Required"
                    value="COVID19_VAX"
                />

                <Checkbox
                    register={register}
                    name="overtimeAllowed"
                    label="Overtime Allowed"
                    value="allowed"
                />
            </>
        );
    },
});