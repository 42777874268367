import { ComponentChildren, h } from "preact";
import { TicketStatus } from "../../graphql/generated";
import * as styles from "./StatusTag.module.scss";
import cx from "classnames";

type Props = {
  /** The status of the ticket. */
  status: TicketStatus;
}

export function getStatusLabel(status: TicketStatus) {
    switch (status) {
        case "UNASSIGNED":
            return "Unassigned";
        case "ASSIGNED":
            return "Call Grabbed";
        case "EN_ROUTE":
            return "En Route";
        case "ON_SITE":
            return "On Site";
        case "PAUSED":
            return "On Pause";
        case "CLOSED":
            return "Complete";
    }
}

/**
 * Renders a tag with status information about the service request.
 */
export function StatusTag({ status }: Props) {
    const classes = cx(`${styles.tag} ${styles[status.replace("_", "").toLowerCase()]}`);

    return (
        <div className={classes}>{getStatusLabel(status)}</div>
    );
}