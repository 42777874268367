import { ComponentChildren, Fragment, h } from "preact";
import { createPortal } from "preact/compat";
import { useEffect, useRef, useState } from "preact/hooks";

type Props = {
    /** The children that will be mounted into the portal's new div element. */
    children?: ComponentChildren;
};

/**
 * This component creates a new div and portals its children to that div on render. The same
 * div will be cleaned up automatically when the portal component instance is removed.
 *
 * This component mounts ONLY in the client and not on the server.
 */
export function Portal({ children }: Props) {
    const ref = useRef<HTMLDivElement>();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        const el = document.createElement("div");
        document.body.appendChild(el);
        ref.current = el;

        setMounted(true);

        return () => {
            document.body.removeChild(el);
        };
    }, []);

    return mounted ? createPortal(<>{children}</>, ref.current!) : null;
}