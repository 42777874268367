import { UseQueryResponse } from "@urql/preact";
import { ComponentType, h } from "preact";
import { RouteComponentProps } from "react-router-dom";
import { useDisclosure } from "../hooks/useDisclosure";
import { ErrorView } from "../views/ErrorView";
import { LoaderView } from "../views/Loader";
import { NotFoundView } from "../views/NotFound";
import { Drawer } from "../components/Drawer";
import { OverflowMenu } from "../components/Icons";
import { PageHeader } from "../components/PageHeader";
import { AuthUser, useAuth } from "../contexts/Auth";
import * as styles from "./createOverviewPage.module.scss";

type EntityViewSubComponent<Params, Data> = ComponentType<{
    data: Data;
    route: RouteComponentProps<Params>;
    auth: AuthUser;
    refetch(): void;
}>;

type CreateOverviewPageProps<Params, Data> = {
    /** The (wrapped) query hook that will be performed on view load. */
    useQuery(params: Params, props: RouteComponentProps<Params>): UseQueryResponse<Data>;
    /** When present, allows a custom function to determine if a NotFound view should be displayed. */
    showNotFound?(data: Data): boolean;
    /** Provides the page header's title from the entity data. */
    title(data: Data): string;
    /** Provides the sub title that can optionally be used in the page's header. */
    subTitle?(data: Data): string;
    /** Sub-component for rendering actions that can be taken for the entity / page. */
    actions?(props: {
        data: Data;
        route: RouteComponentProps<Params>;
        auth: AuthUser;
        refetch(): void;
        close(): void;
    }): null | JSX.Element | JSX.Element[];
    /** Sub-component for displaying additional information in a drawer for the entity / page. */
    info?: EntityViewSubComponent<Params, Data>;
    /** Sub-component for displaying the primary contents of the page. */
    contents: EntityViewSubComponent<Params, Data>;
};

/**
 * Factory function for creating entity overview components.
 */
export function createOverviewPage<Params, Data>({
    useQuery,
    showNotFound,
    title,
    subTitle,
    actions: renderActions,
    info: InfoComponent,
    contents: ContentsComponent,
}: CreateOverviewPageProps<Params, Data>) {
    return function (props: RouteComponentProps<Params>) {
        const params = props.match.params;
        const [{ fetching, error, data }, refetch] = useQuery(params, props);

        const auth = useAuth();
        const actionsDrawerState = useDisclosure();
        const infoDrawerState = useDisclosure();

        if (fetching) {
            return (
                <LoaderView />
            );
        }

        if (error) {
            return (
                <ErrorView
                    message={error.message}
                    onRetry={refetch}
                />
            );
        }

        if (!data || (showNotFound && showNotFound(data))) {
            return (
                <NotFoundView />
            );
        }

        let actionsButton;
        let actions = !renderActions ? null : renderActions({
            data: data,
            route: props,
            auth: auth.user!,
            close: actionsDrawerState.onClose,
            refetch: refetch,
        });

        if (actions) {
            actionsButton = (
                <PageHeader.IconAction
                    icon={<OverflowMenu />}
                    onClick={actionsDrawerState.onOpen}
                />
            );

            actions = (
                <Drawer
                    scrollable
                    open={actionsDrawerState.isOpen}
                    onClose={actionsDrawerState.onClose}
                >
                    <Drawer.Heading label="Actions" />
                    {actions}
                </Drawer>
            );
        }

        let onTitleClick;
        let infoDrawer: null | JSX.Element = null;

        if (InfoComponent) {
            onTitleClick = infoDrawerState.onOpen;
            infoDrawer = (
                <Drawer
                    scrollable
                    open={infoDrawerState.isOpen}
                    onClose={infoDrawerState.onClose}
                >
                    <Drawer.Heading label="Information" />
                    <Drawer.Content className={styles.info}>
                        <InfoComponent
                            refetch={refetch}
                            data={data}
                            route={props}
                            auth={auth.user!}
                        />
                    </Drawer.Content>
                </Drawer>
            );
        }

        return (
            <div>
                <PageHeader>
                    <PageHeader.Row>
                        <PageHeader.Title
                            title={title(data)}
                            subTitle={subTitle ? subTitle(data) : undefined}
                            onClick={onTitleClick}
                        />
                        {actionsButton}
                    </PageHeader.Row>
                </PageHeader>
                <section className={styles.items}>
                    <ContentsComponent
                        refetch={refetch}
                        data={data}
                        route={props}
                        auth={auth.user!}
                    />
                </section>
                {actions}
                {infoDrawer}
            </div>
        );
    };
}