import { h } from "preact";
import { createEntityRefField } from "../../factories/createEntityRefField";
import { SearchServiceZonesDocument, SearchServiceZonesQuery, SearchServiceZonesQueryVariables } from "../../graphql/generated";

type ServiceZone = SearchServiceZonesQuery["serviceZones"]["nodes"][0];

export const ServiceZoneRefField = createEntityRefField<ServiceZone>({
    // async fetchItem(id, { query }) {
    //     const { error, data } = await query<GetServiceZoneMinimalQuery, GetServiceZoneMinimalQueryVariables>(GetServiceZoneMinimalDocument, { id }).toPromise();
    //     return !error && data ? data.serviceZone : null;
    // },
    async searchItems(search, { query }) {
        const { error, data } = await query<SearchServiceZonesQuery, SearchServiceZonesQueryVariables>(SearchServiceZonesDocument, { search }).toPromise();
        return !error && data ? data.serviceZones.nodes : [];
    },
    render(serviceZone) {
        return (
            <span>{serviceZone!.name}</span>
        );
    },
});