import { h, Fragment } from "preact";
import { TextField } from "../../components/controls/TextField";
import { createFormPage } from "../../factories/createFormPage";
import { CreateCustomerInput, CreateCustomerMutation, CreateCustomerMutationVariables, useCreateCustomerMutation } from "../../graphql/generated";

type Fields = CreateCustomerInput;
type RouteParams = {};
type MutRes = CreateCustomerMutation;
type MutVar = CreateCustomerMutationVariables;

/**
 * Renders the form view for adding a new customer.
 */
export const CreateCustomerFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, null>({
    title: () => "Add Customer",
    useDefaultValues: () => ({
        name: "",
        email: "",
        phoneNumber: "",
    }),
    useMutation: useCreateCustomerMutation,
    onSuccess: (res, { history }) => history.replace(`/customers/${res.customer.id}`),
    onSuccessMessage: res => `Successfully created new customer account "${res.customer.name}".`,
    render({ register }) {
        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Company / Customer Name"
                    required
                />
                {/* <TextField
                    register={register}
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    label="Company Email"
                />
                <PhoneNumberField
                    register={register}
                    name="phoneNumber"
                    label="Company Phone Number"
                /> */}
            </>
        );
    },
});