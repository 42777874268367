import { h, Fragment } from "preact";
import { Avatar } from "../../components/Avatar";
import { DateTime } from "../../components/DateTime";
import { Calendar, SortBy } from "../../components/Icons";
import { createListPage, createOptionsFromEnum } from "../../factories/createListPage";
import { ScheduleEventSortBy, GetScheduleEventsQuery, SortOrder, useGetScheduleEventsQuery, ScheduleEventType, ScheduleEventState, UserRole } from "../../graphql/generated";
import * as styles from "./ScheduleEventListView.module.scss";
import closedImage from "../../img/Closed.png";

type ScheduleEvent = GetScheduleEventsQuery["scheduleEvents"]["nodes"][0];

/**
 * Renders the full list of schedule events and allows for new scheduleEvent accounts to be created.
 */
export const ScheduleEventsListView = createListPage<ScheduleEvent>({
    title: "Manage Schedule",
    icon: <Calendar />,
    filterOptions: {
        state: {
            label: "Filter by Relevancy",
            multiselect: true,
            options: createOptionsFromEnum(ScheduleEventState),
        },
        type: {
            label: "Filter by Type",
            multiselect: true,
            options: createOptionsFromEnum(ScheduleEventType),
        },
        sortBy: {
            label: "Sort By",
            icon: <SortBy />,
            default: ScheduleEventSortBy.StartsAt,
            options: createOptionsFromEnum(ScheduleEventSortBy),
        },
    },
    footerActions: ({ history, auth }) => auth.role !== UserRole.Dispatch ? [] : [
        {
            label: "Add Schedule Event",
            onClick: () => history.push("/schedule/create"),
        },
    ],
    getUrl: ({ id }) => `/schedule/${id}`,
    useQuery: (query) => {
        const [{ fetching, error, data }] = useGetScheduleEventsQuery({
            variables: {
                query: {
                    type: (query.type as ScheduleEventType[]) || null,
                    state: (query.state as ScheduleEventState[]) || null,
                    search: (query.search as string) || null,
                    page: parseInt(query.page as string),
                    perPage: parseInt(query.perPage as string),
                    sortBy: query.sortBy as ScheduleEventSortBy,
                    sortOrder: query.orderBy as SortOrder,
                },
            },
        });

        return {
            fetching,
            error,
            pageInfo: data?.scheduleEvents.pageInfo,
            items: data?.scheduleEvents.nodes,
        };
    },
    renderIcon(ev) {
        switch (ev.__typename) {
            case "TechOnCallScheduleEvent":
            case "TechUnavailableScheduleEvent":
                return (<Avatar name={ev.technician.name} src={ev.technician.avatarUrl} size={16} />);
            case "OfficesClosedScheduleEvent":
                return (<Avatar name="Offices Closed" src={closedImage} size={16} />);
            default:
                return null;
        }
    },
    renderItem(ev) {
        const label = ev.name;
        const subtext = formatEventType(ev);

        return (
            <div className={styles.info}>
                {label && (<h3>{label}</h3>)}
                <div className={styles.subtext}>{subtext}</div>
                <div className={styles.dates}>
                    <DateTime when={ev.startsAt} format="MM/dd/yyyy" />
                    <span> to </span>
                    <DateTime when={ev.endsAt} format="MM/dd/yyyy" />
                </div>
            </div>
        );
    },
});

type FormatEventTypeSubset = {
    __typename?: string;
    serviceLocation?: { name: string };
};

export function formatEventType(ev: FormatEventTypeSubset) {
    switch (ev.__typename) {
        case "TechOnCallScheduleEvent":
            return ev.serviceLocation
                ? `Tech On-Call (${ev.serviceLocation.name} Service Zone)`
                : "Tech On-Call";
        case "TechUnavailableScheduleEvent":
            return "Tech Unavailable";
        case "OfficesClosedScheduleEvent":
            return "Office Closure";
        default:
            return "Invalid Type";
    }
}