.eventName {
    margin-right: 20px;

    & + .eventSubtext {
        opacity: 0.7;
    }
}

.eventDates {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.875em;

    > time {
        color: inherit;
        opacity: 1;
        font-size: 1em;

        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }
    }
}
