import { h, Fragment } from "preact";
import { Checkbox } from "../components/controls/Checkbox";
import { PhoneNumberField } from "../components/controls/PhoneNumberField";
import { TextField } from "../components/controls/TextField";
import { createFormPage } from "../factories/createFormPage";
import { useUpdateUserMutation, useGetUserQuery, UpdateUserInput, UpdateUserMutation, GetUserQuery, UpdateUserMutationVariables, UserRole } from "../graphql/generated";

type Fields = UpdateUserInput;
type RouteParams = { id: string };
type MutRes = UpdateUserMutation;
type MutVar = UpdateUserMutationVariables;
type Query = GetUserQuery;

/**
 * Renders the form view for updating a user.
 */
export const UpdateUserProfileFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, Query>({
    title: () => "Edit User Profile",
    useDefaultValues: ({ data }) => {
        const c = data!.user!;

        return {
            id: c.id,
            name: c.name,
            email: c.email,
            phoneNumber: c.phoneNumber,
            tags: c.tags,
        };
    },
    toVars: ({ tags, phoneNumber, ...fields }: Fields) => ({ input: {
        ...fields,
        phoneNumber: phoneNumber || null,
        tags: tags![0] ? tags : [],
    } }),
    useQuery: ({ id }) => useGetUserQuery({ variables: { id } }),
    useMutation: useUpdateUserMutation,
    onSuccess: (res, { history }) => history.replace(`/users/${res.user.id}`),
    onSuccessMessage: res => `Successfully updated user "${res.user.name}".`,
    render({ data, register }) {
        const isTech = data!.user!.role === UserRole.Technician;

        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Name"
                    required
                />
                <TextField
                    register={register}
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    label="Email"
                />
                <PhoneNumberField
                    register={register}
                    name="phoneNumber"
                    label="Phone Number"
                />

                {isTech && (
                    <Checkbox
                        register={register}
                        name="tags.0"
                        label="Is vaccindated against COVID-19"
                        value="COVID19_VAX"
                    />
                )}
            </>
        );
    },
});