@import "../../mixins";

.content {
    padding: 32px 0;
    @extend %container;
}

.stickyTabs {
    position: sticky;
    top: 60px;
    z-index: 30;
}

.header {
    background: #fff;
}

.banner {
    background: url("../../img/TechBanner.png") no-repeat center center;
    background-size: cover;
    height: 140px;
}

.techInfo {
    position: relative;
    top: -32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > h1 {
        margin: 10px 0;
    }
}