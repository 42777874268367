import { h, Fragment } from "preact";
import { RouteComponentProps } from "react-router-dom";
import cx from "classnames";
import { PageHeader } from "../components/PageHeader";
import { CommentableType, GetCommentsQuery, useDeleteCommentMutation, useGetCommentsQuery } from "../graphql/generated";
import { CreateCommentSubView } from "./CreateCommentSubView";
import { ErrorView } from "./ErrorView";
import { LoaderView } from "./Loader";
import { NotFoundView } from "./NotFound";
import { Avatar } from "../components/Avatar";
import { DateTime } from "../components/DateTime";
import * as styles from "./CommentsView.module.scss";
import { Drawer } from "../components/Drawer";
import { useDisclosure } from "../hooks/useDisclosure";
import { DeleteEntityButton } from "../components/DeleteEntityButton";
import { useState } from "preact/hooks";
import { useAuth } from "../contexts/Auth";
import { Edit, ManageUser, OverflowMenu, SortBy } from "../components/Icons";
import { UpdateCommentSubView } from "./UpdateCommentSubview";

type Props = RouteComponentProps<{
    /** Pluralized type used to figure out what type of entity we're looking at. */
    type: string;
    /** ID of the entity we're attempting to view comments for. */
    id: string;
}>;

export type CommentData = NonNullable<GetCommentsQuery["commentable"]>["comments"][0];

/**
 * Provides functionality around listing comments for an entity and provides users (of any type)
 * with the ability to leave comments/notes for that entity.
 */
export function CommentsView({ match, history }: Props) {

    const [selected, setSelected] = useState<null | CommentData>(null);
    const [editing, setEditing] = useState(false);
    const deselect = () => {
        setSelected(null);
        setEditing(false);
    };

    const { user } = useAuth();

    // did we receive a valid pluralized entity type?
    const type = getSubjectType(match.params.type);
    if (!type) return (<NotFoundView />);

    // get the commentable type and comments (if available)
    const [{ fetching, error, data }, refetch] = useGetCommentsQuery({
        variables: { type, id: match.params.id },
    });

    if (fetching && !data) {
        return (
            <LoaderView />
        );
    }

    if (error) {
        return (
            <ErrorView
                message={error.message}
                onRetry={refetch}
            />
        );
    }

    if (!data || !data.commentable) {
        return (
            <NotFoundView />
        );
    }

    // render the found comments (if any)
    const comments = data.commentable.comments;
    let commentElements: JSX.Element | JSX.Element[];

    if (comments.length > 0) {
        commentElements = comments.map(com => {
            const author = com.author ?? {
                name: "System",
                avatarUrl: "",
                id: ""
            };

            const isAuthor = user!.id === author.id;
            const classes = cx(styles.comment, {
                [styles.isInternal]: !com.isPublic,
            });
            return (
                <div key={com.id} className={classes}>
                    <header>
                        <Avatar
                            name={author.name}
                            src={author.avatarUrl}
                            size={10}
                        />
                        <div className={styles.author}>{author.name}</div>
                        <DateTime when={com.createdAt} />
                    </header>
                    <section>
                        {com.content}
                    </section>
                    {isAuthor &&
                        (
                            <div className={styles.actionWrap}>
                                <PageHeader.IconAction
                                    icon={<OverflowMenu />}
                                    onClick={() => setSelected(com)}
                                />
                            </div>
                        )}
                </div>
            );
        });
    } else {
        commentElements = (
            <div className={styles.noComments}>No comments found</div>
        );
    }

    return (
        <>
            <PageHeader>
                <PageHeader.Row>
                    <PageHeader.Title
                        title="Comments"
                        subTitle={getSubjectTitle(data.commentable)}
                    />
                </PageHeader.Row>
            </PageHeader>
            <div className={styles.content}>
                {commentElements}
                <CreateCommentSubView
                    id={match.params.id}
                    type={type}
                    onSuccess={() => refetch()}
                />
            </div>
            <Drawer open={!!selected} onClose={deselect}>
                <Drawer.Heading label="Actions" />
                <Drawer.Button label="Edit Comment" icon={<Edit />} onClick={() => setEditing(true)} />
                <Drawer open={editing} onClose={() => setEditing(false)}>
                    <Drawer.Heading label="Edit comment" />
                    <UpdateCommentSubView className={styles.commentEditingDrawer} key={selected?.id} comment={selected} onSuccess={deselect} onCancel={() => setEditing(false)} />
                </Drawer>
                <DeleteEntityButton id={selected ? selected.id : ""} type="Comment" useMutation={useDeleteCommentMutation} onSuccess={deselect} />
            </Drawer>
        </>
    );
}

function getSubjectType(type: string) {
    switch (type) {
        case "users":
            return CommentableType.User;
        case "buildings":
            return CommentableType.Building;
        case "comments":
            return CommentableType.Comment;
        case "customers":
            return CommentableType.Customer;
        case "scheduleEvents":
            return CommentableType.ScheduleEvent;
        case "serviceZones":
            return CommentableType.ServiceZone;
        case "tickets":
            return CommentableType.Ticket;
        default:
            return null;
    }
}

function getSubjectTitle(data: GetCommentsQuery["commentable"]): string {
    if (!data) return "";

    switch (data.__typename) {
        case "User":
        case "Customer":
        case "Building":
        case "OfficesClosedScheduleEvent":
        case "TechOnCallScheduleEvent":
        case "TechUnavailableScheduleEvent":
            return data.name;
        case "Ticket":
            return "Service Request " + data.id;
        case "Comment":
            const preview = (data.content.length > 40
                ? data.content.slice(40) + "..."
                : data.content);
            return `Comment: "${preview}"`;
        default:
            return "";
    }
}