import { h, Fragment } from "preact";
import { SelectField } from "../../components/controls/SelectField";
import { TextField } from "../../components/controls/TextField";
import { CONTRACT_CODE_OPTS } from "../../constants";
import { createFormPage } from "../../factories/createFormPage";
import { ContractCode, CreateServiceLocationInput, CreateServiceLocationMutation, CreateServiceLocationMutationVariables, useCreateServiceLocationMutation } from "../../graphql/generated";

type Fields = Omit<CreateServiceLocationInput, "id">;
type RouteParams = { buildingId: string };
type MutRes = CreateServiceLocationMutation;
type MutVar = CreateServiceLocationMutationVariables;

/**
 * Renders the form view for adding a new service location.
 */
export const CreateServiceLocationFormView = createFormPage<Fields, RouteParams, MutRes, MutVar, {}>({
    title: () => "Add Service Location",
    useDefaultValues: ({ match }) => ({
        buildingId: match.params.buildingId,
        name: "",
        contractCode: ContractCode.Fm8,
    }),
    useMutation: useCreateServiceLocationMutation,
    onSuccess: (res, { history, match }) => history.replace(`/buildings/${match.params.buildingId}/serviceLocations/${res.serviceLocation.id}`),
    onSuccessMessage: res => `Successfully created new service location "${res.serviceLocation.name}".`,
    render({ register }) {
        return (
            <>
                <TextField
                    register={register}
                    type="text"
                    name="name"
                    label="Service Location Name"
                    required
                />
                <SelectField
                    register={register}
                    name="contractCode"
                    label="Contract Code"
                    options={CONTRACT_CODE_OPTS}
                    required
                />
            </>
        );
    },
});