.confirmActions {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    padding: 25px;

    > button {
        width: 100%;
    }

    > button + button {
        margin-top: 10px;
    }
}
