import { h } from "preact";
import { Card } from "../../components/CardList";
import { ServiceZones, SortBy } from "../../components/Icons";
import { createListPage, createOptionsFromEnum } from "../../factories/createListPage";
import { GetServiceZonesQuery, ServiceZoneSortBy, SortOrder, useGetServiceZonesQuery, UserRole } from "../../graphql/generated";


type ServiceZone = GetServiceZonesQuery["serviceZones"]["nodes"][0];

const serviceZoneColor = "#717171";

/**
 * Renders the full list of service zones and allows for new service zone accounts to be created.
 */
export const ServiceZonesListView = createListPage<ServiceZone>({
    title: "Manage Service Zones",
    icon: <ServiceZones color={serviceZoneColor} />,
    filterOptions: {
        sortBy: {
            label: "Sort By",
            icon: <SortBy />,
            default: ServiceZoneSortBy.Name,
            options: createOptionsFromEnum(ServiceZoneSortBy),
        },
    },
    footerActions: ({ history, auth }) => auth.role !== UserRole.Dispatch ? [] : [
        {
            label: "Add Service Zone",
            onClick: () => history.push("/serviceZones/create"),
        },
    ],
    getUrl: ({ id }) => `/serviceZones/${id}`,
    useQuery: (query) => {
        const [{ fetching, error, data }] = useGetServiceZonesQuery({
            variables: {
                query: {
                    search: (query.search as string) || null,
                    page: parseInt(query.page as string),
                    perPage: parseInt(query.perPage as string),
                    sortBy: query.sortBy as ServiceZoneSortBy,
                    sortOrder: query.orderBy as SortOrder,
                },
            },
        });

        return {
            fetching,
            error,
            pageInfo: data?.serviceZones.pageInfo,
            items: data?.serviceZones.nodes,
        };
    },
    renderItem(serviceZone) {
        return (
            <Card.Info icon={<ServiceZones color={serviceZoneColor} />}>
                <strong>{serviceZone.name}</strong>
            </Card.Info>
        );
    },
});